import React, { useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import FlexBox from "../../../share/FlexBox";
import { HugeText, LargeText, MediumText } from "../../../share/StyledText";
import { ThemeContext } from "../../../../context/ThemeProvider";
import { engMonthChang } from "../../../../lib/ProjectUtils";
import CheckListCompleteKorButton from "../../../../assets/images/button/btn-checkList-completed-kor.png";
import CheckListCompleteEngButton from "../../../../assets/images/button/btn-checkList-completed-eng.png";

const Wrapper = styled.div`
  padding: 18px 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const DateText = styled(LargeText)``;
const GrayText = styled(LargeText)`
  color: ${({ theme }) => theme.subTextColor};
  font-weight: 400;
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
`;
const InfoBox = styled(FlexBox)`
  height: 88px;
  margin-top: 20px;
  padding: 16px 0 18px 0;
  background-color: ${({ theme }) => theme.sortingBoxColor};
`;
const ItemBox = styled(FlexBox)`
  height: 100%;
  flex: ${({ flex }) => (flex ? flex : 1)};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ last }) =>
    last &&
    css`
      flex: 1.1;
      align-items: flex-end;
    `};
`;
const ItemTitle = styled(MediumText)`
  align-self: center;
`;
const ItemContentText = styled(HugeText)`
  font-size: 18px;
  align-self: center;
`;

const CheckListBox = ({ list }) => {
  const navigate = useNavigate();
  const { language } = useContext(ThemeContext);

  const dateText = useCallback(() => {
    switch (language) {
      case "Kor":
        return `${list?.wcl_year}년 ${list?.wcl_month}월 ${list?.wcl_week}주차`;
      case "Eng":
      default:
        return `${engMonthChang(list.wcl_month)}. ${list?.wcl_week}nd week, ${
          list?.wcl_year
        }`;
    }
  }, [list]);

  const onClickCheckListDetail = useCallback(() => {
    localStorage.setItem("wclId", list.wcl_id);
    navigate("/check-list-detail");
  }, [list]);

  return (
    <Wrapper onClick={onClickCheckListDetail}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <DateText>{dateText()}</DateText>
        <FlexBox>
          <GrayText>
            {list?.wcl_status === "complete" && (
              <>
                {language === "Kor" && (
                  <img src={CheckListCompleteKorButton} alt="평가완료" />
                )}
                {language === "Eng" && (
                  <img src={CheckListCompleteEngButton} alt="Completed" />
                )}
              </>
            )}
          </GrayText>
        </FlexBox>
      </FlexBox>
      <InfoBox>
        <ItemBox>
          <ItemTitle>
            {language === "Kor" && "총준수율"}
            {language === "Eng" && "Rate"}
          </ItemTitle>
          <ItemContentText>{list?.wcl_complyRate} %</ItemContentText>
        </ItemBox>
        <ItemBox>
          <ItemTitle>
            {language === "Kor" && "평가 총점"}
            {language === "Eng" && "Total"}
          </ItemTitle>
          <ItemContentText>{list?.wcl_totalScore}</ItemContentText>
        </ItemBox>
        <ItemBox>
          <ItemTitle>
            {language === "Kor" && "평가점수"}
            {language === "Eng" && "Score"}
          </ItemTitle>
          <ItemContentText>{list?.wcl_evaluationScore}</ItemContentText>
        </ItemBox>
        <ItemBox last>
          <ItemTitle>
            {language === "Kor" && "반복위반"}
            {language === "Eng" && "Repeat"}
          </ItemTitle>
          <ItemContentText>{list?.wcl_repeatViolationCount}</ItemContentText>
        </ItemBox>
      </InfoBox>
    </Wrapper>
  );
};

export default React.memo(CheckListBox);
