import React from "react";
import styled, {css} from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 34px;
`;
const ChartBox = styled.div`
  height: 150px;
  display: flex;
  align-self: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const StickGraphBox = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
`;
const StickGraph = styled.div`
  width: 8px;
  flex: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.activeColor};
  transition: .4s;

  ${({ height }) => height && css`
    height: ${({ height }) => height}%;
  `};
`;
const ValueBox = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ValueText = styled.div`
  font-size: 8px;
  margin-bottom: 6px;
`;

const StickChart = ({ data = [] }) => {
    return (
        <Wrapper>
            <ChartBox>
                {data.map((data) => (
                    <StickGraphBox key={data?.month}>
                        <StickGraph height={data?.complyRate} />
                        <ValueBox>
                            <ValueText>
                                {data?.month}
                            </ValueText>
                            <ValueText>
                                {data?.complyRate}%
                            </ValueText>
                        </ValueBox>
                    </StickGraphBox>
                ))}
            </ChartBox>
        </Wrapper>
    )
}

export default React.memo(StickChart);