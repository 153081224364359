import React, {useContext} from "react";
import styled, {css} from "styled-components";
import ModalClose from "../../../share/ModalClose";
import {HugeText} from "../../../share/StyledText";
import {ThemeContext} from "../../../../context/ThemeProvider";
import StyledTextArea from "../../../share/StyledTextArea";
import StyledButton from "../../../share/StyledButton";
import {CheckListDetailContext} from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";

const Wrapper = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  height: calc(var(--vh, 1vh) * 100);
  padding: ${({ theme }) => theme.isApp ? '40px 20px 20px' : '20px'};
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 5;
  background-color: ${({theme}) => theme.whiteColor};
  transition: all 0.3s;
  overflow-y: scroll;

  ${({commentModal}) => commentModal && css`
    z-index: 10;
    top: 0;
    right: calc(100vw - 50%);
    transform: translateX(50%);
  `};
`;
const Header = styled.div`
  width: 100%;
  margin-bottom: 26px;
  background-color: ${({theme}) => theme.bgColor};
`;
const ButtonBox = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  bottom: 26px;
`;

const CheckListCommentModal = ({ }) => {
    const { language } = useContext(ThemeContext);
    const { commentModal, setCommentModal, comment, setComment, handleEnterComments } = useContext(CheckListDetailContext);

    return (
        <Wrapper commentModal={commentModal}>
            <Header>
                <ModalClose setModalClose={setCommentModal}/>
                <HugeText>
                    {language === 'Kor' && '종합 검토 의견 입력하기'}
                    {language === 'Eng' && 'Search Risk Code'}
                </HugeText>
            </Header>

            <StyledTextArea
                label={
                    (language === 'Kor' && `검토 의견 입력 (${comment.length} / 500)`) ||
                    (language === 'Eng' && `검토 의견 입력 (${comment.length} / 500)`)
                }
                commentModal={commentModal}
                value={comment}
                onChange={e => setComment(e.target.value)}
                maxLength={500}
                placeholder={
                    (language === 'Kor' && '의견을 입력해주세요') ||
                    (language === 'Eng' && 'Please enter your comments')
                }
            />
            <ButtonBox>
                <StyledButton
                    title={
                        (language === "Kor" && "의견 입력하기") ||
                        (language === "Eng" && "Create Project")
                    }
                    onClick={handleEnterComments}
                    disabled={comment.length === 0}
                />
            </ButtonBox>
        </Wrapper>
    )
}

export default React.memo(CheckListCommentModal);
