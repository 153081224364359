import React, {
    createRef, useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styled, {css} from "styled-components";
import GoBack from "../../share/GoBack";
import {ThemeContext} from "../../../context/ThemeProvider";
import notification from "../../../assets/icons/sideBar/notification.svg";
import clock from "../../../assets/icons/sideBar/clock.svg";
import {HugeText, LargeText} from "../../share/StyledText";
import {dateFormat} from "../../../utils/commons";
import {SubScriptContext} from "../../../App";
import {ProjectListContext} from "../../../pages/Project/ProjectList/ProjectListContainer";

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: ${({theme}) => theme.isApp ? '20px 0 50px' : '0 0 50px'};
  position: absolute;
  top: 0;
  left: -20px;
  z-index: 999;
  transform: translateX(200%);
  transition: 0.2s;
  background-color: ${({theme}) => theme.bgColor};

  ${({notifications}) =>
          notifications &&
          css`
            transform: translateX(20px);
          `};
`;
const Header = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${({theme}) => theme.bgColor};
`;
const ScrollBox = styled.div`
  height: calc(100vh - 162px);
  padding: 0 20px 160px;
  overflow-y: scroll;
`;
const NotificationBox = styled.div`
  padding-bottom: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 18px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const Image = styled.img`
  ${({clock}) =>
          clock &&
          css`
            margin: 2px 6px 0 0;
          `};
`;
const Box = styled.div`
  color: ${({theme}) => theme.subTextColor};
  display: flex;
  flex-direction: column;
`;
const Text = styled(LargeText)`
  width: 96%;
  word-break: break-all;
  //white-space: pre-wrap;
  line-height: 1.5;
  color: ${({theme}) => theme.defaultTextColor};
  font-weight: 500;
`;
const TimeText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const SideNotifications = () => {
    const {language} = useContext(ThemeContext); // 테마 모드
    const {notifications, setNotifications, notiData} = useContext(ProjectListContext);
    const {setSubScriptOn} = useContext(SubScriptContext);
    const scrollRef = createRef();

    useEffect(() => {
        if (notifications) {
            setSubScriptOn(false);
        }
    }, [notifications]);

    useEffect(() => {
        if (notifications) scrollRef.current.scrollTo(0, 0); // 알림 클릭시 스크롤 최상단 이동
    }, [notifications]);

    return (
        <Wrapper notifications={notifications}>
            <Header>
                <GoBack onClick={() => setNotifications(false)}/>
                <HugeText>
                    {language === "Kor" && "알림"}
                    {language === "Eng" && "Notifications"}
                </HugeText>
            </Header>
            <ScrollBox ref={scrollRef}>
                {notiData?.seeMyNotification?.notificationList?.map((data, index) => (
                    <NotificationBox
                        key={`${index}_${data.title}}`}
                        onClick={() => setNotifications(false)}
                    >
                        <Box>
                            <Text>{data.notifi_content}</Text>
                            <TimeText>
                                <Image src={clock} clock/>
                                {dateFormat(
                                    new Date(Number(data.notifi_createdAt)),
                                    "yyyy.MM.dd - hh:mm"
                                )}
                            </TimeText>
                        </Box>
                        <Image src={notification}/>
                    </NotificationBox>
                ))}
            </ScrollBox>
        </Wrapper>
    );
};

export default SideNotifications;
