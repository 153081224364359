import React from "react";
import styled from "styled-components";

const Button = styled.button`
  width: ${({ width }) => width};
  height: ${({ height }) => height}px;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily}, sans-serif;
  color: ${({ fontColor, theme }) => fontColor ? fontColor : theme.buttonTextColor};
  margin: ${({ margin }) => margin};
  border: ${({ border }) => border};
  border-radius: 8px;
  background-color: ${({ bgColor, theme }) => bgColor ? bgColor : theme.activeColor};
  
  &:disabled {
    background-color: ${({ theme }) => theme.subTextColor};
  }
`;

const StyledButton = ({
                        title = "",
                        width = "100%",
                        height = 54,
                        fontSize = 18,
                        fontWeight = 600,
                        fontFamily = "AppleSDGothicNeoMedium",
                        fontColor,
                        margin = 0,
                        border = "none",
                        bgColor,
                        onClick = () => null,
                        disabled = false,
                      }) => {
    return (
        <Button
            width={width}
            height={height}
            fontSize={fontSize}
            fontWeigh={fontWeight}
            fontFamily={fontFamily}
            fontColor={fontColor ? fontColor : null}
            margin={margin}
            border={border}
            bgColor={bgColor ? bgColor : null}
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </Button>
    )
}

export default React.memo(StyledButton);