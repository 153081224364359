import React, { useContext, useEffect, useState } from "react";
import ProjectSearchPresenter from "./ProjectSearchPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { SEARCH_MY_PROJECT } from "../../../graphql/Project/ProjectList/query";
import { useQuery } from "@apollo/client";

const ProjectSearchContainer = () => {
  const { language } = useContext(ThemeContext); // 테마 모드
  const [searchTerm, setSearchTerm] = useState(""); // 검색 Input 상태
  const [searchPrjList, setSearchPrjList] = useState([]);

  const {
    data: searchPrjData,
    loading: searchPrjLoading,
    refetch: searchPrjRefetch,
  } = useQuery(SEARCH_MY_PROJECT, {
    variables: {
      searchTerm: searchTerm,
    },
  });

  useEffect(() => {
    if (searchPrjData?.searchMyProject?.result) {
      setSearchPrjList(searchPrjData.searchMyProject.projectList);
    }
  }, [searchPrjData]);

  return (
    <ProjectSearchPresenter
      language={language}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchPrjList={searchPrjList}
    />
  );
};

export default ProjectSearchContainer;
