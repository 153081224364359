import React, {useCallback, useContext, useEffect} from "react";
import styled, {css} from "styled-components";
import ProfileImage from "../../share/ProfileImage";
import {ThemeContext} from "../../../context/ThemeProvider";
import {darkTheme, lightTheme} from "../../../styles/theme";
import {LargeText, HugeText, MediumText} from "../../share/StyledText";
import SideTabMenu from "./SideTabMenu";
import GoBack from "../../share/GoBack";
import {ProfileContext} from "../../../App";
import {groupChanger} from "../../../lib/ProfileUtils";
import emptyUser from "../../../assets/images/emptyUser.png";
import {ProjectListContext} from "../../../pages/Project/ProjectList/ProjectListContainer";

const Wrapper = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding: ${({theme}) => theme.isApp ? '40px 20px' : '20px 20px 40px'};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -20px;
  z-index: 999;
  transform: translateX(-100%);
  transition: 0.2s;
  overflow: scroll;
  background-color: ${({theme}) => theme.bgColor};

  ${({sideBar}) => sideBar && css`
    transform: translateX(20px);
  `};
`;
const Line = styled.div`
  height: 1px;
  margin-bottom: 20px;
  background-color: ${({theme}) => theme.borderColor};
`;
const VersionText = styled.div`
  padding-top: 20px;
  color: ${({ theme }) => theme.subTextColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`;

const SideBar = () => {
    const {themeMode, language} = useContext(ThemeContext); // 테마 모드
    const {sideBar, setSideBar} = useContext(ProjectListContext);

    const {myProfile , profileRefetch} = useContext(ProfileContext);    
    
    useEffect(() => {
        profileRefetch();
    },[])

    return (
        <Wrapper sideBar={sideBar}>
            <GoBack onClick={() => setSideBar(false)}/>
            <ProfileImage
                width={58}
                height={58}
                margin="20px 0"
                src={myProfile?.user_profileImg === "" ? emptyUser : myProfile?.user_profileImg} // 유저 이미지
            />
            <HugeText>
                {language === "Kor" && `반가워요. ${myProfile?.user_name} 님 `}
                {language === "Eng" && `Hello. ${myProfile?.user_name}`}
            </HugeText>
            <MediumText
                margin="12px 0 20px"
                fontColor={
                    themeMode === "light"
                        ? lightTheme.subTextColor
                        : darkTheme.subTextColor
                }
            >
                {language === "Kor" &&
                `소속업체 : ${myProfile?.user_groupCompany} (${groupChanger(
                    myProfile?.user_group,
                    language
                )})`}
                {language === "Eng" &&
                `Affiliated Company : ${myProfile?.user_groupCompany} (${myProfile?.user_group})`}
            </MediumText>
            <Line/>
            <LargeText margin="0 0 14px">
                {language === "Kor" && "설정"}
                {language === "Eng" && "Settings"}
            </LargeText>
            <SideTabMenu title="내정보 보러가기"/>
            <SideTabMenu title="고객센터 바로가기"/>
            <SideTabMenu title="알림 설정하기"/>
            <SideTabMenu title="로그아웃하기"/>
            <LargeText margin="30px 0 8px">
                {language === "Kor" && "기타"}
                {language === "Eng" && "Other"}
            </LargeText>
            <SideTabMenu title="언어 설정하기"/>
            <SideTabMenu title="약관 보러가기"/>
            <SideTabMenu title="사용법 보기"/>

            <VersionText>
                version 1.1.0
            </VersionText>
        </Wrapper>
    );
};

export default SideBar;
