import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext,
} from "react";
import GlobalStyles from "./styles/GlobalStyles";
import AppRouter from "./AppRouter";
import { ThemeContext } from "./context/ThemeProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { SEE_MY_PROFILE_INFO } from "./graphql/myPage/MyProfile/query";
import { useQuery, useSubscription } from "@apollo/client";
import {
  CREATE_SCAR_NOTI,
  CREATE_PROJECT_NOTI,
  SCAR_APPROVAL_NOTI,
} from "./graphql/Subscription/subscription";
import {
  SEE_MY_PROJECT,
  SEE_MY_SIGN_PROJECT,
} from "./graphql/Project/ProjectList/query";
import Report from "./pages/Report";
import {
  SEE_PROJECT_DETAIL,
  SEE_SCAR_LIST,
} from "./graphql/Project/ProjectDetail/query";
import { SEE_SCAR_DETAIL } from "./graphql/Project/ScarDetail/query";
import CustomAlert from "./components/share/CustomAlert";
import CustomConfirm from "./components/share/CustomConfirm";

export const SubScriptContext = createContext(null);
export const ProfileContext = createContext(null);

function App() {
  const { setLanguage } = useContext(ThemeContext);
  const { pathname } = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();
  const [myProfile, setMyProfile] = useState(null);
  const [subScriptOn, setSubScriptOn] = useState(false);
  const [prjListSort, setPrjListSort] = useState({
    status: "전체", // 프로젝트 상태
    period: [
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      new Date(),
    ], // 기간 설정(기본 한달전 ~ 오늘까지 설정)
    sort: "내림차순", // 정렬 상태
  });
  const [sideBar, setSideBar] = useState(false); // 사이드바 상태
  const [notifications, setNotifications] = useState(false); // 알림창 보기 상태
  const [alertMessage, setAlertMessage] = useState(null); // custom alert message
  const [confirmMessage, setConfirmMessage] = useState(null); // 나중에 confirm
  const [fcmToken, setFcmToken] = useState(""); // FCM 토큰
  const [cameraPermission, setCameraPermissions] = useState(false);
  const [mediaLibraryPermissions, setMediaPermissions] = useState(false);

  // 인증번호 요청 시 앱으로 FCM 토큰 요청
  useEffect(() => {
    if (!!window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "REQ_FCM_TOKEN",
        }),
      );
    }
  }, []);

  const { data: profileData, refetch: profileRefetch } =
    useQuery(SEE_MY_PROFILE_INFO);

  const { data: scarSubData } = useSubscription(CREATE_SCAR_NOTI, {
    variables: {
      userId: myProfile?.user_id,
    },
  });

  const { data: prjSubData } = useSubscription(CREATE_PROJECT_NOTI, {
    variables: {
      memberId: myProfile?.user_id,
    },
  });

  const { data: scarApprovalData } = useSubscription(SCAR_APPROVAL_NOTI, {
    variables: {
      userId: myProfile?.user_id,
    },
  });

  const { refetch: prjRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { refetch: prjDetailRefetch } = useQuery(SEE_PROJECT_DETAIL, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
    },
  });
  const { refetch: scarDetailRefetch } = useQuery(SEE_SCAR_DETAIL, {
    variables: {
      scar_id:
        localStorage.getItem("scarId") === null
          ? 0
          : Number(localStorage.getItem("scarId")),
    },
  });
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });
  const { refetch: prjListRefetch } = useQuery(SEE_MY_PROJECT, {
    variables: {
      searchTerm: "",
      status: "total",
      startDate: prjListSort.period[0],
      endDate: prjListSort.period[1],
      sort: "desc",
    },
  });

  const previousLoading = () => {
    try {
      const token = localStorage.getItem("riskcatch_user_token");
      const language = localStorage.getItem("language");

      if (language) {
        setLanguage(language);
      }

      if (token) {
        setIsLoggedIn(true);
        navigate(pathname);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      alert(e.message);      
    }
  };

  const requestPermission = () => {
    // if (window.ReactNativeWebView) { // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다
    //     window.ReactNativeWebView.postMessage(); // JSON.stringify({ type: "REQ_CAMERA_PERMISSION" })
    // } else { // 모바일이 아니라면 모바일 아님을 alert 로 띄웁니다.
    //     alert({
    //         // message: ERROR_TYPES.notMobile
    //         message: "모바일이 사용환경이 아닙니다."
    //     });
    // }
  };

  const setScreenSize = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    (async () => {
      if (scarSubData || prjSubData || scarApprovalData) {
        setSubScriptOn(true);
        await prjRefetch();
        await prjListRefetch();

        if (localStorage.getItem("prjId") !== null) {
          await prjDetailRefetch();
          await scarListRefetch();
        }
        if (localStorage.getItem("scarId") !== null) {
          await scarDetailRefetch();
        }
      }
    })();

    // localStorage.setItem("authInfo", JSON.stringify({
    //     countryCode: "+82",
    //     cellPhone: "01098110022"
    // }));
  }, [scarSubData, prjSubData, scarApprovalData]);

  useEffect(() => {
    previousLoading();
    requestPermission();
    setScreenSize();
    window.alert = (message) => setAlertMessage(message);
  }, []);

  useEffect(() => {        
    if (profileData?.seeMyProfileInfo?.result) {
      setMyProfile(profileData.seeMyProfileInfo.myInfo);
    }
  }, [profileData]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [pathname]);

  const appMessageListener = useCallback((e) => {
    const data = JSON.parse(e.data);

    switch (data.type) {
      case "SIDE_BAR_CLOSE":
        setSideBar(false);
        break;
      case "SIDE_NOTIFICATION_CLOSE":
        setNotifications(false);
        break;
      case "FCM_TOKEN":
        // alert(`FCM_TOKEN : ${data.payload}`);
        setFcmToken(data.payload);
        break;
      case "CAMERA_PERMISSIONS":
        alert("CAMERA_PERMISSIONS: " + data.payload ? "TRUE" : "FALSE");
        setCameraPermissions(data.payload);
        break;
      case "MEDIA_LIBRARY_PERMISSIONS":
        alert("MEDIA_LIBRARY_PERMISSIONS: " + data.payload ? "TRUE" : "FALSE");
        setMediaPermissions(data.payload);
        break;
      default:
        console.log("error");
    }
  }, []);

  useEffect(() => {
    if (!!window.ReactNativeWebView) {
      // 인증번호 요청 시 앱으로 FCM 토큰 요청
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "REQ_FCM_TOKEN",
        }),
      );

      document.addEventListener("message", appMessageListener);
      window.addEventListener("message", appMessageListener);
    }
  }, []);

  // 콘솔 삭제
  // console.log = function no_console() {};
  // console.warn = function no_console() {};
  // console.info = function no_console() {};

  return (
    <ProfileContext.Provider
      value={{
        myProfile,
        profileRefetch,
        setPrjListSort,
        setConfirmMessage,
        sideBar,
        setSideBar,
        notifications,
        setNotifications,
        fcmToken,
        cameraPermission,
        mediaLibraryPermissions,
      }}
    >
      <GlobalStyles />
      <SubScriptContext.Provider value={{ subScriptOn, setSubScriptOn }}>
        {alertMessage && (
          <CustomAlert
            message={alertMessage}
            onClose={() => setAlertMessage(null)}
          />
        )}
        {confirmMessage && <CustomConfirm message={confirmMessage} />}

        {/*<ToggleTheme />*/}
        {pathname.includes("/scar/share/report/") ? <Report /> : <AppRouter />}
      </SubScriptContext.Provider>
    </ProfileContext.Provider>
  );
}

export default App;
