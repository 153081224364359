import styled from "styled-components";
import { ReactComponent as MoreIcon } from "../../assets/icons/share/more.svg";

const AccordionContainer = styled.details``;

const AccordionHeader = styled.summary`
  cursor: pointer;
  padding: 20px 32px;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
`;

const MoreButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 12px 16px;
  transform: translateY(-50%);
  background-image: url(../../assets/icons/share/more.svg);
`;

const AccordionContent = styled.div`
  background-color: #f9f9f9;
  border-bottom: 1px solid #e6e6e6;
`;

const Accordion = ({
  children,
  label,
  handleMoreOpen,
  handleMoreClose,
  selected,
  language,
}) => {
  return (
    <AccordionContainer>
      <AccordionHeader>
        {label}
        {handleMoreOpen ? (
          <MoreButton onClick={() => handleMoreOpen && handleMoreOpen()}>
            <MoreIcon width={24} height={24} />
          </MoreButton>
        ) : null}
      </AccordionHeader>
      <AccordionContent>{children}</AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
