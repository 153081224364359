import React from "react";
import styled from "styled-components";
import closeIcon from "../../assets/icons/share/closeIcon.svg";

const Wrapper = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Icon = styled.img``;

const ModalClose = ({ setModalClose }) => {
    return (
        <Wrapper>
            <Icon
                src={closeIcon}
                onClick={() => setModalClose(false)}
            />
        </Wrapper>
    )
}

export default React.memo(ModalClose);