import React, { useContext } from "react";
import ApprovalProcessViewPresenter from "./ApprovalProcessViewPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useLocation } from "react-router-dom";

const ApprovalProcessViewContainer = () => {
  const { language } = useContext(ThemeContext);
  const location = useLocation();
  const { signLine } = location.state;

  return (
    <ApprovalProcessViewPresenter language={language} signLine={signLine} />
  );
};

export default ApprovalProcessViewContainer;
