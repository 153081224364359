import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {HugeText} from "../../../components/share/StyledText";
import pencilIcon from "../../../assets/icons/myPage/pencilIcon.svg";
import FlexBox from "../../../components/share/FlexBox";
import QnaRow from "../../../components/feature/ServiceCenter/QnaRow";
import QnaModal from "../../../components/feature/ServiceCenter/QnaModal";

const Wrapper = styled.div`
  //height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '180px 20px 0' : '160px 20px 20px'};
`;
const Header = styled.div`
  width: 100%;
  position: fixed;
  padding: ${({ theme }) => theme.isApp ? '40px 20px 20px' : '20px'};
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({theme}) => theme.bgColor};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const ActiveText = styled.div`
  color: ${({theme}) => theme.activeColor};
`;
const Icon = styled.img`
  margin: ${({margin}) => (margin ? margin : "0 0 0 6px")};
`;
const Contents = styled.div`
  margin-top: 26px;
`;

const QnaPresenter = ({
                          language = "Kor",
                          qnaData,
                          qnaModal,
                          setQnaModal,
                          qnaList,
                          qnaRefetch,
                      }) => {
    return (
        <Wrapper>
            <Header>
                <GoBack route='/service-center'/>
                <HugeText margin="16px 0 10px">
                    {language === "Kor" && "일대일 문의하기"}
                    {language === "Eng" && "One-on-one questions"}
                </HugeText>

                <FlexBox margin="26px 0 0" onClick={() => setQnaModal(true)}>
                    <ActiveText>
                        {language === "Kor" && "일대일 문의하기"}
                        {language === "Eng" && "one-on-one questions"}
                    </ActiveText>
                    <Icon src={pencilIcon} margin="0 0 0 12px"/>
                </FlexBox>
            </Header>
            <Contents>
                {qnaList.map((qna) => (
                    <QnaRow key={qna.one_id} qna={qna}/>
                ))}
            </Contents>

            <QnaModal
                qnaModal={qnaModal}
                setQnaModal={setQnaModal}
                qnaRefetch={qnaRefetch}
            />
        </Wrapper>
    );
};

export default React.memo(QnaPresenter);
