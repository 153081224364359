import React, { useContext, useEffect } from "react";
import ServiceCenterPresenter from "./ServiceCenterPresenter";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeProvider";
import { SEE_VOC_NUMBER_USER } from "../../../graphql/ServiceCenter/ServiceCenter/query";
import { useQuery } from "@apollo/client";

const ServiceCenterContainer = () => {
  const navigate = useNavigate();
  const { language } = useContext(ThemeContext);

  const {
    data: vocData,
    loading: vocLoading,
    refetch: vocRefetch,
  } = useQuery(SEE_VOC_NUMBER_USER);

  return (
    <ServiceCenterPresenter
      navigate={navigate}
      language={language}
      vocData={vocData}
    />
  );
};

export default ServiceCenterContainer;
