export const confirmAuthCodeErrorMessage = (error, language) => {
    switch (error) {
        case "1":
            alert(
                (language === 'Kor' && '인증번호 전송 후 시도해주세요.') ||
                (language === 'Eng' && 'Please try after sending\nthe authentication number')
            );
            break;
        case "2":
            alert(
                (language === 'Kor' && '회원가입이 안된 번호입니다.') ||
                (language === 'Eng' && 'This number is not\nregistered as a member')
            );
            break;
        case "4":
            alert(
                (language === 'Kor' && '인증번호가 다릅니다.') ||
                (language === 'Eng' && 'The authentication number is different')
            );
            break;
        case "5":
            alert(
                (language === 'Kor' && '탈퇴한 회원입니다.') ||
                (language === 'Eng' && 'You are a withdrawn member')
            );
            break;
        case "6":
            alert(
                (language === 'Kor' && '미승인 회원입니다.') ||
                (language === 'Eng' && 'Not an authorized member')
            );
            break;
        case "7":
            alert(
                (language === 'Kor' && '정지된 사용자입니다.\n관리자에게 문의하세요.') ||
                (language === 'Eng' && 'Stopped user\nContact your administrator')
            );
            break;
        default:
            return;
    }
}

export const requestAuthCodeErrorMessage = (error, language) => {
    switch (error) {
        case "2":
            alert(
                (language === 'Kor' && '회원가입이 안된 번호입니다.') ||
                (language === 'Eng' && 'This number is not\nregistered as a member')
            );
            break;
        case "3":
            alert(
                (language === 'Kor' && '문자 전송에 실패하였습니다.') ||
                (language === 'Eng' && 'Failed to send message')
            );
            break;
        case "4":
            alert(
                (language === 'Kor' && '정지된 사용자입니다.\n관리자에게 문의하세요.') ||
                (language === 'Eng' && 'Stopped user\nContact your administrator')
            );
            break;
        default:
            return;
    }
}