import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiX } from "react-icons/fi";

const BlackBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  & > .icon {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${({ theme }) => `${theme.whiteColor}`};
  }
`;

const Wrapper = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  z-index: 11;
  @media only screen and (max-width: 550px) {
    width: 300px;
    height: 300px;
  }
`;

const Slide = styled.div`
  display: flex;
  transition: transform 0.5s ease-in;
  transform: ${({ count }) => "translateX(-" + count * 100 + "%)"};
  > div {
    width: 100%;
    height: 500px;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 550px) {
      width: 300px;
      height: 300px;
    }
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => `${theme.blackColor}`};
`;

const ControlBtn = styled.div`
  width: 30px;
  height: 30px;
  display: ${({ valid }) => (valid ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  top: calc(50% - 15px);
  border-radius: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  cursor: pointer;
  ${({ right }) =>
    right &&
    css`
      left: calc(100% - 40px);
    `}
`;

const FullViewModal = ({ setFullView, image }) => {
  const length = image.length;
  const [count, setCount] = useState(0);

  const handleButton = (arrow) => {
    switch (arrow) {
      case "left":
        if (count !== 0) setCount(count - 1);
        break;
      case "right":
        if (count !== length - 1) setCount(count + 1);
        break;
    }
  };

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <>
      <BlackBackground>
        <FiX className="icon" onClick={() => setFullView(false)} />
        <Wrapper>
          <ControlBtn
            right={false}
            valid={count !== 0}
            onClick={() => handleButton("left")}
          >
            <FaChevronLeft />
          </ControlBtn>
          <ControlBtn
            right={true}
            valid={count !== length - 1}
            onClick={() => handleButton("right")}
          >
            <FaChevronRight />
          </ControlBtn>
          <Slide count={count}>
            {image?.map((img, index) => (
              <div key={index}>
                <Image src={img} />
              </div>
            ))}
          </Slide>
        </Wrapper>
      </BlackBackground>
    </>
  );
};

export default FullViewModal;
