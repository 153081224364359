import React, { useCallback, useContext, useEffect, useState } from "react";
import SignUpInfoPresenter from "./SignUpInfoPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { getExtension, isEmail } from "../../../utils/commons";
import { USER_SIGNUP } from "../../../graphql/Login/mutation";
import { useMutation } from "@apollo/client";
import { engGroupChanger, groupChanger } from "../../../lib/ProfileUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { ALLOW_FILE_EXTENSION } from "../../../utils/constants";

const SignUpInfoContainer = () => {
  const navigate = useNavigate();
  const { themeMode, language } = useContext(ThemeContext); // 테마 모드
  const { countryCode, cellphone } = JSON.parse(
    localStorage.getItem("authInfo"),
  );
  const [imgUrl, setImgUrl] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const {
    state: { email },
  } = useLocation();
  const emailDisabled = !!email;
  const [userInfo, setUserInfo] = useState({
    name: "",
    duty: "",
    email,
    affiliation: language === "Kor" ? "PM본사" : "PM HQ",
    company: "",
    code: "",
  });
  const [emailCheck, setEmailCheck] = useState(null);
  const [agree, setAgree] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);

  const [userSignup] = useMutation(USER_SIGNUP);

  const onChangeImage = useCallback(async (e) => {
    // 이미지 등록 함수
    const file = e.target.files[0];
    const ext = getExtension(file.name);

    if (file.size / 1024 / 1024 > 10) {
      alert(
        (language === "Kor" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
          (language === "Eng" && "Please upload the image file under 10MB."),
      );
      return;
    }
    if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
      alert(
        (language === "Kor" && "이미지 파일만 업로드 가능합니다.") ||
          (language === "Eng" && "Only image files can be uploaded"),
      );
      return;
    }
    setImgFile(file);
    setImgUrl(URL.createObjectURL(file));
  }, []);

  const onChangeUserInfo = useCallback(
    (e) => {
      // 유저 정보 상태관리 함수
      const { name, value } = e.target;

      if (value.substring(0, 1) === " ") return;
      if (name === "email" && value === "") {
        setEmailCheck(null);
      }
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    },
    [userInfo],
  );

  const emailValid = useCallback(() => {
    // 메일주소 유효성 검사
    if (userInfo.email.length > 0) {
      if (isEmail(userInfo.email)) {
        setEmailCheck("valid");
      } else {
        setEmailCheck("invalid");
      }
    }
  }, [userInfo.email]);

  const handleAgree = useCallback((e) => setAgree(e.target.checked), [agree]); // 약관 동의하기

  const handleSignUp = useCallback(async () => {
    // 회원가입하기
    if (!agree) {
      alert(
        (language === "Kor" && "약관에 동의해주세요.") ||
          (language === "Eng" && "Please accept the terms and conditions"),
      );
      return;
    }
    let variables;

    if (imgUrl === "") {
      variables = {
        user_name: userInfo.name,
        user_rank: userInfo.duty,
        user_countryCode: countryCode,
        user_cellphone: cellphone,
        user_email: userInfo.email,
        profileImagSelect: false,
        user_group:
          language === "Kor"
            ? groupChanger(userInfo.affiliation)
            : engGroupChanger(userInfo.affiliation),
        user_groupCompany: userInfo.company,
        groupAuthCode: userInfo.code,
      };
    } else {
      variables = {
        user_name: userInfo.name,
        user_rank: userInfo.duty,
        user_countryCode: countryCode,
        user_cellphone: cellphone,
        user_email: userInfo.email,
        profileImagSelect: true,
        user_profileImg: imgFile,
        user_group:
          language === "Kor"
            ? groupChanger(userInfo.affiliation)
            : engGroupChanger(userInfo.affiliation),
        user_groupCompany: userInfo.company,
        groupAuthCode: userInfo.code,
      };
    }

    try {
      setSignUpLoading(true);

      const { data } = await userSignup({ variables });

      if (data?.userSignup?.result) {
        setSignUpLoading(false);
        alert(
          (language === "Kor" && "회원가입을 축하합니다.") ||
            (language === "Eng" && "Congratulations on your membership"),
        );
        navigate("/");
      }
      if (data?.userSignup?.error !== "") {
        alert(data.userSignup.error);
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setSignUpLoading(false);
    }
  }, [userInfo, imgUrl, imgFile, agree]);

  const lookUpTerm = useCallback(() => {
    if (window.ReactNativeWebView) {
      const data = {
        type: "WINDOW_OPEN",
        payload: "https://agreementshg.riskzero.ai/",
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      window.open("https://agreementshg.riskzero.ai/", "_blank");
    }
  }, []);

  useEffect(() => {
    const { name, email, company, code } = userInfo;

    if (name !== "" && email !== "" && company !== "" && code !== "" && agree) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [userInfo, agree]);

  return (
    <SignUpInfoPresenter
      themeMode={themeMode}
      language={language}
      imgUrl={imgUrl}
      onChangeImage={onChangeImage}
      userInfo={userInfo}
      onChangeUserInfo={onChangeUserInfo}
      emailCheck={emailCheck}
      emailValid={emailValid}
      agree={agree}
      handleAgree={handleAgree}
      handleSignUp={handleSignUp}
      signUpLoading={signUpLoading}
      buttonStatus={buttonStatus}
      lookUpTerm={lookUpTerm}
      emailDisabled={emailDisabled}
    />
  );
};

export default SignUpInfoContainer;
