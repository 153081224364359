import { gql } from "@apollo/client";

export const REQUEST_CLOSE_PROJECT = gql`
  mutation requestCloseProject($prj_id: Int) {
    requestCloseProject(prj_id: $prj_id) {
      result
      error
    }
  }
`;

export const SAFEY_ACTION_DOCUMENT_DOWN = gql`
  mutation safeyActionDocumentDown($scarId: Int) {
    safeyActionDocumentDown(scar_id: $scarId) {
      result
      docs
    }
  }
`;

export const SCAR_DELETE = gql`
  mutation scarDelete($scarId: Int) {
    scarDelete(scar_id: $scarId) {
      result
      error
    }
  }
`;

export const WITHDRAWAL_PROJECT = gql`
  mutation withdrawalProject($prjId: Int) {
    withdrawalProject(prj_id: $prjId) {
      result
    }
  }
`;
