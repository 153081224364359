import {gql} from '@apollo/client';

export const UPDATE_EVALUATOR = gql`
    mutation updateEvaluator (
        $wclId: Int
    ) {
        updateEvaluator (
            wcl_id: $wclId
        ) {
            evaluationPermission
            result
        }
    }
`;

export const UPDATE_CHECK_LIST_EVALUATION = gql`
    mutation updateCheckListEvaluation(
        $wclId: Int!
        $wciImgs: [Upload!]
    ) {
        updateCheckListEvaluation(
            wcl_id: $wclId
            wci_imgs: $wciImgs
        )
    }
`;

export const EDIT_CHECK_LIST_EVALUATION = gql`
    mutation editCheckListEvaluation (
        $wclEvaluation: String
        $wclId: Int!
    ) {
        editCheckListEvaluation(
            wcl_evaluation: $wclEvaluation
            wcl_id: $wclId
        )
    }
`;

export const CREATE_CHECK_ITEM_EVALUATE = gql`
    mutation createCheckItemEvaluate(
        $wciId: Int!
        $wciScore: Int!
        $wciRepeatedViolation: Boolean!
    ) {
        createCheckItemEvaluate(
            wci_id: $wciId
            wci_score: $wciScore
            wci_repeatedViolation: $wciRepeatedViolation
        ) {
            result
            wscComplyRate
        }
    }
`;

export const EDIT_CHECK_LIST_ITEM_COMMENT = gql`
    mutation editCheckListItemComment(
        $wciId: Int!
        $wciComment: String
    ) {
        editCheckListItemComment(
            wci_id: $wciId
            wci_comment: $wciComment
        )
    }
`;

export const DELETE_CHECK_LIST_IMG = gql`
    mutation deleteCheckListImg($wcliId: Int!) {
        deleteCheckListImg(wcli_id: $wcliId)
    }
`;