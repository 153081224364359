import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../context/AuthContext";
import {MediumText} from "../../share/StyledText";
import myInfo from "../../../assets/icons/sideBar/myInfo.svg";
import serviceCenter from "../../../assets/icons/sideBar/serviceCenter.svg";
import setNotification from "../../../assets/icons/sideBar/setNotification.svg";
import logout from "../../../assets/icons/sideBar/logout.svg";
import sideBarArrow from "../../../assets/icons/sideBar/sideBarArrow.svg";
import {ThemeContext} from "../../../context/ThemeProvider";

const Wrapper = styled.div`
  height: 46px;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 5px;
  }
`;
const Image = styled.img`
  margin-right: 12px;

  &:last-child {
    margin: 0;
  }
`;
const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const LanguageBox = styled.div`
  display: flex;
  align-items: center;
`;
const SubText = styled.div`
  color: ${({theme}) => theme.subTextColor};
  margin: 0 6px 1px 0;
`;


const SideTabMenu = ({title}) => {
    const navigate = useNavigate();
    const {language} = useContext(ThemeContext);
    const {userLogout} = useContext(AuthContext);
    const [tabMenu, setTabMenu] = useState({ // 탭 메뉴 Image 및 클릭 함수 상태
        img: null,
        onClick: () => null
    });

    useEffect(() => { // 탭 메뉴에 따라 Image 및 Router 설정
        switch (title) {
            case "내정보 보러가기":
                setTabMenu({
                    title: language === "Kor" ? title : "My Info",
                    img: myInfo,
                    onClick: () => navigate("/my-profile")
                });
                break;
            case "고객센터 바로가기":
                setTabMenu({
                    title: language === "Kor" ? title : "Service Center",
                    img: serviceCenter,
                    onClick: () => navigate("/service-center")
                });
                break;
            case "알림 설정하기":
                setTabMenu({
                    title: language === "Kor" ? title : "Notification Setting",
                    img: setNotification,
                    onClick: () => navigate("/notification-setting")
                });
                break;
            case "로그아웃하기":
                setTabMenu({
                    title: language === "Kor" ? title : "Logout",
                    img: logout,
                    onClick: () => userLogout()
                });
                break;
            case "언어 설정하기":
                setTabMenu({
                    title: language === "Kor" ? title : "Language Setting",
                    img: null,
                    onClick: () => navigate("/language-setting")
                });
                break;
            case "약관 보러가기":
                setTabMenu({
                    title: language === "Kor" ? title : "Terms",
                    img: null,
                    onClick: () => navigate("/terms")
                });
                break;
            case "사용법 보기":
                setTabMenu({
                    title: language === "Kor" ? title : "How to use",
                    img: null,
                    onClick: () => {
                        if (window.ReactNativeWebView) {
                            const data = {
                                type: 'WINDOW_OPEN',
                                payload: 'https://myurl.ai/zbPftQ'
                            }
                            window.ReactNativeWebView.postMessage(JSON.stringify(data));
                        }  else {
                            window.open('https://myurl.ai/zbPftQ', "_blank")
                        }
                    }
                });
                break;
        }
    }, [title]);


    return (
        <Wrapper onClick={() => tabMenu.onClick()}>
            {tabMenu.img && <Image src={tabMenu.img}/>}
            <FlexBox>
                <MediumText>{tabMenu.title}</MediumText>
                {title === "언어 설정하기"
                    ? (
                        <LanguageBox>
                            <SubText>
                                {language === "Kor" && "한국어"}
                                {language === "Eng" && "English"}
                            </SubText>
                            <Image src={sideBarArrow}/>
                        </LanguageBox>
                    )
                    : <Image src={sideBarArrow}/>}
            </FlexBox>
        </Wrapper>
    )
}

export default React.memo(SideTabMenu);