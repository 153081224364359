import React, {useCallback, useContext, useEffect, useState} from "react";
import EditEmailPresenter from "./EditEmailPresenter";
import {ThemeContext} from "../../../context/ThemeProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {
    EDIT_MY_EMAIL,
    SEND_EMAIL_AUTH_CODE,
} from "../../../graphql/myPage/EditEmail/mutation";
import {ProfileContext} from "../../../App";
import {isEmail} from "../../../utils/commons";

const EditEmailContainer = () => {
    const {profileRefetch} = useContext(ProfileContext);
    const navigate = useNavigate();
    const {mail} = useLocation().state;
    const {language, themeMode} = useContext(ThemeContext);
    const [inputs, setInputs] = useState({
        email: mail,
        code: "",
    });
    const [emailCheck, setEmailCheck] = useState(null);

    const [sendEmailAuthCode] = useMutation(SEND_EMAIL_AUTH_CODE);
    const [editMyEmail] = useMutation(EDIT_MY_EMAIL);

    const onChangeInput = useCallback((e) => {
        const {name, value} = e.target;

        if (value.substring(0, 1) === ' ') return;
        if (name === "email" && value === "") {
            setEmailCheck(null);
        }

        setInputs({
            ...inputs,
            [name]: value,
        });
    }, [inputs]);

    const emailValid = useCallback(() => { // 메일주소 유효성 검사
        if (inputs.email.length > 0) {
            if (isEmail(inputs.email)) {
                setEmailCheck("valid");
            } else {
                setEmailCheck("invalid");
            }
        }
    }, [inputs.email]);

    const sendMail = useCallback(async () => {
        if (emailCheck !== 'valid') return;

        try {
            const {data} = await sendEmailAuthCode({
                variables: {
                    email: inputs.email,
                }
            });
            if (data?.sendEmailAuthCode?.result) {
                alert((language === 'Kor' && '인증번호가 전송되었습니다.') || (language === 'Eng' && 'Authentication number has been sent'));
            }
            if (data?.sendEmailAuthCode?.error !== "") {
                alert(data?.sendEmailAuthCode?.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [inputs, emailCheck]);

    const editEmail = useCallback(async () => {
        try {
            const {data} = await editMyEmail({
                variables: {
                    email: inputs.email,
                    authCode: inputs.code,
                },
            });
            if (data?.editMyEmail?.result) {
                await profileRefetch();
                alert((language === 'Kor' && '메일 인증을 완료하였습니다.') || (language === 'Eng' && 'Mail authentication completed'));
                navigate("/my-profile");
            }
            if (data?.editMyEmail?.error !== "") {
                alert(data?.editMyEmail?.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [inputs]);

    useEffect(() => {
        if (emailValid()) {
            setEmailCheck('valid');
        }
    }, [inputs.email]);

    return (
        <EditEmailPresenter
            themeMode={themeMode}
            language={language}
            inputs={inputs}
            onChangeInput={onChangeInput}
            sendMail={sendMail}
            editEmail={editEmail}
            emailCheck={emailCheck}
        />
    );
};

export default EditEmailContainer;
