import React, {useContext} from "react";
import styled, {css} from "styled-components";
import {SmallText} from "./StyledText";
import selectBottomArrow from "../../assets/icons/share/selectBottomArrow.svg";
import {ThemeContext} from "../../context/ThemeProvider";
import {darkTheme, lightTheme} from "../../styles/theme";

const Wrapper = styled.div`
  min-width: 72px;
  width: ${({width}) => width};
  margin: ${({margin}) => margin};
  height: ${({height}) => height}px;
`;
const SelectBox = styled.div`
  width: 100%;
  margin: 14px 0 0;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const Select = styled.select`
  width: 100%;
  font-size: 18px;
  color: ${({theme}) => theme.defaultTextColor};
  padding: 0 0 8px;
  background: url(${selectBottomArrow}) no-repeat 95% 20%;

  ${({placeholder, value}) =>
          placeholder === value &&
          css`
            color: ${({theme}) => theme.subTextColor};
          `};
`;

const StyledSelect = ({
                          label = "",
                          width = "100%",
                          height,
                          margin = 0,
                          name = "",
                          value = "",
                          placeholder,
                          options = [],
                          onChange = () => null,
                      }) => {
    const {themeMode} = useContext(ThemeContext);

    return (
        <Wrapper width={width} margin={margin} height={height}>
            <SmallText
                fontColor={
                    themeMode === "light"
                        ? lightTheme.subTextColor
                        : darkTheme.subTextColor
                }
            >
                {label}
            </SmallText>
            <SelectBox>
                <Select
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                >
                    {placeholder && (
                        <option value={value} disabled>
                            {value}
                        </option>
                    )}
                    {options.map((opt, index) => (
                        <option key={`${opt}_${index}`} value={opt}>
                            {opt}
                        </option>
                    ))}
                </Select>
            </SelectBox>
        </Wrapper>
    );
};

export default React.memo(StyledSelect);
