export const groupChanger = (value) => {
  switch (value) {
    case "head":
      return "PM본사";
    case "local":
      return "PM현장";
    case "builder":
      return "시공사";
    case "owner":
      return "발주처";
    case "PM본사":
      return "head";
    case "PM현장":
      return "local";
    case "시공사":
      return "builder";
    case "발주처":
      return "owner";

    // 언어 영어
    case "PM":
      return "head";
    case "Contractor":
      return "builder";
    case "Client":
      return "owner";
  }
};

export const engGroupChanger = (value) => {
  switch (value) {
    case "head":
      return "PM HQ";
    case "local":
      return "PM";
    case "builder":
      return "Contractor";
    case "owner":
      return "Client";
    case "PM HQ":
      return "head";
    case "PM":
      return "local";
    case "Contractor":
      return "builder";
    case "Client":
      return "owner";
    default:
      return '';
  }
};
