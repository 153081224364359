import styled from "styled-components";

const BottomSheetContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const BottomSheetOverlay = styled.div`
  background-color: #000000;
  opacity: 0.5;
  width: 100%;
  height: 100vh;
`;

const BottomSheetContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #ffffff;
  padding: 24px 32px 100px;

  & > *:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const BottomSheet = ({ children, closeBottomSheet }) => {
  return (
    <BottomSheetContainer>
      <BottomSheetOverlay onClick={() => closeBottomSheet()} />
      <BottomSheetContent>{children}</BottomSheetContent>
    </BottomSheetContainer>
  );
};

export default BottomSheet;
