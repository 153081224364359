import React, { useContext } from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import { HugeText, LargeText } from "../../../components/share/StyledText";
import FlexBox from "../../../components/share/FlexBox";
import CheckListCommentModal from "../../../components/feature/Project/CheckList/CheckListCommentModal";
import Checker from "../../../components/feature/Project/CheckList/Checker";
import EvaluatorDetailModal from "../../../components/feature/Project/CheckList/EvaluatorDetailModal";
import WeeklyEvaluationModal from "../../../components/feature/Project/CheckList/WeeklyEvaluationModal";
import Loader from "../../../components/share/Loader";
import Viewer from "../../../components/feature/Project/CheckList/Viewer";
import FullViewModal from "../../../components/feature/Project/FullViewModal";
import { CheckListDetailContext } from "./CheckListDetailContainer";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) =>
    theme.isApp ? "40px 20px 30px" : "20px 20px 30px"};
`;
const Header = styled.div`
  //max-width: 768px;
  max-width: 430px;
  padding-bottom: 12px;
`;
const StatusText = styled(LargeText)`
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
  color: ${({ theme, status }) =>
    status ? theme.subTextColor : theme.activeColor};
`;

const CheckListDetailPresenter = ({
  language = "Kor",
  loading = true,
  evaluationPermission,
  checkListDetail,
  weeklySafetyCategory = [],
  uploadLoading,
}) => {
  const { fullView, setFullView, wclImages } = useContext(
    CheckListDetailContext,
  );

  if (loading || uploadLoading) return <Loader height="100vh" />;

  return (
    <Wrapper id="check-list-detail-wrapper">
      <Header>
        <GoBack
          route="/check-list"
          DOWNLOAD={checkListDetail?.wcl_status === "complete"}
          onClickDownload={() =>
            window.open(checkListDetail?.wcl_evaluationPdf)
          }
        />
        <FlexBox alignItems="flex-end" justifyContent="space-between">
          <HugeText>
            {language === "Kor" &&
              `${checkListDetail?.wcl_year}년 ${checkListDetail?.wcl_month}월 ${checkListDetail?.wcl_week}주차`}
            {language === "Eng" &&
              `${checkListDetail?.wcl_year}. ${checkListDetail?.wcl_month}. ${checkListDetail?.wcl_week}week`}
          </HugeText>
          <StatusText status={checkListDetail?.wcl_status === "complete"}>
            {checkListDetail?.wcl_status === "complete"
              ? (language === "Kor" && "평가완료") ||
                (language === "Eng" && "Completed")
              : (language === "Kor" && "평가진행중") ||
                (language === "Eng" && "In progress")}
          </StatusText>
        </FlexBox>
      </Header>

      {evaluationPermission === 1 ? (
        <Checker
          checkListDetail={checkListDetail}
          weeklySafetyCategory={weeklySafetyCategory}
        />
      ) : (
        <Viewer />
      )}

      {/* 종합 검토 의견 입력 모달 */}
      <CheckListCommentModal />

      {/* 평가완료 상세보기 모달 */}
      <EvaluatorDetailModal />

      {/* 평가진행중 상세보기 모달 */}
      <WeeklyEvaluationModal />

      {fullView && (
        <FullViewModal
          fullView={fullView}
          setFullView={setFullView}
          images={wclImages.map((img) => img.wcli_url)}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(CheckListDetailPresenter);
