import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { LargeText, MediumText, SmallText } from "../../share/StyledText";
import clockIcon from "../../../assets/icons/project/clockIcon.svg";
import { lightTheme } from "../../../styles/theme";
import warningIcon from "../../../assets/icons/project/warningIcon.svg";
import ProfileImage from "../../share/ProfileImage";
import exclamationIcon from "../../../assets/icons/project/exclamationIcon.svg";
import {
  engGradeChanger,
  gradeChanger,
  prjStatusChanger,
} from "../../../lib/ProjectUtils";
import { dateFormat } from "../../../utils/commons";
import { engGroupChanger, groupChanger } from "../../../lib/ProfileUtils";
import { ThemeContext } from "../../../context/ThemeProvider";

const Wrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const ScarTitle = styled(LargeText)`
  word-break: break-all;
  line-height: 1.2;
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  margin: ${({ margin }) => (margin ? margin : 0)};
  white-space: nowrap;
  word-break: keep-all;
`;
const Image = styled.img`
  margin: ${({ margin }) => (margin ? margin : 0)};
`;
const WarningText = styled.span`
  color: ${({ fontColor }) => fontColor};
  font-family: ${({ fontColor }) =>
    fontColor === lightTheme.negativeColor
      ? "AppleSDGothicNeoBold"
      : "AppleSDGothicNeoMedium"};
`;
const ScarImgBox = styled.div`
  width: 38px;
  height: 38px;
  overflow: hidden;
  border-radius: 10px;
  border: ${({ image }) => (image ? "none" : "1px solid lightgray")};
`;
const ScarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Count = styled(MediumText)`
  color: ${({ theme }) => theme.subTextColor};
  margin-left: 12px;
`;
const ProcessDot = styled.div`
  width: 12px;
  height: 12px;
  margin: 1px 6px 0 0;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
`;
const RejectMessageBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;
const ErrorText = styled(MediumText)`
  max-width: 200px;
  color: ${({ theme }) => theme.negativeColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const LastSignTarget = styled.span`
  font-weight: bold;
  padding-left: 10px;
`;

const ScarBriefInfoBox = ({ scar }) => {
  const { language } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [warningColor, setWarningColor] = useState(lightTheme.defaultTextColor);
  const [processDotColor, setProcessDotColor] = useState(
    lightTheme.subTextColor
  );

  const onClickScarDetail = useCallback(() => {
    navigate(`/scar-detail/${scar.scar_id}`);
  }, [scar]);

  useEffect(() => {
    switch (scar?.scar_riskGrade) {
      case "A":
        setWarningColor(lightTheme.negativeColor);
        break;
      case "B":
        setWarningColor(lightTheme.activeYellow);
        break;
      case "C":
        setWarningColor(lightTheme.defaultTextColor);
        break;
    }
  }, [scar?.scar_riskGrade]);

  useEffect(() => {
    switch (scar?.scar_signStatus) {
      case "impossible": //조치불가
        setProcessDotColor(lightTheme.negativeColor);
        break;
      case "reject":
        setProcessDotColor(lightTheme.negativeColor);
        break;
      case "complete": //완료
        setProcessDotColor(lightTheme.subTextColor);
        break;
      default:
        setProcessDotColor(lightTheme.activeColor);
        break;
    }
  }, [scar?.scar_signStatus]);

  return (
    <Wrapper onClick={onClickScarDetail}>
      <FlexBox justifyContent="space-between">
        <FlexBox flexDirection="column">
          <ScarTitle>{scar?.scar_title}</ScarTitle>
          <MediumText margin="6px 0 8px">{scar?.scar_number}</MediumText>
          <FlexBox>
            <Image margin="0 6px 0 0" src={clockIcon} />
            {dateFormat(Number(scar?.scar_createdAt), "yyyy.MM.dd")} -{" "}
            {dateFormat(scar?.scar_endDate, "yyyy.MM.dd")}
          </FlexBox>
          <FlexBox margin="6px 0 26px" alignItems="flex-start">
            <Image margin="0 6px 0 0" src={warningIcon} />
            <WarningText>
              {(language === "Kor" && "위험등급") ||
                (language === "Eng" && "Risk Grade")}
              &nbsp;:&nbsp;
            </WarningText>
            <WarningText fontColor={warningColor}>
              {(language === "Kor" && gradeChanger(scar?.scar_riskGrade)) ||
                (language === "Eng" && engGradeChanger(scar?.scar_riskGrade))}
            </WarningText>
          </FlexBox>
        </FlexBox>

        <FlexBox flexDirection="column">
          <ProfileImage
            width={46}
            height={46}
            src={scar?.signLine?.create_img}
          />
          <MediumText margin="6px 0 26px">
            {(language === "Kor" &&
              groupChanger(scar?.signLine?.create_group)) ||
              (language === "Eng" &&
                engGroupChanger(scar?.signLine?.create_group))}
          </MediumText>
        </FlexBox>
      </FlexBox>

      <FlexBox justifyContent="space-between">
        <FlexBox alignItems="center">
          <ScarImgBox image={!!scar?.img[0]?.si_img}>
            <ScarImage src={scar?.img[0]?.si_img} />
          </ScarImgBox>
          <Count>x {scar?.img?.length}</Count>
        </FlexBox>

        <FlexBox flexDirection="column" alignItems="flex-end">
          <FlexBox alignItems="center" margin="0 0 2px">
            <ProcessDot bgColor={processDotColor} />
            {prjStatusChanger(scar?.scar_signStatus, language)} :{" "}
            {scar?.scar_signStatus === "reject" ||
            scar?.scar_signNumber === 7 ? (
              <>
                {(language === "Kor" && "반려") ||
                  (language === "Eng" && "Reject")}
              </>
            ) : scar?.scar_signNumber === 6 ? (
              <>
                {(language === "Kor" && "완료") ||
                  (language === "Eng" && "Complete")}
              </>
            ) : scar?.scar_signNumber === 1 ? (
              <>
                {(language === "Kor" && "발행") ||
                  (language === "Eng" && "Issued")}
              </>
            ) : (
              <>
                {(language === "Kor" && "진행중") ||
                  (language === "Eng" && "In progress")}
              </>
            )}
          </FlexBox>
          <FlexBox>({scar?.scar_signNumber} / 6)<LastSignTarget>({scar?.signLine.finalSign_name})</LastSignTarget></FlexBox>

          {scar?.rejectContent && scar?.rejectContent !== "" && (
            <RejectMessageBox>
              <Image margin="4px 6px 0 0" src={exclamationIcon} />
              <ErrorText>{scar?.rejectContent}</ErrorText>
            </RejectMessageBox>
          )}
        </FlexBox>
      </FlexBox>
    </Wrapper>
  );
};
export default React.memo(ScarBriefInfoBox);
