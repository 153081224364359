import React, { memo } from "react";
import styled, { css } from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {
  HugeText,
  LargeText,
  MediumText,
  SmallText,
} from "../../../components/share/StyledText";
import FlexBox from "../../../components/share/FlexBox";
import { lightTheme } from "../../../styles/theme";
import StyledInput from "../../../components/share/StyledInput";
import StyledSelect from "../../../components/share/StyledSelect";
import emptyUser from "../../../assets/images/emptyUser.png";
import rightArrow from "../../../assets/icons/sideBar/sideBarArrow.svg";
import deleteIcon from "../../../assets/icons/project/deleteIcon.svg";
import StyledImgUpload from "../../../components/share/StyledImgUpload";
import StyledButton from "../../../components/share/StyledButton";
import SearchRiskCodeModal from "../../../components/feature/Project/SearchRiskCodeModal";
import ApprovalSettingModal from "../../../components/feature/Project/ApprovalSettingModal";
import Chips from "../../../components/share/Chips";
import emptyImg from "../../../assets/icons/share/emptyImg.svg";
import Loader from "../../../components/share/Loader";

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
`;
const Header = styled.div`
  position: sticky;
  top: 20px;
  z-index: 1;
  margin-bottom: 30px;

  &:before {
    content: "";
    width: calc(100% + 40px);
    height: ${({ theme }) => (theme.isApp ? "150px" : "130px")};
    position: absolute;
    top: ${({ theme }) => (theme.isApp ? "-40px" : "-20px")};
    left: -20px;
    z-index: -1;
    background-color: ${({ theme }) => theme.lightBorderColor};
  }
`;
const UserName = styled(MediumText)`
  max-width: 146px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ScrollBox = styled.div`
  padding: 20px 0;
  overflow-y: scroll;
`;
const ApprovalBox = styled(FlexBox)`
  justify-content: space-between;
  padding: 0 0 20px;
  margin: 0 0 14px;
  border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
`;
const Profile = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
`;
const ProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  right: ${({ right }) => (right ? right : 0)}px;
`;
const CountBox = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.subTextColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.lightBorderColor};
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius}px;
    `};
`;
const Icon = styled.img`
  margin-left: 4px;
`;
const ChipBox = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 10px 14px;
  margin: 16px 0 14px;
`;
const DeleteIcon = styled.img`
  position: absolute;
  top: -4px;
  right: -4px;
`;
const PreviewBox = styled.div`
  width: 55px;
  height: 55px;
  position: relative;
  margin-right: 10px;
  border-radius: 8px;
`;
const RankText = styled(MediumText)`
  max-width: 146px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CreateScarPresenter = ({
  language = "Kor",
  inputs,
  onChangeInput,
  handleDeleteLabel,
  riskCodeModal,
  setRiskCodeModal,
  approvalSettingModal,
  setApprovalSettingModal,
  approvalList,
  onChangeImage,
  handleDeleteImage,
  buttonStatus,
  handleCreateScar,
  labelList,
  scarThumbnail,
  myProfile,
  createScarLoading,
}) => {
  return (
    <Wrapper>
      <Header>
        <GoBack route="/project-detail" />
        <FlexBox justifyContent="space-between">
          <HugeText>
            {language === "Kor" && "SCAR 발행하기"}
            {language === "Eng" && "Create SCAR"}
          </HugeText>
          <FlexBox alignItems="flex-start">
            <Profile>
              <Image
                src={myProfile?.user_profileImg || emptyUser}
                onError={(e) => (e.target.src = emptyUser)}
              />
            </Profile>
            {myProfile?.user_name.length > 8 ||
            myProfile?.user_rank.length > 8 ? (
              <FlexBox flexDirection="column" alignItems="flex-end">
                <UserName>123 {myProfile?.user_name}</UserName>
                <RankText margin="4px 0 0">
                  랭크 {myProfile?.user_rank}
                </RankText>
              </FlexBox>
            ) : (
              <FlexBox>
                <UserName>{myProfile?.user_name}</UserName>&nbsp;
                <MediumText>{myProfile?.user_rank}</MediumText>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </Header>
      <ScrollBox>
        <ApprovalBox onClick={() => setApprovalSettingModal(true)}>
          <LargeText fontFamily="AppleSDGothicNeoBold">
            {language === "Kor" && "결재라인"}
            {language === "Eng" && "Approval Process"}
          </LargeText>
          <FlexBox>
            {!!approvalList ? (
              <>
                {[
                  approvalList?.create?.img,
                  approvalList?.check?.img,
                  approvalList?.actionCheck?.img,
                ].map(
                  (
                    src,
                    index, // 유저 이미지 1, 2, 3
                  ) => (
                    <ProfileImage
                      src={!!src ? src : emptyImg}
                      key={`${index}_img`}
                      right={(index === 0 && -12) || (index === 1 && -6)}
                    />
                  ),
                )}
                <CountBox>+{3}</CountBox>
              </>
            ) : (
              <LargeText fontColor={lightTheme.subTextColor}>
                {language === "Kor" && "설정하기"}
                {language === "Eng" && "Edit"}
              </LargeText>
            )}
            <Icon src={rightArrow} />
          </FlexBox>
        </ApprovalBox>
        <StyledInput
          label={
            (language === "Kor" &&
              `위반사항* (${inputs.violation.length} / 80)`) ||
            (language === "Eng" &&
              `Violation Description* (${inputs.violation.length} / 80)`)
          }
          name="violation"
          value={inputs.violation}
          onChange={onChangeInput}
        />
        <StyledInput
          label={
            (language === "Kor" &&
              `위반위치 ${inputs.location.length} / 80)`) ||
            (language === "Eng" && `Location (${inputs.location.length} / 80)`)
          }
          margin="14px 0"
          name="location"
          value={inputs.location}
          onChange={onChangeInput}
        />
        <StyledSelect
          label={
            (language === "Kor" && "라벨*") || (language === "Eng" && "Labels*")
          }
          name="label"
          placeholder={
            (language === "Kor" && "라벨 선택하기") ||
            (language === "Eng" && "Select Label")
          }
          value={inputs.label}
          onChange={onChangeInput}
          options={labelList}
        />
        <ChipBox>
          {inputs.labels.map(
            (
              chip,
              index, // 라벨 렌더링
            ) => (
              <Chips
                key={`${chip}_${index}`}
                chip={chip}
                handleDeleteChip={handleDeleteLabel}
              />
            ),
          )}
        </ChipBox>
        <FlexBox justifyContent="space-between">
          <StyledSelect
            label={
              (language === "Kor" && "위험등급*") ||
              (language === "Eng" && "Risk Impact*")
            }
            width="48%"
            name="riskImpact"
            value={inputs.riskImpact}
            options={
              (language === "Kor" && ["상", "중", "하"]) ||
              (language === "Eng" && ["High", "Medium", "Low"])
            }
            onChange={onChangeInput}
          />
          <StyledInput
            RIGHT_ARROW
            label={
              (language === "Kor" && "리스크코드") ||
              (language === "Eng" && "Risk Code")
            }
            width="48%"
            name="riskCode"
            value={inputs.riskCode}
            onClick={() => setRiskCodeModal(true)}
          />
        </FlexBox>
        <SmallText fontColor={lightTheme.negativeColor} margin="10px 0 0">
          {inputs.riskImpact === "상" || inputs.riskImpact === "High" ? (
            <>
              {language === "Kor" && "발행일 자정까지 조치"}
              {language === "Eng" && "Action by midnight on the date of issue"}
            </>
          ) : inputs.riskImpact === "중" || inputs.riskImpact === "Medium" ? (
            <>
              {language === "Kor" && "발행일 익일 자정까지 조치"}
              {language === "Eng" && "Action by midnight the day after issue"}
            </>
          ) : (
            <>
              {language === "Kor" && "발행일 4일 후 자정까지 조치"}
              {language === "Eng" &&
                "Action taken until midnight four days after the date of issue"}
            </>
          )}
        </SmallText>
        <StyledImgUpload
          multiple
          label={
            (language === "Kor" && "사진*") ||
            (language === "Eng" && "Violation Images*")
          }
          placeholder={
            (language === "Kor" && "최대 5장까지 업로드 할 수 있습니다.") ||
            (language === "Eng" && "You can upload up to 5 images.")
          }
          onChange={onChangeImage}
        />
        <FlexBox>
          {scarThumbnail.map(
            (
              url,
              index, // 스카 이미지 렌더링
            ) => (
              <PreviewBox key={`image-${index}`}>
                <Image borderRadius={8} src={url} />
                <DeleteIcon
                  src={deleteIcon}
                  onClick={() => handleDeleteImage(index)}
                />
              </PreviewBox>
            ),
          )}
        </FlexBox>
        <StyledButton
          title={
            createScarLoading ? (
              <Loader />
            ) : (
              (language === "Kor" && "SCAR 발행하기") ||
              (language === "Eng" && "Create SCAR")
            )
          }
          margin="60px 0 40px"
          onClick={handleCreateScar}
          disabled={buttonStatus || createScarLoading}
        />
        <SearchRiskCodeModal // 리스크 코드 모달
          riskCodeModal={riskCodeModal}
          setRiskCodeModal={setRiskCodeModal}
        />
        <ApprovalSettingModal // 결재라인 설정 모달
          approvalSettingModal={approvalSettingModal}
          setApprovalSettingModal={setApprovalSettingModal}
        />
      </ScrollBox>
    </Wrapper>
  );
};

export default memo(CreateScarPresenter);
