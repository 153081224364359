import { gql } from "@apollo/client";

export const CREATE_SCAR_NOTI = gql`
  subscription createScarNoti($userId: Int) {
    createScarNoti(user_id: $userId) {
      user_id
      msg
      createdAt
    }
  }
`;

export const CREATE_PROJECT_NOTI = gql`
  subscription createProjectNoti($memberId: Int) {
    createProjectNoti(member_id: $memberId) {
      member_id
      msg
      createdAt
    }
  }
`;

export const SCAR_APPROVAL_NOTI = gql`
  subscription ScarApprovalNoti($userId: Int) {
    scarApprovalNoti(user_id: $userId) {
      user_id
      msg
    }
  }
`;
