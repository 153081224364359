import React, { useContext } from "react";
import styled, { css } from "styled-components";
import CustomSelect from "../../share/CustomSelect";
import { LargeText } from "../../share/StyledText";
import StyledInput from "../../share/StyledInput";
import Chips from "../../share/Chips";
import FlexBox from "../../share/FlexBox";
import CheckBox from "../../share/CheckBox";
import StyledButton from "../../share/StyledButton";
import { ThemeContext } from "../../../context/ThemeProvider";
import StyledSelect from "../../share/StyledSelect";
import countryCode from "../../../utils/countryCode";

const ChipBox = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 10px 14px;
  margin: 16px 0 14px;
`;

const RowBox = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
`;

const CirculatorBtn = styled.div`
  padding: 5px 10px;
  flex-shrink: 0;
  border-radius: 8px;
  margin: 10px 10px 10px 0;
  border: ${({ theme }) => `1px solid ${theme.borderColor}`};
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => `${theme.whiteColor}`};
      border: ${({ theme }) => `1px solid ${theme.activeColor}`};
      background-color: ${({ theme }) => `${theme.activeColor}`};
    `}
`;

const ApprovalSelfForm = ({
  memberList,
  approvalLine,
  onChangeSelect,
  circulatorSelect,
  setCirculatorSelect,
  country,
  setCountry,
  dist,
  setDist,
  handleCreateDist,
  onBlurDist,
  handleDeleteDist,
  name,
  handleName,
  agree,
  handleAgree,
  handleSetApprovalLine,
  buttonStatus,
}) => {
  const { language } = useContext(ThemeContext);

  return (
    <>
      <CustomSelect
        label={
          (language === "Kor" && "발행자") ||
          (language === "Eng" && "HSE Officer (PM)")
        }
        options={memberList}
        name="create"
        value={approvalLine["create"]}
        onChange={onChangeSelect}
        disabled
      />
      <CustomSelect
        margin="20px 0"
        label={
          (language === "Kor" && "발행확인자") ||
          (language === "Eng" && "Resident Engineer (PM)")
        }
        options={memberList}
        name="check"
        value={approvalLine["check"]}
        onChange={onChangeSelect}
      />
      <CustomSelect
        label={
          (language === "Kor" && "조치자") ||
          (language === "Eng" && "Safety Officer")
        }
        options={memberList}
        name="action"
        value={approvalLine["action"]}
        onChange={onChangeSelect}
      />
      <CustomSelect
        margin="20px 0"
        label={
          (language === "Kor" && "책임자") ||
          (language === "Eng" && "Safety Manager")
        }
        options={memberList}
        name="manager"
        value={approvalLine["manager"]}
        onChange={onChangeSelect}
      />
      <CustomSelect
        label={
          (language === "Kor" && "조치확인자") ||
          (language === "Eng" && "HSE Officer")
        }
        options={memberList}
        name="actionCheck"
        value={approvalLine["actionCheck"]}
        onChange={onChangeSelect}
      />
      <CustomSelect
        margin="20px 0"
        label={
          (language === "Kor" && "최종승인자") ||
          (language === "Eng" && "Resident Engineer")
        }
        options={memberList}
        name="finalSign"
        value={approvalLine["finalSign"]}
        onChange={onChangeSelect}
      />
      <LargeText>
        {language === "Kor" && "회람자"}
        {language === "Eng" && "Distribution"}
      </LargeText>
      <RowBox>
        <CirculatorBtn
          active={circulatorSelect === "email"}
          onClick={() => setCirculatorSelect("email")}
        >
          {language === "Kor" && "이메일"}
          {language === "Eng" && "Email"}
        </CirculatorBtn>
        <CirculatorBtn
          active={circulatorSelect === "phone"}
          onClick={() => setCirculatorSelect("phone")}
        >
          {language === "Kor" && "휴대폰번호"}
          {language === "Eng" && "Phone number"}
        </CirculatorBtn>
      </RowBox>
      <RowBox>
        {circulatorSelect === "phone" && (
          <StyledSelect
            margin="0 20px 0 0"
            label={
              (language === "Kor" && "국가") ||
              (language === "Eng" && "Country Code")
            }
            options={countryCode}
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          />
        )}

        <StyledInput
          PLUS_BUTTON
          label={
            (language === "Kor" && "연락처 또는 메일로 추가") ||
            (language === "Eng" && "Add as phone number or mail")
          }
          // margin="0 0 0 20px"
          value={dist}
          type={circulatorSelect === "phone" ? "number" : "text"}
          onChange={(e) => setDist(e.target.value)}
          onCreate={handleCreateDist}
          onBlur={onBlurDist}
        />
      </RowBox>
      {(approvalLine.circulators || []).length !== 0 && ( // 회람자 데이터가 있을 경우 렌더링
        <ChipBox>
          {approvalLine.circulators.map((chip, index) => (
            <Chips
              key={`${chip}_${index}`}
              chip={chip}
              handleDeleteChip={handleDeleteDist}
            />
          ))}
        </ChipBox>
      )}
      <LargeText margin="20px 0 0">
        {language === "Kor" && "결제라인 이름"}
        {language === "Eng" && "Approval Process Name"}
      </LargeText>
      <FlexBox margin="16px 0 20px">
        <StyledInput
          name="name"
          value={name}
          onChange={({ target: { value } }) => handleName(value)}
        />
      </FlexBox>
      <FlexBox margin="26px 0 22px">
        <CheckBox
          label={
            (language === "Kor" && "위 결재 라인을 저장합니다.") ||
            (language === "Eng" && "Save the above process")
          }
          margin="0 4px 0 0"
          fontFamily="AppleSDGothicNeoRegular"
          checked={agree}
          onChange={handleAgree}
        />
      </FlexBox>
      <StyledButton
        title={
          (language === "Kor" && "결재라인 설정하기") ||
          (language === "Eng" && "Set Approval Process")
        }
        margin="0 0 100px"
        onClick={handleSetApprovalLine}
        disabled={buttonStatus}
      />
    </>
  );
};

export default React.memo(ApprovalSelfForm);
