import { gql } from "@apollo/client";

export const SEE_MY_NOTIFICATION = gql`
  query seeMyNotification {
    seeMyNotification {
      result
      notificationList {
        notifi_id
        notifi_createdAt
        notifi_content
      }
      error
    }
  }
`;
