import React from "react";
import styled from "styled-components";
import {HugeText} from "../../../components/share/StyledText";
import GoBack from "../../../components/share/GoBack";
import ToggleSwitch from "../../../components/share/ToggleSwitch";

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ToggleBox = styled.div`
  padding: 18px 0;
  border-bottom: 1px solid ${({theme}) => theme.lightBorderColor};
`;

const NotificationPresenter = ({
                                   notifications,
                                   handleToggleSwitch,
                                   language,
                               }) => {
    return (
        <Wrapper>
            <GoBack/>
            <HugeText margin="0 0 26px">
                {(language === "Kor" && "알림 설정하기") ||
                (language === "Eng" && "Set Notification")}
            </HugeText>
            <ToggleBox>
                <ToggleSwitch
                    name="user_pushNoti"
                    value={notifications?.user_pushNoti}
                    onChange={handleToggleSwitch}
                >
                    {(language === "Kor" && "푸시 알림") ||
                    (language === "Eng" && "Push Notification")}
                </ToggleSwitch>
            </ToggleBox>
            <ToggleBox>
                <ToggleSwitch
                    name="user_msgNoti"
                    value={notifications?.user_msgNoti}
                    onChange={handleToggleSwitch}
                >
                    {(language === "Kor" && "메세지 알림") ||
                    (language === "Eng" && "Message Notification")}
                </ToggleSwitch>
            </ToggleBox>
            <ToggleBox>
                <ToggleSwitch
                    name="user_emailNoti"
                    value={notifications?.user_emailNoti}
                    onChange={handleToggleSwitch}
                >
                    {(language === "Kor" && "이메일 알림") ||
                    (language === "Eng" && "Email Notification")}
                </ToggleSwitch>
            </ToggleBox>
            <ToggleBox>
                <ToggleSwitch
                    name="user_kakaoNoti"
                    value={notifications?.user_kakaoNoti}
                    onChange={handleToggleSwitch}
                >
                    {(language === "Kor" && "알림톡") ||
                    (language === "Eng" && "Tok Notification")}
                </ToggleSwitch>
            </ToggleBox>
        </Wrapper>
    );
};

export default React.memo(NotificationPresenter);
