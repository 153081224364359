import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import closeIcon from "../../../assets/icons/share/closeIcon.svg";
import { HugeText } from "../../share/StyledText";
import StyledInput from "../../share/StyledInput";
import { ThemeContext } from "../../../context/ThemeProvider";
import StyledButton from "../../share/StyledButton";
import { lightTheme } from "../../../styles/theme";
import { SCAR_APPROVAL } from "../../../graphql/Project/ScarDetail/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { ScarDetailContext } from "../../../pages/Project/ScarDetail/ScarDetailContainer";
import { SEE_MY_SIGN_PROJECT } from "../../../graphql/Project/ProjectList/query";
import { SEE_SCAR_LIST } from "../../../graphql/Project/ProjectDetail/query";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  height: 100vh;
  max-height: 932px;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
  position: fixed;
  top: 0;
  right: -100%;
  z-index: -1;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.3s;

  ${({ theme, scarRejectModal }) =>
    scarRejectModal &&
    css`
      right: ${theme.isTablet ? theme.isTabletLeft : 0};
      z-index: 10;
      //transform: translateY(0);
      //bottom: 0;
    `};
`;
const Icon = styled.img`
  margin: 12px 0 24px;
`;

const ScarRejectModal = ({ scarRejectModal, setScarRejectModal }) => {
  const { language } = useContext(ThemeContext);
  const { scarRefetch } = useContext(ScarDetailContext);
  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });
  const [reject, setReject] = useState("");
  const { scarId } = useParams();

  const [scarApproval] = useMutation(SCAR_APPROVAL);

  const handleReject = useCallback(async () => {
    if (reject === "") {
      alert(
        (language === "Kor" && "반려사유를 입력해주세요.") ||
          (language === "Eng" && "Please enter reasons for a rejection"),
      );
      return;
    }
    try {
      const { data } = await scarApproval({
        variables: {
          scar_id: Number(scarId),
          approval: false,
          rejectText: reject,
        },
      });
      if (data?.scarApproval?.result) {
        alert(
          (language === "Kor" && "반려를 완료했습니다.") ||
            (language === "Eng" && "Reject successfully"),
        );
        await scarRefetch();
        setScarRejectModal(false);
        await signLineRefetch();
        await scarListRefetch();
      }
      if (data?.scarApproval?.error !== "") {
        alert(data?.scarApproval?.error);
      }
    } catch (e) {
      alert(e.message);
    }
  }, [reject]);

  return (
    <Wrapper scarRejectModal={scarRejectModal}>
      <Icon src={closeIcon} onClick={() => setScarRejectModal(false)} />
      <HugeText>
        {language === "Kor" && "반려사유 입력하기"}
        {language === "Eng" && "Reason for ejection"}
      </HugeText>
      <StyledInput
        label={
          (language === "Kor" && `반려사유 입력 (${reject.length} / 80)`) ||
          (language === "Eng" &&
            `Enter reasons for a rejection (${reject.length} / 80)`)
        }
        margin="26px 0 0"
        value={reject}
        onChange={(e) => setReject(e.target.value)}
      />
      <StyledButton
        title={
          (language === "Kor" && "반려하기") || (language === "Eng" && "Reject")
        }
        fontColor={lightTheme.whiteColor}
        margin="40px 0"
        border="none"
        bgColor={lightTheme.negativeColor}
        onClick={handleReject}
      />
    </Wrapper>
  );
};

export default React.memo(ScarRejectModal);
