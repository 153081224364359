import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { darkTheme, lightTheme } from "../../styles/theme";
import { SmallText } from "./StyledText";
import { ThemeContext } from "../../context/ThemeProvider";
import DaumPostcode from "react-daum-postcode";
import postSearchIcon from "../../assets/icons/project/postSearchIcon.svg";
import { FiX } from "react-icons/fi";
import AddressModal from "./AddressModal";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0;
`;
const Input = styled.input`
  width: 90%;
  height: auto;
  font-size: 18px;

  &::placeholder {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.subTextColor};
  }
  &:disabled {
    color: ${({ theme, activeTextColor }) =>
      activeTextColor ? theme.defaultTextColor : theme.subTextColor};
    opacity: 1;
  }
`;
const Image = styled.img``;
const ModalBackground = styled.div`
  position: absolute;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;
const PostBox = styled.div`
  width: calc(100% - 32px);
  height: 480px;
  padding: 40px 20px;
  margin: 16vh 0;
  border-radius: 8px;
  position: relative;
  background: ${({ theme }) => theme.bgColor};
  overflow: hidden;
`;
const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const StyledPost = ({
  label = "",
  width = "100%",
  margin = 0,
  postModal,
  setPostModal,
  handleCompletePost = () => null,
  value,
}) => {
  const { themeMode, language } = useContext(ThemeContext);

  return (
    <Wrapper width={width} margin={margin}>
      <SmallText
        fontColor={
          themeMode === "light"
            ? lightTheme.subTextColor
            : darkTheme.subTextColor
        }
      >
        {label}
      </SmallText>
      <FlexBox onClick={() => setPostModal(true)}>
        <Input
          value={value}
          placeholder={
            (language === "Kor" && "주소를 검색해주세요.") ||
            (language === "Eng" && "Search For the Address")
          }
          disabled
          activeTextColor={value !== ""}
        />
        <Image src={postSearchIcon} />
      </FlexBox>

      {postModal && (
        <AddressModal
          postModal={postModal}
          setPostModal={setPostModal}
          handleCompletePost={handleCompletePost}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(StyledPost);
