import React, { useContext, useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import bellIcon from "../../../assets/icons/project/bell.svg";
import { LargeText } from "../../share/StyledText";
import { ThemeContext } from "../../../context/ThemeProvider";
import { lightTheme } from "../../../styles/theme";
import { useNavigate, useParams } from "react-router-dom";
import ModalBackground from "../../share/ModalBackground";
import { useMutation, useQuery } from "@apollo/client";
import {
  REQUEST_CLOSE_PROJECT,
  SAFEY_ACTION_DOCUMENT_DOWN,
  SCAR_DELETE,
} from "../../../graphql/Project/ProjectDetail/mutation";
import { SEE_PROJECT_DETAIL } from "../../../graphql/Project/ProjectDetail/query";

const Content = styled.div`
  width: 296px;
  border-radius: 8px;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%, 50%);
  //transform: translateY(200%);
  transition: 0.3s;
  background-color: ${({ theme }) => theme.bgColor};

  ${({ notifyModal }) =>
    notifyModal &&
    css`
      bottom: 50%;
      z-index: 100;
      //transform: translateY(0);
    `};
`;
const Header = styled.div`
  height: 196px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${({ theme }) => theme.lightBorderColor};
`;
const Image = styled.img``;
const TabButton = styled.div`
  height: 60px;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultTextColor};
  border-bottom: 1px solid ${({ theme }) => theme.lightBorderColor};
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-bottom: none;
  }
`;

const NotifyModal = ({
  PROJECT,
  SCAR,
  scarImage = "",
  notifyModal,
  setNotifyModal,
  prjInfo,
  handleQuitProject = () => null,
  projectPrivate,
}) => {
  const navigate = useNavigate();
  const prjId = Number(localStorage.getItem("prjId"));
  // const { prjDetailRefetch } = useContext(ProjectDetailContext);
  const { language } = useContext(ThemeContext);
  const { scarId } = useParams();
  const [requestCloseProject] = useMutation(REQUEST_CLOSE_PROJECT);
  const [safeyActionDocumentDown] = useMutation(SAFEY_ACTION_DOCUMENT_DOWN);
  const [scarDelete] = useMutation(SCAR_DELETE);

  const { refetch: prjDetailRefetch } = useQuery(SEE_PROJECT_DETAIL, {
    variables: { prjId: prjId },
    fetchPolicy: "network-only",
  });

  const handleProjectTermination = useCallback(async () => {
    if (
      language === "Kor"
        ? window.confirm(
            "프로젝트 종료 요청 이후 취소할 수 없습니다.\n그래도 진행하시겠어요?",
          )
        : window.confirm(
            "Unable to cancel after project end request\nDo you want continue?",
          )
    ) {
      try {
        const { data } = await requestCloseProject({
          variables: {
            prj_id: prjId,
          },
        });
        if (data?.requestCloseProject?.result) {
          await prjDetailRefetch();
          language === "Kor"
            ? alert("종료 요청을 보냈습니다.")
            : alert("Send Require for End");
          setNotifyModal(false);
        }
      } catch (e) {
        alert(e.message);
      }
    }
  }, []);

  const handleSafeyDocDown = useCallback(async () => {
    try {
      const { data } = await safeyActionDocumentDown({
        variables: {
          scarId: Number(scarId),
        },
      });
      if (data?.safeyActionDocumentDown?.result) {
        language === "Kor"
          ? alert("안전조치시정서를 다운로드하였습니다.")
          : alert("Downloaded successfully");
        window.open(data?.safeyActionDocumentDown?.docs);
      }
    } catch (e) {
      alert(e.message);
    }
  }, [scarId]);

  const handleDelete = useCallback(async () => {
    if (
      language === "Kor"
        ? window.confirm("스카를 삭제하시겠습니까?")
        : window.confirm(
            "SCAR data will be deleted permanently. This action cannot be undone!",
          )
    ) {
      try {
        const { data } = await scarDelete({
          variables: {
            scarId: Number(scarId),
          },
        });
        if (data?.scarDelete?.result) {
          language === "Kor"
            ? alert("스카를 삭제하였습니다.")
            : alert("Delete Scar");
          // await prjDetailRefetch();
          navigate("/project-detail");
          window.location.reload();
        }
        if (data?.scarDelete?.error !== "") {
          alert(data?.scarDelete?.error);
        }
      } catch (e) {
        alert(e.message);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const shareKakao = useCallback(() => {
    if (projectPrivate === "private") {
      alert(
        (language === "Kor" && "비공개 프로젝트입니다.") ||
          (language === "Eng" && "This is a private project"),
      );
      return;
    }

    if (!!window.ReactNativeWebView) {
      const data = {
        type: "SHARED",
        payload: `https://m-safety.riskzero.ai/scar/share/report/${scarId}`,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
          kakao.init("640f0af245aff7f6289189303a6141fc");
        }

        kakao.Share.sendDefault({
          objectType: "feed",
          content: {
            title: "회람자 링크 공유",
            description: "SCAR 링크를 공유합니다.",
            imageUrl: "",
            link: {
              mobileWebUrl: `https://m-safety.riskzero.ai/scar/share/report/${scarId}`,
              webUrl: `https://m-safety.riskzero.ai/scar/share/report/${scarId}`,
            },
          },
          buttons: [
            {
              title: "공유하기",
              link: {
                mobileWebUrl: `https://m-safety.riskzero.ai/scar/share/report/${scarId}`,
                webUrl: `https://m-safety.riskzero.ai/scar/share/report/${scarId}`,
              },
            },
          ],
        });
      }
    }
  }, [scarImage, scarId, projectPrivate]);

  return (
    <ModalBackground modalState={notifyModal}>
      <Content notifyModal={notifyModal}>
        <Header>
          <Image src={bellIcon} />
          <LargeText fontFamily="AppleSDGothicNeoBold" margin="20px 0 12px">
            {language === "Kor" && "알림"}
            {language === "Eng" && "Setting"}
          </LargeText>
          <LargeText>
            {language === "Kor" && "어떤 작업을 하시겠어요?"}
            {language === "Eng" && "Select the menu"}
          </LargeText>
        </Header>
        {PROJECT && (
          <>
            <TabButton onClick={() => navigate("/statistics")}>
              {language === "Kor" && "통계"}
              {language === "Eng" && "Statistics"}
            </TabButton>
            <TabButton
              onClick={() => navigate("/edit-project", { state: { prjInfo } })}
            >
              {language === "Kor" && "프로젝트 정보 수정하기"}
              {language === "Eng" && "Edit Project Details"}
            </TabButton>
            <TabButton
              onClick={() => navigate("/edit-members", { state: { prjId } })}
            >
              {language === "Kor" && "구성원 수정하기"}
              {language === "Eng" && "Edit Members"}
            </TabButton>
            <TabButton onClick={handleProjectTermination}>
              {language === "Kor" && "프로젝트 종료 요청하기"}
              {language === "Eng" && "Request to close project"}
            </TabButton>
            <TabButton
              fontColor={lightTheme.negativeColor}
              onClick={handleQuitProject}
            >
              {language === "Kor" && "프로젝트 탈퇴하기"}
              {language === "Eng" && "Quit Project"}
            </TabButton>
          </>
        )}
        {SCAR && (
          <>
            <TabButton
              fontColor={lightTheme.negativeColor}
              onClick={handleDelete}
            >
              {language === "Kor" && "SCAR 삭제하기"}
              {language === "Eng" && "Delete SCAR"}
            </TabButton>
            <TabButton onClick={handleSafeyDocDown}>
              {language === "Kor" && "안전조치시정서 다운"}
              {language === "Eng" && "Download as PDF"}
            </TabButton>
            <TabButton onClick={shareKakao}>
              {language === "Kor" && "회람자 링크 공유"}
              {language === "Eng" && "Share Link"}
            </TabButton>
          </>
        )}
        <TabButton
          fontColor={lightTheme.subTextColor}
          onClick={() => {
            setNotifyModal(false);
            document.body.style.overflow = "scroll";
          }}
        >
          {language === "Kor" && "닫기"}
          {language === "Eng" && "Close"}
        </TabButton>
      </Content>
    </ModalBackground>
  );
};

export default React.memo(NotifyModal);
