import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import { HugeText, LargeText } from "../../../components/share/StyledText";
import FlexBox from "../../../components/share/FlexBox";
import CheckListBox from "../../../components/feature/Project/CheckList/CheckListBox";
import Loader from "../../../components/share/Loader";
import { engMonthChang } from "../../../lib/ProjectUtils";
import CheckListStartKorButton from "../../../assets/images/button/btn-checkList-start-kor.png";
import CheckListStartEngButton from "../../../assets/images/button/btn-checkList-start-eng.png";
import CheckListInProgressKorButton from "../../../assets/images/button/btn-checkList-inprogress-kor.png";
import CheckListInProgressEngButton from "../../../assets/images/button/btn-checkList-inprogress-eng.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Header = styled.div`
  max-width: 430px;
  padding: ${({ theme }) =>
    theme.isApp ? "40px 20px 30px" : "20px 20px 30px"};
`;
const Title = styled(HugeText)`
  margin-bottom: 24px;
`;
const Line = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.borderColor};
`;
const ScrollBox = styled.div`
  height: calc(100vh - 200px);
  max-height: calc(932px - 200px);
  padding: 0 20px 60px;
  overflow-x: hidden;
  overflow-y: scroll;
`;
const Evaluation = styled.div`
  display: flex;
  align-items: center;
`;

const CheckListPresenter = ({
  language,
  handleEvaluation,
  loading,
  error,
  completeCheckList = [],
  thisWeekCheckList,
  evaluationPermission,
}) => {
  if (loading || error) return <Loader height="100vh" />;

  return (
    <Wrapper>
      <Header>
        <GoBack route="/project-detail" />
        <Title>
          {(language === "Kor" && "체크리스트 평가하기") ||
            (language === "Eng" && "Review Weekly Checklist")}
        </Title>
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          onClick={handleEvaluation}
        >
          <LargeText>
            {language === "Kor" &&
              `${thisWeekCheckList?.wcl_year}년 ${thisWeekCheckList?.wcl_month}월 ${thisWeekCheckList?.wcl_week}주차`}
            {language === "Eng" &&
              `${engMonthChang(thisWeekCheckList?.wcl_month)}. ${
                thisWeekCheckList?.wcl_week
              }nd week, ${thisWeekCheckList?.wcl_year}`}
          </LargeText>
          <Evaluation>
            {evaluationPermission !== 0
              ? (language === "Kor" && (
                  <img src={CheckListInProgressKorButton} alt="평가진행중" />
                )) ||
                (language === "Eng" && (
                  <img src={CheckListInProgressEngButton} alt="In Progress" />
                ))
              : (language === "Kor" && (
                  <img src={CheckListStartKorButton} alt="평가시작하기" />
                )) ||
                (language === "Eng" && (
                  <img src={CheckListStartEngButton} alt="Create" />
                ))}
          </Evaluation>
        </FlexBox>
      </Header>
      <Line />
      <ScrollBox>
        <LargeText margin="26px 0 6px">
          {(language === "Kor" && "완료 항목") ||
            (language === "Eng" && "Completed checklist")}
        </LargeText>
        {completeCheckList.map((list) => (
          <CheckListBox key={list.wcl_id} list={list} />
        ))}
      </ScrollBox>
    </Wrapper>
  );
};

export default React.memo(CheckListPresenter);
