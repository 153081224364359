import React from "react";
import styled, {css} from "styled-components";
import {HugeText, MediumText} from "../../../components/share/StyledText";
import GoBack from "../../../components/share/GoBack";
import excelIcon from "../../../assets/icons/project/excel.svg";
import StyledCalendar from "../../../components/share/StyledCalendar";
import StatisticsTableRow from "../../../components/feature/Project/StatisticsTableRow";
import CircleChart from "../../../components/feature/Project/CircleChart";
import StickChart from "../../../components/feature/Project/StickChart";
import FlexBox from "../../../components/share/FlexBox";
import Loader from "../../../components/share/Loader";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({theme}) => theme.isApp ? '216px 20px 0' : '196px 20px 0'};
`;
const Header = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 1;
  padding: ${({theme}) => theme.isApp ? '40px 20px 0' : '20px 20px 0'};
  background-color: ${({theme}) => theme.bgColor};
`;
const Contents = styled.div`
  padding-bottom: 80px;
  overflow: scroll;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
`;
const Image = styled.img`
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const TotalTableRow = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};

  ${({BACKGROUND}) =>
          BACKGROUND &&
          css`
            background-color: ${({theme}) => theme.lightBorderColor};
            border: none;
          `};

  ${({TOTAL}) =>
          TOTAL &&
          css`
            height: auto;
            padding: 10px 0 35px;
          `};
`;
const TotalTableCell = styled.div`
  width: ${({width}) => width}%;
  text-align: ${({textAlign}) => (textAlign ? textAlign : "right")};
  white-space: nowrap;

  &:nth-child(1) {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 12px;
  }
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 18px 0 0;
`;
const Button = styled.button`
  width: 48%;
  height: 50px;
  font-size: 16px;
  color: ${({theme}) => theme.subTextColor};
  border: 1px solid ${({theme}) => theme.subTextColor};
  border-radius: 4px;

  ${({active}) => active && css`
    font-weight: 600;
    color: ${({theme}) => theme.blackColor};
    border: 1px solid ${({theme}) => theme.activeBorderColor};
  `};
`;
const TableInfoBox = styled(FlexBox)`
  flex: 1;
  flex-direction: column;
`;
const GrayText = styled(MediumText)`
  font-family: "AppleSDGothicNeoRegular";
  color: ${({theme}) => theme.subTextColor};
  margin-bottom: 4px;
`;

const ProjectStatisticsPresenter = ({
                                        language = "Kor",
                                        period,
                                        onChangePeriod,
                                        handleExcelDownload,
                                        count,
                                        labels,
                                        status,
                                        createTotal,
                                        completeTotal,
                                        imcompleteTotal,

                                        buttonStatus,
                                        setButtonStatus,

                                        checkListStatsData,

                                        statsLoading,
                                        checkListLoading
                                    }) => {


    return (
        <Wrapper>
            <Header>
                <GoBack route='/project-detail'/>
                <Title>
                    <HugeText>
                        {language === "Kor" && "프로젝트 통계"}
                        {language === "Eng" && "Project SCAR Report"}
                    </HugeText>
                    <Image src={excelIcon} onClick={handleExcelDownload}/>
                </Title>
                <StyledCalendar
                    label={
                        (language === "Kor" && "기간 선택") ||
                        (language === "Eng" && "Select Duration")
                    }
                    value={period}
                    onChange={onChangePeriod}
                />
            </Header>
            <Contents>
                <ButtonBox>
                    <Button active={buttonStatus === 'SCAR'} onClick={() => setButtonStatus('SCAR')}>
                        {language === "Kor" && "SCAR 통계"}
                        {language === "Eng" && "SCAR Stats"}
                    </Button>
                    <Button active={buttonStatus === 'CHECK_LIST'} onClick={() => setButtonStatus('CHECK_LIST')}>
                        {language === "Kor" && "CHECK LIST 통계"}
                        {language === "Eng" && "Check List Stats"}
                    </Button>
                </ButtonBox>
                {buttonStatus === 'SCAR' && (
                    statsLoading
                        ? <Loader height='100vh'/>
                        : (
                            <>
                                {/* SCAR 기간 내 총 발행 */}
                                <MediumText margin="16px 0">
                                    {language === "Kor" && "SCAR 기간 내 총 발행"}
                                    {language === "Eng" && "SCAR Status"}
                                </MediumText>
                                <TotalTableRow BACKGROUND>
                                    <TotalTableCell width={38} textAlign="left">
                                        {language === "Kor" && "총 건수"}
                                        {language === "Eng" && "Issued"}
                                    </TotalTableCell>
                                    <TotalTableCell width={38} textAlign="left">
                                        {language === "Kor" && "처리누계"}
                                        {language === "Eng" && "Closed"}
                                    </TotalTableCell>
                                    <TotalTableCell width={24} textAlign="left">
                                        {language === "Kor" && "미처리"}
                                        {language === "Eng" && "Open"}
                                    </TotalTableCell>
                                </TotalTableRow>
                                <TotalTableRow>
                                    <TotalTableCell width={38} textAlign="left">
                                        {count?.total} {language === "Kor" && "건"}{" "}
                                        {language === "Eng" && "count"}
                                    </TotalTableCell>
                                    <TotalTableCell width={38} textAlign="left">
                                        {count?.complete} {language === "Kor" && "건"}{" "}
                                        {language === "Eng" && "count"}
                                    </TotalTableCell>
                                    <TotalTableCell width={24} textAlign="left">
                                        {count?.unprocessed} {language === "Kor" && "건"}{" "}
                                        {language === "Eng" && "count"}
                                    </TotalTableCell>
                                </TotalTableRow>

                                {/* SCAR 처리 상태별 */}
                                <MediumText margin="26px 0 12px">
                                    {language === "Kor" && "SCAR 처리 상태별"}
                                    {language === "Eng" && "SCAR Processing Status"}
                                </MediumText>
                                <CircleChart
                                    status={status} // 차트 데이터
                                />

                                {/* SCAR 라벨별 */}
                                <MediumText margin="26px 0 12px">
                                    {language === "Kor" && "SCAR 라벨별"}
                                    {language === "Eng" && "SCAR Status by labels"}
                                </MediumText>
                                <TotalTableRow BACKGROUND>
                                    <TotalTableCell width={23} textAlign="left">
                                        {language === "Kor" && "분류"}
                                        {language === "Eng" && "Label"}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>
                                        {language === "Kor" && "발행"}
                                        {language === "Eng" && "Issued"}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>
                                        {language === "Kor" && "완료"}
                                        {language === "Eng" && "Closed"}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>
                                        {language === "Kor" && "미완료"}
                                        {language === "Eng" && "Open"}
                                    </TotalTableCell>
                                    <TotalTableCell width={23}>%</TotalTableCell>
                                </TotalTableRow>

                                {labels?.map((label, index) => (  // 라벨별 데이터 목록
                                    <StatisticsTableRow key={index} label={label} language={language}/>
                                ))}

                                {/* 합계 */}
                                <TotalTableRow TOTAL>
                                    <TotalTableCell width={23} textAlign="left">
                                        {language === "Kor" && "합계"}
                                        {language === "Eng" && "Total"}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>{createTotal}</TotalTableCell>
                                    <TotalTableCell width={18}>{completeTotal}</TotalTableCell>
                                    <TotalTableCell width={18}>{imcompleteTotal}</TotalTableCell>
                                    <TotalTableCell width={23}/>
                                </TotalTableRow>
                            </>
                        ))}

                {buttonStatus === 'CHECK_LIST' && (
                    checkListLoading
                        ? <Loader height='100vh'/>
                        : (
                            <>
                                <MediumText margin="26px 0 12px">
                                    {language === "Kor" && "체크리스트 비율 분포"}
                                    {language === "Eng" && "Weekly Checklist Summary"}
                                </MediumText>
                                <TotalTableRow BACKGROUND>
                                    <TotalTableCell width={26} textAlign="left">
                                        {language === "Kor" && "총준수율"}
                                        {language === "Eng" && "Rate"}
                                    </TotalTableCell>
                                    <TotalTableCell width={26} textAlign="left">
                                        {language === "Kor" && "평균 총점"}
                                        {language === "Eng" && "total"}
                                    </TotalTableCell>
                                    <TotalTableCell width={26} textAlign="left">
                                        {language === "Kor" && "총 평균 점수"}
                                        {language === "Eng" && "Actual"}
                                    </TotalTableCell>
                                    <TotalTableCell width={24} textAlign="left">
                                        {language === "Kor" && "반복 평균"}
                                        {language === "Eng" && "Repeat"}
                                    </TotalTableCell>
                                </TotalTableRow>
                                <TotalTableRow>
                                    <TotalTableCell width={26} textAlign="left">
                                        {checkListStatsData?.totalEvaluationInfo?.totalComplyRate} %
                                    </TotalTableCell>
                                    <TotalTableCell width={26} textAlign="left">
                                        {checkListStatsData?.totalEvaluationInfo?.avgTotalScore}
                                    </TotalTableCell>
                                    <TotalTableCell width={26} textAlign="left">
                                        {checkListStatsData?.totalEvaluationInfo?.avgEvaluationScore}
                                    </TotalTableCell>
                                    <TotalTableCell width={24} textAlign="left">
                                        {checkListStatsData?.totalEvaluationInfo?.avgRepeatViolation}
                                    </TotalTableCell>
                                </TotalTableRow>

                                {/* SCAR 처리 상태별 */}
                                <MediumText margin="26px 0 12px">
                                    {language === "Kor" && "체크리스트 비율 분포"}
                                    {language === "Eng" && "Checklist Ratio Chart"}
                                </MediumText>
                                <CircleChart
                                    CHECK_LIST
                                    status={checkListStatsData?.checkListSocreRate} // 차트 데이터
                                />

                                <MediumText margin="26px 0 12px">
                                    {language === "Kor" && "체크리스트 월 준수율 통계 (최근)"}
                                    {language === "Eng" && "Checklist Monthly Compliance Statistics (Recent)"}
                                </MediumText>
                                <StickChart
                                    data={checkListStatsData?.byMonthStatistics}
                                />

                                <MediumText margin="26px 0 12px">
                                    {language === "Kor" && "체크리스트 범주별 평균 통계"}
                                    {language === "Eng" && "Average statistics by checklist category"}
                                </MediumText>
                                <FlexBox margin='0 0 16px'>
                                    <TableInfoBox flexDirection='column'>
                                        <GrayText>
                                            MA:&nbsp;
                                            {language === 'Kor' && '평가 총점 평균'}
                                            {language === 'Eng' && 'Max Count AV.'}
                                        </GrayText>
                                        <GrayText>
                                            PE:&nbsp;
                                            {language === 'Kor' && '평균 준수율 %'}
                                            {language === 'Eng' && 'Percent AV.'}
                                        </GrayText>
                                        <GrayText>
                                            SC:&nbsp;
                                            {language === 'Kor' && '스카 발생수 평균'}
                                            {language === 'Eng' && 'SCAR Count AV.'}
                                        </GrayText>
                                    </TableInfoBox>
                                    <TableInfoBox flexDirection='column'>
                                        <GrayText>
                                            CO:&nbsp;
                                            {language === 'Kor' && '주간 점수 평균'}
                                            {language === 'Eng' && 'Total Count AV.'}
                                        </GrayText>
                                        <GrayText>
                                            RE:&nbsp;
                                            {language === 'Kor' && '반복 위반 평균'}
                                            {language === 'Eng' && 'Repeat Findings AV.'}
                                        </GrayText>
                                    </TableInfoBox>
                                </FlexBox>

                                <TotalTableRow BACKGROUND>
                                    <TotalTableCell width={28} textAlign="left">
                                        {language === "Kor" && "ITEM"}
                                        {language === "Eng" && "ITEM"}
                                    </TotalTableCell>
                                    <TotalTableCell width={20}>
                                        {language === "Kor" && "MA./CO."}
                                        {language === "Eng" && "MA./CO."}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>
                                        {language === "Kor" && "PE."}
                                        {language === "Eng" && "PE."}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>
                                        {language === "Kor" && "RE."}
                                        {language === "Eng" && "RE."}
                                    </TotalTableCell>
                                    <TotalTableCell width={18}>
                                        {language === "Kor" && "SC."}
                                        {language === "Eng" && "SC."}
                                    </TotalTableCell>
                                </TotalTableRow>
                                {checkListStatsData?.bySafetyCategoryStatistics?.categoryStatisticsList?.map((label, index) => ( // 라벨별 데이터 목록
                                        <StatisticsTableRow CHECK_LIST key={index} label={label} language={language}/>
                                    )
                                )}
                                <TotalTableRow TOTAL>
                                    <TotalTableCell width={28} textAlign="left">
                                        {language === "Kor" && "합계"}
                                        {language === "Eng" && "Total"}
                                    </TotalTableCell>
                                    <TotalTableCell width={20}>
                                        {checkListStatsData?.bySafetyCategoryStatistics?.totalMA} / {checkListStatsData?.bySafetyCategoryStatistics?.totalCO}
                                    </TotalTableCell>
                                    <TotalTableCell
                                        width={18}>{checkListStatsData?.bySafetyCategoryStatistics?.totalPE}%</TotalTableCell>
                                    <TotalTableCell
                                        width={18}>{checkListStatsData?.bySafetyCategoryStatistics?.totalRE}</TotalTableCell>
                                    <TotalTableCell
                                        width={18}>{checkListStatsData?.bySafetyCategoryStatistics?.totalSC}</TotalTableCell>
                                </TotalTableRow>
                            </>
                        ))}
            </Contents>
        </Wrapper>
    );
};

export default React.memo(ProjectStatisticsPresenter);
