import React, {useCallback, useContext, useEffect, useState} from "react";
import ProjectStatisticsPresenter from "./ProjectStatisticsPresenter";
import {ThemeContext} from "../../../context/ThemeProvider";
import {useQuery, useMutation} from "@apollo/client";
import {SEE_CHECK_LIST_STATISTICS, SEE_SCAR_TOTAL_COUNT} from "../../../graphql/Project/ProjectStatistics/query";
import {SCAR_STATISTICS_EXCEL, CHECK_LIST_STATISTICS_EXCEL} from "../../../graphql/Project/ProjectStatistics/mutation";

const ProjectStatisticsContainer = () => {
    const {language} = useContext(ThemeContext);
    const prjId = Number(localStorage.getItem("prjId"));
    const now = new Date();

    const searchStartDate = new Date(2019, 0, 1);
    const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);

    const [period, setPeriod] = useState([searchStartDate, searchEndDate]);
    const [count, setCount] = useState(null);
    const [labels, setLabels] = useState([]);
    const [status, setStatus] = useState(null);
    const [createTotal, setCreateTotal] = useState(0);
    const [completeTotal, setCompleteTotal] = useState(0);
    const [imcompleteTotal, setImcompleteTotal] = useState(0);
    const [buttonStatus, setButtonStatus] = useState('SCAR');

    const [scarStatisticsExcel] = useMutation(SCAR_STATISTICS_EXCEL);
    const [checkListStatisticsExcel] = useMutation(CHECK_LIST_STATISTICS_EXCEL);

    const {data: statsData, loading: statsLoading, refetch: statsRefetch} = useQuery(SEE_SCAR_TOTAL_COUNT, {
        variables: {
            prj_id: prjId,
            searchStart: period[0],
            searchEnd: period[1],
        },
        fetchPolicy: 'network-only'
    });
    const {data: checkListStatsData, loading: checkListLoading} = useQuery(SEE_CHECK_LIST_STATISTICS, {
        variables: {
            prj_id: prjId,
            searchStart: period[0],
            searchEnd: period[1],
        },
        fetchPolicy: 'network-only'
    });

    const onChangePeriod = useCallback((name, value) => setPeriod(value), []); // 기간 설정 onChange

    // 엑셀 다운로드
    const handleExcelDownload = useCallback(async () => {
        try {
            switch (buttonStatus) {
                case 'SCAR':
                    const {data: scarData} = await scarStatisticsExcel({
                        variables: {
                            prj_id: prjId,
                            searchStart: period[0],
                            searchEnd: period[1],
                        },
                    });

                    if (scarData?.scarStatisticsExcel?.result) {
                        alert((language === 'Kor' && '액셀 파일을 다운받았습니다.') || (language === 'Eng' && 'Downloaded successfully'));
                        window.open(scarData.scarStatisticsExcel.excel);
                    }
                    if (scarData?.scarStatisticsExcel?.error !== "") {
                        alert(scarData?.scarStatisticsExcel?.error);
                    }
                    break;
                case 'CHECK_LIST':
                    const {data: checkListData} = await checkListStatisticsExcel({
                        variables: {
                            prj_id: prjId,
                            searchStart: period[0],
                            searchEnd: period[1],
                        },
                    });

                    if (checkListData?.checkListStatisticsExcel?.result) {
                        alert((language === 'Kor' && '액셀 파일을 다운받았습니다.') || (language === 'Eng' && 'Downloaded successfully'));
                        window.open(checkListData.checkListStatisticsExcel.excel);
                    }
                    if (checkListData?.checkListStatisticsExcel?.error !== "") {
                        alert(checkListData?.checkListStatisticsExcel?.error);
                    }
                    break;
            }
        } catch (e) {
            alert(e);
        }
    }, [period, prjId, buttonStatus]);

    useEffect(() => {
        if (statsData?.seeScarTotalCount?.result) {
            setCount(statsData.seeScarTotalCount.byScarCount);
            setLabels(statsData.seeScarTotalCount.byLabelScarCount);
            setStatus(statsData.seeScarTotalCount.byStatus);
        }
    }, [statsData]);

    useEffect(() => {
        if (labels) {
            setCreateTotal(0);
            setCompleteTotal(0);
            setImcompleteTotal(0);
            labels.forEach((label) => {
                setCreateTotal((createTotal) => createTotal + label.createCount);
                setCompleteTotal(
                    (completeTotal) => completeTotal + label.completeCount
                );
                setImcompleteTotal(
                    (imcompleteTotal) => imcompleteTotal + label.imcompleteCount
                );
            });
        }
    }, [labels]);

    useEffect(() => {
        window.scrollTo({top: 0});
    }, []);

    return (
        <ProjectStatisticsPresenter
            language={language}
            period={period}
            onChangePeriod={onChangePeriod}
            handleExcelDownload={handleExcelDownload}
            count={count}
            labels={labels}
            status={status}
            createTotal={createTotal}
            completeTotal={completeTotal}
            imcompleteTotal={imcompleteTotal}

            buttonStatus={buttonStatus}
            setButtonStatus={setButtonStatus}

            checkListStatsData={checkListStatsData?.seeCheckListStatistics}
            statsLoading={statsLoading}
            checkListLoading={checkListLoading}
        />
    );
};

export default ProjectStatisticsContainer;
