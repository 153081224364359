import React, { useContext } from "react";
import styled, { css } from "styled-components";
import ModalClose from "../../share/ModalClose";
import { HugeText } from "../../share/StyledText";
import { ThemeContext } from "../../../context/ThemeProvider";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
  top: 0;
  right: -100%;
  z-index: -1;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.3s;

  ${({ isModalOpen }) =>
    isModalOpen &&
    css`
      z-index: 10;
      top: 0;
      right: 0;
    `};
`;

const Content = styled.div`
  word-wrap: break-word;
  line-height: 140%;
`;

const ServiceModal = ({ tos = [], isModalOpen, setIsOpenModal }) => {
  const { language } = useContext(ThemeContext);

  return (
    <Wrapper isModalOpen={isModalOpen}>
      <ModalClose setModalClose={setIsOpenModal} />

      <HugeText margin="16px 0 26px">
        {(language === "Kor" && "서비스이용약관") ||
          (language === "Eng" && "Terms and Conditions of Service")}
      </HugeText>

      {tos.length ? (
        <Content
          dangerouslySetInnerHTML={{ __html: tos[0].tos_text }}
        ></Content>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(ServiceModal);
