import React from "react";
import styled, {css} from "styled-components";
import {MediumText} from "./StyledText";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ToggleBox = styled.div`
  display: flex;
  align-items: center;
`;
const CheckBox = styled.input``;
const Label = styled.label`
  width: 34px;
  height: 20px;
  display: block;
  position: relative;
  border-radius: 30px;
  transition: all .15s ease-in;
  background-color: ${({theme, checked}) => checked ? theme.activeColor : theme.toggleSwitchColor};
`;
const ToggleButton = styled.span`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 1px;
  transform: translateY(-50%);
  transition: all .15s ease-in;
  border-radius: 50%;
  background: ${({theme}) => theme.whiteColor};

  ${({checked}) => checked && css`
    left: calc(100% - 19px);
  `};
`;

const ToggleSwitch = ({
                          children,
                          name = "",
                          value = false,
                          onChange = () => null,
                      }) => {
    return (
        <Wrapper>
            <MediumText>{children}</MediumText>
            <ToggleBox>
                <CheckBox id={name} name={name} type="checkbox" checked={value} onChange={onChange} hidden/>
                <Label htmlFor={name} checked={value}>
                    <ToggleButton checked={value}/>
                </Label>
            </ToggleBox>
        </Wrapper>
    )
}

export default React.memo(ToggleSwitch);