import React, {useContext, useState} from "react";
import styled, {css} from "styled-components";
import ProfileImage from "../../share/ProfileImage";
import {LargeText, MediumText} from "../../share/StyledText";
import arrowTopIcon from "../../../assets/icons/project/arrowTopIcon.svg";
import {ThemeContext} from "../../../context/ThemeProvider";
import userImage from "../../../assets/images/user_1.png";
import nonMember from "../../../assets/icons/project/nonMember.svg";
import {ProjectDetailContext} from "../../../pages/Project/ProjectDetail/ProjectDetailContainer";
import {engGroupChanger, groupChanger} from "../../../lib/ProfileUtils";

const Wrapper = styled.div`
  margin: 20px 0;
`;
const Image = styled.img`
  margin: ${({margin}) => (margin ? margin : 0)};
  transition: 0.4s;

  ${({rotate}) => rotate && css`
    transform: rotate(${rotate});
  `};
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : "row"};
  align-items: ${({alignItems}) => (alignItems ? alignItems : "flex-start")};
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : "flex-start"};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const MemberList = styled.div`
  height: 148px;
  display: flex;
  margin-top: 20px;
  padding-bottom: 26px;
  transition: 0.25s;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  overflow-x: scroll;

  ${({fold}) => fold && css`
    height: 0;
    padding: 0;
    border: none;
    overflow: hidden;
    opacity: 0;
  `};
`;
const MemberBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;
const GrayText = styled(MediumText)`
  color: ${({theme}) => theme.subTextColor};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const UserName = styled(LargeText)`
  max-width: 154px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SwiperMemberList = () => {
    const {language} = useContext(ThemeContext);
    const {setMemberModalInfo, setUserProfileModal, memberList} = useContext(ProjectDetailContext);

    const [fold, setFold] = useState(true);

    return (
        <Wrapper>
            <FlexBox alignItems="center" justifyContent="space-between">
                <LargeText>
                    {language === "Kor" && `프로젝트 구성원 (${memberList.length}명)`}
                    {language === "Eng" && `Project Members (${memberList.length})`}
                </LargeText>
                <FlexBox alignItems="center" onClick={() => setFold(!fold)}>
                    <GrayText>
                        {fold ? (
                            <>
                                {language === "Kor" && "열기"}
                                {language === "Eng" && "Open"}
                            </>
                        ) : (
                            <>
                                {language === "Kor" && "접기"}
                                {language === "Eng" && "Close"}
                            </>
                        )}
                    </GrayText>
                    <Image
                        margin="2px 0 0 4px"
                        rotate={fold ? "180deg" : 0}
                        src={arrowTopIcon}
                    />
                </FlexBox>
            </FlexBox>
            <MemberList fold={fold}>
                {memberList.map((member, index) => (
                    <MemberBox
                        key={index}
                        onClick={() => {
                            setUserProfileModal(true);
                            setMemberModalInfo(member);
                        }}
                    >
                        <ProfileImage
                            BORDER
                            width={68}
                            height={68}
                            src={
                                member?.user_profileImg === ""
                                    ? nonMember
                                    : member?.user_profileImg
                            }
                        />
                        <GrayText margin="10px 0 2px">
                            {language === "Kor" && groupChanger(member?.user_changedPermission)}
                            {language === "Eng" && engGroupChanger(member?.user_changedPermission)}
                        </GrayText>
                        <UserName>{member?.user_name}</UserName>
                    </MemberBox>
                ))}
            </MemberList>
        </Wrapper>
    );
};

export default React.memo(SwiperMemberList);
