import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import emptyImg from "../../../assets/icons/share/emptyImg.svg";
import { groupChanger } from "../../../lib/ProfileUtils";
import { lightTheme } from "../../../styles/theme";
import {ThemeContext} from "../../../context/ThemeProvider";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  width: 474px;
  height: 661px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => `${theme.whiteColor}`};
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${({ theme }) => `${theme.subTextColor}`};
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  display: flex;
  align-items: center;
`;

const ProfileRowBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  & > ${Text} + ${Text} {
    margin: 0 5px;
  }
`;

const ProfileImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyImg})` : `url(${src})`};
  background-size: ${({ src }) => (src === "" ? "40%" : "cover")};
  background-repeat: no-repeat;
  border: ${({ src, theme }) => src === "" && `1px solid ${theme.borderColor}`};
  background-position: 50%;
  margin-right: 10px;
  border: ${({ theme }) => `0.5px solid ${theme.borderColor}`};
`;

const CirculatorsScroll = styled.div`
  height: 80px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => `${theme.borderColor}`};
  }
`;

const MemberModal = ({ data, handleModal }) => {
  const { language } = useContext(ThemeContext);
  useEffect(() => {
    document.body.style.overfolow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text BOLD>결재라인</Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />

        <Text margin="40px 0 20px 20px" color={lightTheme.subTextColor}>
          발행자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.create_img} />
          <Text>{groupChanger(data?.create_group, language)}</Text>
          <Text BOLD>{data?.create_name}</Text>
          <Text BOLD>{data?.create_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={lightTheme.subTextColor}>
          발행확인자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.check_img} />
          <Text>{groupChanger(data?.check_group, language)}</Text>
          <Text BOLD>{data?.check_name}</Text>
          <Text BOLD>{data?.check_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={lightTheme.subTextColor}>
          조치자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.action_img} />
          <Text>{groupChanger(data?.action_group, language)}</Text>
          <Text BOLD>{data?.action_name}</Text>
          <Text BOLD>{data?.action_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={lightTheme.subTextColor}>
          책임자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.manager_img} />
          <Text>{groupChanger(data?.manager_group, language)}</Text>
          <Text BOLD>{data?.manager_name}</Text>
          <Text BOLD>{data?.manager_rank}</Text>
        </ProfileRowBox>

        <Text margin="20px 0 20px  20px" color={lightTheme.subTextColor}>
          조치확인자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data?.actionCheck_img} />
          <Text>{groupChanger(data?.actionCheck_group, language)}</Text>
          <Text BOLD>{data?.actionCheck_name}</Text>
          <Text BOLD>{data?.actionCheck_rank}</Text>
        </ProfileRowBox>

        <Text margin="10px 0 10px 20px" color={lightTheme.subTextColor}>
          최종승인자
        </Text>
        <ProfileRowBox>
          <ProfileImage src={data.finalSign_img} />
          <Text>{groupChanger(data?.finalSign_group, language)}</Text>
          <Text BOLD>{data?.finalSign_name}</Text>
          <Text BOLD>{data?.finalSign_rank}</Text>
        </ProfileRowBox>

        <Text margin="25px 0 10px 20px" color={lightTheme.subTextColor}>
          회람자
        </Text>
        {data?.circulators?.length === 0 && (
          <Text margin="0 0 10px 20px">-</Text>
        )}
        <CirculatorsScroll>
          {data?.circulators?.map((circulator, index) => (
            <Text key={index} margin="0 0 10px 20px">
              {circulator?.circ_email + circulator?.circ_cellphone}{" "}
            </Text>
          ))}
        </CirculatorsScroll>
      </ModalContainer>
    </Container>
  );
};

export default MemberModal;
