import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {HugeText} from "../../../components/share/StyledText";
import StyledInput from "../../../components/share/StyledInput";
import ProjectInfo from "../../../components/feature/Project/ProjectInfo";

const Wrapper = styled.div`
  //height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '196px 20px 100px' : '176px 20px 100px'};
`;
const Header = styled.div`
  width: 100%;
  position: fixed;
  padding: ${({ theme }) => theme.isApp ? '40px 20px 20px' : '20px'};
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({theme}) => theme.bgColor};
`;

const ProjectSearchPresenter = ({
                                    language = "Kor",
                                    searchTerm,
                                    setSearchTerm,
                                    searchPrjList,
                                }) => {
    return (
        <Wrapper>
            <Header>
                <GoBack/>
                <HugeText>
                    {language === "Kor" && "프로젝트 검색하기"}
                    {language === "Eng" && "Project Search"}
                </HugeText>
                <StyledInput
                    margin="20px 0 0"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={
                        (language === "Kor" && "검색할 프로젝트명을 입력해주세요.") ||
                        (language === "Eng" && "Enter the name of the project to search for")
                    }
                />
            </Header>
            {searchPrjList.map((prj, index) => (
                <ProjectInfo key={index + 1} prjData={prj}/>
            ))}
        </Wrapper>
    );
};

export default React.memo(ProjectSearchPresenter);
