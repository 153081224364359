import React, {useContext} from "react";
import styled from "styled-components";
import {CheckListDetailContext} from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";
import CheckerProfile from "./CheckerProfile";
import NumericalInfo from "./NumericalInfo";
import EvaluatorItem from "./EvaluatorItem";
import PreviewImageBox from "./PreviewImageBox";
import FullViewModal from "../FullViewModal";
import {LargeText, MediumText} from "../../../share/StyledText";
import {ThemeContext} from "../../../../context/ThemeProvider";

const Wrapper = styled.div`
  margin-top: 12px;
`;
const Spacer = styled.div`
  height: 6px;
`;
const ComprehensiveReviewText = styled(MediumText)`
  // color: ${({ theme }) => theme.darkGrayTextColor};
  line-height: 1.4;
  margin: 20px 0;
`;

const Viewer = () => {
    const { language } = useContext(ThemeContext);
    const { comment, checkListDetail, weeklySafetyCategory } = useContext(CheckListDetailContext);

    return (
        <Wrapper>
            <CheckerProfile // 평가자 프로필
                checkListDetail={checkListDetail}
            />
            <NumericalInfo // 평가율 데이터
                checkListDetail={checkListDetail}
            />
            <Spacer/>
            {weeklySafetyCategory.map(item => (
                <EvaluatorItem
                    key={item.wsc_id}
                    item={item}
                    checkListDetail={checkListDetail}
                />
            ))}
            <LargeText margin='30px 0 20px'>
                {(language === 'Kor' && '평가 사진 및 의견') || (language === 'Eng' && 'Pictures and Comments')}
            </LargeText>
            <ComprehensiveReviewText>
                {comment === '' ? '-' : comment}
            </ComprehensiveReviewText>
            <PreviewImageBox />
        </Wrapper>
    )
}

export default React.memo(Viewer);