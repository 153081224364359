import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {darkTheme, lightTheme} from "../../../styles/theme";
import StyledInput from "../../../components/share/StyledInput";
import StyledButton from "../../../components/share/StyledButton";
import {HugeText, MediumText, SmallText} from "../../../components/share/StyledText";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
`;
const Contents = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 40px;
`;

const AuthNumCheckPresenter = ({
                                   themeMode,
                                   language,
                                   authNumber,
                                   onChange,
                                   phoneNumber,
                                   handleAuthNumber,
                                   handleResendAuthNumber
                               }) => {
    return (
        <Wrapper>
            <GoBack/>
            <HugeText>
                {language === 'Kor' && '6자리 인증번호를'}
                {language === 'Eng' && 'Please enter 6-digit code'}
            </HugeText>
            <HugeText margin="10px 0 26px">
                {language === 'Kor' && '입력해주세요.'}
                {language === 'Eng' && 'sent to your phone'}
            </HugeText>
            <SmallText
                fontColor={themeMode === "light" ? lightTheme.subTextColor : darkTheme.subTextColor}
                margin="0 0 10px"
            >
                {language === 'Kor' && `${phoneNumber?.replaceAll("-", " ")} 번호로 6자리 인증번호를`}
                {language === 'Eng' && `6 digit authentication number`}
            </SmallText>
            <SmallText
                fontColor={themeMode === "light" ? lightTheme.subTextColor : darkTheme.subTextColor}
            >
                {language === 'Kor' && '전송하였습니다.'}
                {language === 'Eng' && `has been sent to ${phoneNumber?.replaceAll("-", " ")} number`}
            </SmallText>
            <Contents>
                <StyledInput
                    label={(language === 'Kor' && '6자리 인증번호') || (language === 'Eng' && '6 digit authentication number')}
                    width="100%"
                    type="tel"
                    maxLength={6}
                    value={authNumber}
                    onChange={onChange}
                />
                <StyledButton
                    title={(language === 'Kor' && '인증하기') || (language === 'Eng' && 'Submit')}
                    margin="40px 0 26px"
                    onClick={handleAuthNumber}
                />
                <MediumText onClick={handleResendAuthNumber}>
                    {language === 'Kor' && '인증번호 재전송'}
                    {language === 'Eng' && 'Not received the code? Resend'}
                </MediumText>
            </Contents>
        </Wrapper>
    )
}

export default React.memo(AuthNumCheckPresenter);