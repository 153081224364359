import React from "react";
import styled, {css} from "styled-components";
import goBack from "../../assets/icons/share/goBack.svg";
import download from "../../assets/icons/project/download.svg";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  ${({NO_MARGIN}) => NO_MARGIN && css`
    margin: 0;
  `};
  
  ${({ DOWNLOAD }) => DOWNLOAD && css`
    justify-content: space-between;
  `};
`;
const IconBox = styled.div`
  width: 38px;
  height: 100%;
  display: flex;
  align-items: center;
  ${({ DOWNLOAD }) => DOWNLOAD && css`
    justify-content: center;
  `};
`;
const Icon = styled.img``;

const GoBack = ({
                    NO_MARGIN,
                    DOWNLOAD,
                    onClick,
                    route = -1,
                    onClickDownload = () => null
                }) => {
    const navigate = useNavigate();

    return (
        <Wrapper NO_MARGIN={NO_MARGIN} DOWNLOAD={DOWNLOAD}>
            <IconBox onClick={onClick ? onClick : () => navigate(route)}>
                <Icon src={goBack}/>
            </IconBox>

            {DOWNLOAD && (
                <IconBox DOWNLOAD={DOWNLOAD} onClick={onClickDownload}>
                    <Icon src={download}/>
                </IconBox>
            )}
        </Wrapper>
    );
};

export default GoBack;
