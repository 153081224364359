import { ApolloClient, InMemoryCache , from} from "@apollo/client";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createUploadLink({
  uri: `//${process.env.REACT_APP_SERVER_IP}`,
  headers: {
    "Apollo-Require-Preflight": "true",
    "Content-Type": "application/json",
    Authorization: !localStorage.getItem("riskcatch_user_token")
      ? ""
      : `Bearer ${localStorage.getItem("riskcatch_user_token")}`,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${process.env.REACT_APP_SERVER_IP}`,
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    console.log('--------------')
    console.log('kind : ' + definition.kind)
    console.log('operation : ' + definition.operation)
    console.log('--------------')

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions:{
    watchQuery:{
      fetchPolicy: 'cache-and-network',
      errorPolicy:'ignore'
    },
    query:{
      fetchPolicy:'network-only',
      errorPolicy:'all'
    },
    mutate:{
      errorPolicy:'all'
    }
  }
});

export default client;

const updateHeadersWithToken = (token) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "", // Include token in the authorization header
      },
    };
  });
};

const loginAfterToken = (token) => {
  // Store the token securely (e.g., in local storage)
  localStorage.setItem("riskcatch_user_token", token);

  // Update Apollo Client's headers with the token
  const authLink = updateHeadersWithToken(token);

  // Update the Apollo Client instance with the new headers
  client.setLink(authLink.concat(httpLink));

  // Optionally, you can fetch user data or perform other actions after login
};

export { loginAfterToken };
