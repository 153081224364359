import { gql } from "@apollo/client";

export const REQUEST_AUTH_CODE = gql`
  mutation requestAuthCode(
    $type: String
    $countryCode: String
    $cellphone: String
    $authPage: Boolean
    $email: String
  ) {
    requestAuthCode(
      type: $type
      countryCode: $countryCode
      cellphone: $cellphone
      authPage: $authPage
      email: $email
    ) {
      result
      error
    }
  }
`;

export const RESEND_REQUEST_AUTH_CODE = gql`
  mutation resendRequestAuthCode(
    $type: String
    $countryCode: String
    $cellphone: String
    $email: String
  ) {
    resendRequestAuthCode(
      type: $type
      countryCode: $countryCode
      cellphone: $cellphone
      email: $email
    ) {
      result
    }
  }
`;

export const CONFIRM_AUTH_CODE = gql`
  mutation confirmAuthCode(
    $countryCode: String
    $cellphone: String
    $authcode: String
    $authPage: Boolean
  ) {
    confirmAuthCode(
      countryCode: $countryCode
      cellphone: $cellphone
      authcode: $authcode
      authPage: $authPage
    ) {
      result
      error
      token
    }
  }
`;

export const CONFIRM_AUTH_CODE_MOBILE = gql`
  mutation confirmAuthCodeMobile(
    $countryCode: String!
    $cellphone: String
    $email: String
    $authcode: String!
    $authPage: Boolean!
    $fcmDeviceToken: String
  ) {
    confirmAuthCodeMobile(
      countryCode: $countryCode
      cellphone: $cellphone
      email: $email
      authcode: $authcode
      authPage: $authPage
      fcmDeviceToken: $fcmDeviceToken
    ) {
      result
      error
      token
    }
  }
`;

export const USER_SIGNUP = gql`
  mutation userSignup(
    $user_name: String
    $user_rank: String
    $user_countryCode: String
    $user_cellphone: String
    $user_email: String
    $profileImagSelect: Boolean
    $user_profileImg: Upload
    $user_group: projectPermission
    $user_groupCompany: String
    $groupAuthCode: String
  ) {
    userSignup(
      user_name: $user_name
      user_rank: $user_rank
      user_countryCode: $user_countryCode
      user_cellphone: $user_cellphone
      user_email: $user_email
      profileImagSelect: $profileImagSelect
      user_profileImg: $user_profileImg
      user_group: $user_group
      user_groupCompany: $user_groupCompany
      groupAuthCode: $groupAuthCode
    ) {
      result
      error
    }
  }
`;
