import { gql } from "@apollo/client";

export const SEND_EMAIL_AUTH_CODE = gql`
  mutation sendEmailAuthCode($email: String) {
    sendEmailAuthCode(email: $email) {
      result
      error
    }
  }
`;

export const EDIT_MY_EMAIL = gql`
  mutation editMyEmail($email: String, $authCode: String) {
    editMyEmail(email: $email, authCode: $authCode) {
      result
      error
    }
  }
`;
