import { gql } from "@apollo/client";

export const EDIT_PROJECT = gql`
  mutation editProject(
    $prjTitle: String!
    $prjAddress: String!
    $prjStartDate: String!
    $prjEndDate: String!
    $prjNumber: String!
    $prjOpenAvailable: Boolean
    $prjId: Int
    $prjImgSame: Boolean
    $prjImg: Upload
  ) {
    editProject(
      prj_title: $prjTitle
      prj_address: $prjAddress
      prj_startDate: $prjStartDate
      prj_endDate: $prjEndDate
      prj_number: $prjNumber
      prj_openAvailable: $prjOpenAvailable
      prj_id: $prjId
      prj_img_same: $prjImgSame
      prj_img: $prjImg
    ) {
      result
      error
    }
  }
`;
