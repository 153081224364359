import React, {useContext} from "react";
import styled from "styled-components";
import {MediumText} from "./StyledText";
import FlexBox from "./FlexBox";
import {ThemeContext} from "../../context/ThemeProvider";
import {ProfileContext} from "../../App";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
`;
const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 20px 26px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.whiteColor};
`;
const Text = styled(MediumText)`
  line-height: 1.2;
`;
const ButtonBox = styled(FlexBox)`
  margin: 26px 0 0;
  align-self: flex-end;
`;
const Button = styled.div`
  color: ${({ theme }) => theme.subTextColor};
  align-self: flex-end;
  margin-left: 12px;
`;

const CustomConfirm = ({ message }) => {
    const { language } = useContext(ThemeContext);

    const { setConfirmMessage } = useContext(ProfileContext);

    return (
        <Wrapper>
            <Alert>
                <Text>
                    {message}
                </Text>
                <ButtonBox>
                    <Button onClick={() => setConfirmMessage('')}>
                        {language === 'Kor' && '취소'}
                        {language === 'Eng' && 'Cancel'}
                    </Button>
                    <Button onClick={() => {}}>
                        {language === 'Kor' && '확인'}
                        {language === 'Eng' && 'Ok'}
                    </Button>
                </ButtonBox>
            </Alert>
        </Wrapper>
    )
}

export default React.memo(CustomConfirm);