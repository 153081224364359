import { gql } from "@apollo/client";

export const SEE_TOS = gql`
  query seeTOS {
    seeTOS {
      tokenExpired
      totalLength
      tosList {
        tos_id
        tos_createdAt
        tos_creator
        tos_text
        tos_deleteDate
      }
    }
  }
`;

export const SEE_VOC_NUMBER_USER = gql`
  query seeVocNumberUser {
    seeVocNumberUser {
      result
      error
      vocNumber
    }
  }
`;
