import { gql } from "@apollo/client";

export const EDIT_PROJECT_MEMBER = gql`
  mutation editProjectMember(
    $prjId: Int
    $group: String
    $emailPhone: Boolean
    $countryCode: String
    $term: String
  ) {
    editProjectMember(
      prj_id: $prjId
      group: $group
      emailPhone: $emailPhone
      countryCode: $countryCode
      term: $term
    ) {
      result
      error
      signupCheck
    }
  }
`;

export const DELETE_PROJECT_MEMBER = gql`
  mutation deleteProjectMember(
    $prj_id: Int
    $unsignedUserId: Int
    $user_id: Int
  ) {
    deleteProjectMember(
      prj_id: $prj_id
      unsignedUser_id: $unsignedUserId
      user_id: $user_id
    ) {
      result
      error
    }
  }
`;
