import React, {useContext} from "react";
import styled, { css } from "styled-components";
import chartIcon from "../../../assets/icons/project/chart.svg";
import {engLabelChanger} from "../../../lib/ProjectUtils";
import {ThemeContext} from "../../../context/ThemeProvider";

const TableRow = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const TableCell = styled.div`
  width: ${({width}) => width}%;
  text-align: ${({textAlign}) => (textAlign ? textAlign : "right")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 2px;
  
  &:nth-child(1) {
    padding-left: 20px;
  }

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
  }
  
  ${({ SCAR }) => SCAR && css`
    &:last-child {
      font-family: "AppleSDGothicNeoBold";
      color: ${({theme}) => theme.activeBorderColor};
    }
  `};
`;

const StatisticsTableRow = ({CHECK_LIST, label, language}) => {
    return (
        CHECK_LIST
            ?
            <TableRow>
                <TableCell width={28} textAlign="left">
                    {language === 'Kor' && label?.item_kr}
                    {language === 'Eng' && label?.item_eng}
                </TableCell>
                <TableCell width={20}>{label?.MA} / {label?.CO}</TableCell>
                <TableCell width={18}>{label?.PE}%</TableCell>
                <TableCell width={18}>{label?.RE}</TableCell>
                <TableCell width={18}>{label?.SC}</TableCell>
            </TableRow>
            :
            <TableRow SCAR={!CHECK_LIST}>
                <TableCell width={23} textAlign="left">
                    {language === "Kor" && label?.labelName}
                    {language === "Eng" && engLabelChanger(label?.labelName)}
                </TableCell>
                <TableCell width={18}>{label?.createCount}</TableCell>
                <TableCell width={18}>{label?.completeCount}</TableCell>
                <TableCell width={18}>{label?.imcompleteCount}</TableCell>
                <TableCell width={23}>{label?.ratio} %</TableCell>
            </TableRow>
    );
};

export default React.memo(StatisticsTableRow);
