import React from "react";
import styled from "styled-components";
import serviceCenterImg from "../../../assets/images/serviceCenterImg.png";
import serviceCenterIcon from "../../../assets/icons/serviceCenter/serviceCenterIcon.svg";
import {
    HugeText,
    LargeText,
    MediumText,
    SmallText,
} from "../../../components/share/StyledText";
import callIcon from "../../../assets/icons/serviceCenter/callIcon.svg";
import questionIcon from "../../../assets/icons/serviceCenter/questionIcon.svg";
import chatIcon from "../../../assets/icons/serviceCenter/chatIcon.svg";
import bellIcon from "../../../assets/icons/serviceCenter/bellIcon.svg";
import GoBack from "../../../components/share/GoBack";

const Wrapper = styled.div`
  //height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 50px;
`;
const Header = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: space-between;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(${serviceCenterImg}) 100% 100% no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &:after {
    content: "";
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: url(${serviceCenterIcon}) 100% 100% no-repeat;
  }
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 66px 0 96px;
`;
const ActiveText = styled(MediumText)`
  color: ${({theme}) => theme.activeColor};
  font-family: "AppleSDGothicNeoBold";
`;
const GrayText = styled(LargeText)`
  color: ${({theme}) => theme.subTextColor};
  padding: 0 20px;
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const NaviTabBox = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 20px;
`;
const Icon = styled.img``;
const TextBox = styled.div`
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;

const ServiceCenter = ({language = "Kor", navigate, vocData}) => {
    return (
        <Wrapper>
            <Header>
                <GoBack route='/project-list'/>
            </Header>
            <InfoBox>
                <ActiveText>
                    {language === "Kor" && "리스크제로HG"}
                    {language === "Eng" && "Risk Zero"}
                </ActiveText>
                <HugeText margin="22px 0">
                    {language === "Kor" && "고객센터"}
                    {language === "Eng" && "Service Center"}
                </HugeText>
                <GrayText>
                    {language === "Kor" && "리스크제로HG의 고객센터 운영시간은"}
                    {language === "Eng" && "RiskZero HG customer center"}
                </GrayText>
                <GrayText margin="8px 0">
                    {language === "Kor" && "09:00 ~ 18:00까지이며, 점심시간은"}
                    {language === "Eng" && "operating hours are from 09:00 to 18:00,"}
                </GrayText>
                <GrayText>
                    {language === "Kor" && "12:00 ~ 13:00입니다."}
                    {language === "Eng" && "lunch time is 12:00 to 13:00."}
                </GrayText>
            </InfoBox>

            <NaviTabBox
                onClick={() => document.location.href = `tel:${vocData?.seeVocNumberUser?.vocNumber}`}
            >
                <Icon src={callIcon}/>
                <TextBox>
                    {language === "Kor" && "전화하기"}
                    {language === "Eng" && "Call Service Center"}
                </TextBox>
            </NaviTabBox>

            <NaviTabBox onClick={() => navigate("/faq")}>
                <Icon src={questionIcon}/>
                <TextBox>
                    {language === "Kor" && "자주 묻는 질문 가기"}
                    {language === "Eng" && "Frequently Asked Questions"}
                </TextBox>
            </NaviTabBox>

            <NaviTabBox onClick={() => navigate("/qna")}>
                <Icon src={chatIcon}/>
                <TextBox>
                    {language === "Kor" && "일대일 문의하기"}
                    {language === "Eng" && "Go to one-on-one questions"}
                </TextBox>
            </NaviTabBox>

            <NaviTabBox onClick={() => navigate("/notice")}>
                <Icon src={bellIcon}/>
                <TextBox>
                    {language === "Kor" && "공지사항"}
                    {language === "Eng" && "Notice"}
                </TextBox>
            </NaviTabBox>

            {/*<GrayText margin="98px 0 60px">
                {language === "Kor" && "회원탈퇴하기"}
                {language === "Eng" && "Membership Withdrawal"}
            </GrayText>*/}
        </Wrapper>
    );
};

export default React.memo(ServiceCenter);
