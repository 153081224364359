import React, {useCallback, useContext, useEffect} from "react";
import styled, {css} from "styled-components";
import {CheckListDetailContext} from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";
import {ThemeContext} from "../../../../context/ThemeProvider";
import FlexBox from "../../../share/FlexBox";
import goBack from "../../../../assets/icons/share/goBack.svg";
import {LargeText} from "../../../share/StyledText";
import CheckItem from "./CheckItem";
import StyledButton from "../../../share/StyledButton";
import {useLazyQuery} from "@apollo/client";
import {SEE_CHECK_ITEM_LIST} from "../../../../graphql/Project/CheckList/query";
import Loader from "../../../share/Loader";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  //max-width: 768px;
  max-width: 430px;
  padding-bottom: 40px;
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 5;
  background-color: ${({theme}) => theme.whiteColor};
  transition: all 0.3s;
  overflow-y: scroll;

  ${({weeklyEvaluationModal}) => weeklyEvaluationModal && css`
    z-index: 10;
    top: 0;
    right: calc(100vw - 50%);
    transform: translateX(50%);
  `};
`;
const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  padding: ${({ theme }) => theme.isApp ? '50px 20px 20px' : '30px 20px 20px'};
  background-color: ${({theme}) => theme.whiteColor};
`;
const Title = styled(LargeText)`
  flex: 1;
  text-align: center;
  margin-right: 38px;
`;
const IconBox = styled.div`
  width: 38px;
  height: 100%;
  display: flex;
  align-items: center;
`;
const Icon = styled.img``;
const SubTitle = styled(LargeText)`
  max-width: 80%;
  line-height: 1.2;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ColorText = styled(LargeText)`
  white-space: nowrap;
  color: ${({ theme }) => theme.negativeColor};
  text-align: right;
  margin-left: 4px;
`;
const ButtonBox = styled.div`
  padding: 0 20px; 
`;

const WeeklyEvaluationModal = () => {
    const { language } = useContext(ThemeContext);
    const { weeklyEvaluationModal, setWeeklyEvaluationModal, wscId, refetch } = useContext(CheckListDetailContext);

    const [seeCheckItemList, { data, loading, refetch: checkItemRefetch }] = useLazyQuery(SEE_CHECK_ITEM_LIST);

    const handleSaveCheckItem = useCallback(async () => {
        setWeeklyEvaluationModal(false);
        await checkItemRefetch();
        await refetch();
        alert(
            (language === 'Kor' && '평가를 완료했습니다.') ||
            (language === 'Kor' && 'The evaluation has been completed')
        );
        document.body.style.overflow = 'scroll';
    }, [language, refetch, checkItemRefetch]);

    useEffect(() => {
        if (wscId !== null) {
            (async () => {
                try {
                    await seeCheckItemList({
                        variables: {
                            wscId: wscId
                        },
                        fetchPolicy: 'network-only'
                    });
                } catch (e) {
                    console.info(e.message);
                    alert(e.message);
                }
            })();
        }
    }, [wscId]);

    if (loading) return <Loader height='100vh' />

    return (
        <Wrapper weeklyEvaluationModal={weeklyEvaluationModal}>
            <Header>
                <FlexBox>
                    <IconBox>
                        <Icon src={goBack} onClick={async () => {
                            await refetch();
                            setWeeklyEvaluationModal(false);
                            document.body.style.overflow = 'scroll';
                        }}/>
                    </IconBox>
                    <Title>
                        {language === 'Kor' && '주간평가'}
                        {language === 'Eng' && 'Weekly checklist'}
                    </Title>
                </FlexBox>
                <FlexBox justifyContent='space-between' margin='22px 0 0'>
                    <SubTitle>
                        {language === 'Kor' && data?.seeCheckItemList?.safetyCategoryKr}
                        {language === 'Eng' && data?.seeCheckItemList?.safetyCategoryEng}
                    </SubTitle>
                    <ColorText>
                        {data?.seeCheckItemList?.scComplyRate}%&nbsp;
                        {language === 'Kor' && '준수'}
                        {language === 'Eng' && 'Comply'}
                    </ColorText>
                </FlexBox>
            </Header>

            {data?.seeCheckItemList?.prjWeeklyCheckItemList.map((item, index) => (
                <CheckItem
                    key={item.wci_id}
                    item={item}
                    index={index}
                    refetch={checkItemRefetch}
                />
            ))}

            <ButtonBox>
                <StyledButton
                    title={
                        (language === "Kor" && "평가 완료하기") ||
                        (language === "Eng" && "Complete Comments")
                    }
                    margin='42px 0'
                    onClick={handleSaveCheckItem}
                />
            </ButtonBox>
        </Wrapper>
    )
}

export default React.memo(WeeklyEvaluationModal);
