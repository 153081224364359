import React from "react";
import NoticeDetailPresenter from "./NoticeDetailPresenter";
import { useLocation } from "react-router-dom";

const NoticeDetailContainer = () => {
  const { notice } = useLocation().state;

  return <NoticeDetailPresenter notice={notice} />;
};

export default NoticeDetailContainer;
