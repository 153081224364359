import React, { useCallback, useContext, useEffect, useState } from "react";
import EditMembersPresenter from "./EditMembersPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { autoHyphen, removeHyphen } from "../../../utils/commons";
import { useMutation, useQuery } from "@apollo/client";
import {
  EDIT_PROJECT_MEMBER,
  DELETE_PROJECT_MEMBER,
} from "../../../graphql/Project/EditMembers/mutation";
import { engGroupChanger, groupChanger } from "../../../lib/ProfileUtils";
import { SEE_SCAR_MEMBER } from "../../../graphql/Project/CreateScar/query";
import { SEE_PROJECT_DETAIL } from "../../../graphql/Project/ProjectDetail/query";

const EditMembersContainer = () => {
  const { language } = useContext(ThemeContext);
  const prjId = Number(localStorage.getItem("prjId"));
  const [inputs, setInputs] = useState({
    affiliation:
      (language === "Kor" && "PM현장") || (language === "Eng" && "PM"),
    type:
      (language === "Kor" && "연락처") ||
      (language === "Eng" && "Phone number"),
    email: "",
    phoneNumber: "",
    countryCode: (language === "Kor" && "+82") || (language === "Eng" && "+1"),
  });

  const [editProjectMember] = useMutation(EDIT_PROJECT_MEMBER);
  const [deleteProjectMember] = useMutation(DELETE_PROJECT_MEMBER);
  const { refetch: prjDetailRefetch } = useQuery(SEE_PROJECT_DETAIL, {
    variables: { prjId },
    fetchPolicy: "network-only",
  });

  const {
    data: memberData,
    loading: memberLoading,
    refetch: memberRefetch,
  } = useQuery(SEE_SCAR_MEMBER, {
    variables: {
      prjId: prjId,
    },
  });

  const onChangeInput = useCallback(
    (e) => {
      // Input 상태 변경 함수
      const { name, value } = e.target;

      if (name === "phoneNumber") {
        setInputs({
          ...inputs,
          [name]: value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"),
        });
      } else {
        setInputs({
          ...inputs,
          [name]: value,
        });
      }
    },
    [inputs],
  );

  const handleDelMember = useCallback(async (userId, unsignId) => {
    try {
      const { data } = await deleteProjectMember({
        variables: {
          prj_id: prjId,
          unsignedUserId: unsignId,
          user_id: userId,
        },
      });
      if (data?.deleteProjectMember?.result) {
        await memberRefetch();
        language === "Kor"
          ? alert("구성원을 삭제하였습니다.")
          : alert("Remove Member");
        await prjDetailRefetch();
      }
      if (data?.deleteProjectMember?.error !== "") {
        alert(data?.deleteProjectMember?.error);
      }
    } catch (e) {
      alert(e.message);
    }
  }, []);

  const includesMemberChecker = useCallback(() => {
    // 중복 멤버 체크
    const { phoneNumber, email, type } = inputs;

    return memberData?.seeScarMember?.prj_member.find((member) => {
      if (type === "연락처" || type === "Phone number") {
        return member.user_cellphone === removeHyphen(phoneNumber);
      } else if (type === "이메일" || type === "Email") {
        return member.user_email === email;
      }
    });
  }, [inputs, memberData]);

  const addMember = useCallback(async () => {
    if (inputs.email === "" && inputs.phoneNumber === "") {
      language === "Kor"
        ? alert("휴대폰번호나 이메일을 입력해주세요.")
        : alert("Please enter the phone number or email");

      return;
    }
    try {
      if (includesMemberChecker()) {
        alert(
          (language === "Kor" && "이미 추가하신 구성원입니다.") ||
            (language === "Eng" && "Member you have already added"),
        );
        return;
      }

      const { data } = await editProjectMember({
        variables: {
          prjId: prjId,
          group:
            language === "Kor"
              ? groupChanger(inputs.affiliation)
              : language === "Eng"
              ? engGroupChanger(inputs.affiliation)
              : "",
          emailPhone:
            inputs.type === "연락처" || inputs.type === "Phone number",
          countryCode: inputs.countryCode,
          term:
            inputs.type === "연락처" || inputs.type === "Phone number"
              ? removeHyphen(inputs.phoneNumber)
              : inputs.email,
        },
      });
      if (data?.editProjectMember?.result) {
        await memberRefetch();
        alert(
          (language === "Kor" && "구성원을 추가하였습니다.") ||
            (language === "Eng" && "Added members successfully."),
        );
        await prjDetailRefetch();
        setInputs({
          affiliation:
            (language === "Kor" && "PM현장") || (language === "Eng" && "PM"),
          type: "연락처",
          email: "",
          phoneNumber: "",
          countryCode:
            (language === "Kor" && "+82") || (language === "Eng" && "+1"),
        });
      }
      if (data?.editProjectMember?.error !== "") {
        alert(data?.editProjectMember?.error);
      }
    } catch (e) {
      alert(e.message);
    }
  }, [inputs]);

  return (
    <EditMembersPresenter
      language={language}
      inputs={inputs}
      onChangeInput={onChangeInput}
      addMember={addMember}
      memberData={memberData}
      handleDelMember={handleDelMember}
    />
  );
};

export default EditMembersContainer;
