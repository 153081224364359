import React, {useCallback, useContext, useEffect, useState} from "react";
import EditMyProfilePresenter from "./EditMyProfilePresenter";
import {ThemeContext} from "../../../context/ThemeProvider";
import {useMutation} from "@apollo/client";
import {EDIT_MY_PROFILE} from "../../../graphql/myPage/EditMyProfile/mutation";
import {useNavigate} from "react-router-dom";
import {ProfileContext} from "../../../App";
import {groupChanger} from "../../../lib/ProfileUtils";

const EditMyProfileContainer = () => {
    const {myProfile, profileRefetch} = useContext(ProfileContext);
    const navigate = useNavigate();
    const {language} = useContext(ThemeContext);
    const [inputs, setInputs] = useState({
        name: "",
        position: "",
        affiliation: "",
        AffiliationCompany: "",
    });

    const [editMyProfile] = useMutation(EDIT_MY_PROFILE);

    const onChangeInput = useCallback((e) => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value,
        });
    }, [inputs]);

    const editProfile = useCallback(async () => {
        try {
            const {data} = await editMyProfile({
                variables: {
                    user_name: inputs.name,
                    user_rank: inputs.position,
                    user_groupCompany: inputs.AffiliationCompany,
                },
            });
            if (data?.editMyProfile?.result) {
                await profileRefetch();
                alert((language === 'Kor' && '정보를 변경하였습니다.') || (language === 'Eng' && 'Changed information successfully'));
                navigate("/my-profile");
            }
            if (data?.editMyProfile?.error !== "") {
                alert(data?.editMyProfile?.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [inputs]);

    useEffect(() => {
        setInputs({
            name: myProfile?.user_name,
            position: myProfile?.user_rank,
            affiliation: groupChanger(myProfile?.user_group, language),
            AffiliationCompany: myProfile?.user_groupCompany,
        });
    }, [myProfile]);

    return (
        <EditMyProfilePresenter
            language={language}
            inputs={inputs}
            onChangeInput={onChangeInput}
            editProfile={editProfile}
        />
    );
};

export default EditMyProfileContainer;
