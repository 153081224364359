import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { SmallText } from "./StyledText";
import { ThemeContext } from "../../context/ThemeProvider";
import { darkTheme, lightTheme } from "../../styles/theme";
import plusIcon from "../../assets/icons/project/plusIcon.svg";
import selectRightArrow from "../../assets/icons/share/selectRightArrow.svg";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;
const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  ${({ NO_BORDER }) =>
    NO_BORDER &&
    css`
      border: none;
    `};
`;
const Input = styled.input`
  width: ${({ PLUS_BUTTON }) => (PLUS_BUTTON ? "90%" : "100%")};
  font-size: 18px;
  color: ${({ theme }) => theme.defaultTextColor};
  padding: 0 20px 14px 0;

  &::placeholder {
    font-size: ${({ placeholderFontSize }) => placeholderFontSize}px;
    color: ${({ theme }) => theme.subTextColor};
  }
  &:disabled {
    color: ${({ theme }) => theme.defaultTextColor};
    opacity: 1;
  }

  ${({ RIGHT_ARROW }) =>
    RIGHT_ARROW &&
    css`
      padding-right: 28px;
      background: url(${selectRightArrow}) 100% 25% no-repeat;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.subTextColor};
    `};
`;

const View = styled.div`
  width: ${({ PLUS_BUTTON }) => (PLUS_BUTTON ? "90%" : "100%")};
  font-size: 18px;
  color: ${({ theme }) => theme.defaultTextColor};
  padding: 0 20px 14px 0;

  &::placeholder {
    font-size: ${({ placeholderFontSize }) => placeholderFontSize}px;
    color: ${({ theme }) => theme.subTextColor};
  }
  &:disabled {
    color: ${({ theme }) => theme.defaultTextColor};
    opacity: 1;
  }

  ${({ RIGHT_ARROW }) =>
    RIGHT_ARROW &&
    css`
      padding-right: 28px;
      background: url(${selectRightArrow}) 100% 25% no-repeat;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.subTextColor};
    `};
`;

const PlusButton = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  /* margin-bottom: 8px; */
  position: absolute;
  bottom: 8px;
  right: 0;
  background: url(${plusIcon}) no-repeat 50% 50%;
`;

const StyledInput = ({
  PLUS_BUTTON,
  RIGHT_ARROW,
  NO_BORDER,
  label = "",
  width = "100%",
  margin = 0,
  type = "text",
  maxLength = 80,
  name = "",
  value = "",
  onChange = () => null,
  onClick = () => null,
  onCreate = () => null,
  onBlur = () => null,
  placeholder = "",
  placeholderFontSize = 14,
  disabled,
}) => {
  const { themeMode } = useContext(ThemeContext);

  return (
    <Wrapper width={width} margin={margin} onClick={onClick}>
      {label && (
        <SmallText
          margin="0 0 14px"
          fontColor={
            themeMode === "light"
              ? lightTheme.subTextColor
              : darkTheme.subTextColor
          }
        >
          {label}
        </SmallText>
      )}
      <InputBox NO_BORDER={NO_BORDER}>
        {disabled ? (
          <View PLUS_BUTTON={PLUS_BUTTON} RIGHT_ARROW={RIGHT_ARROW}>
            {placeholder}
          </View>
        ) : (
          <Input
            PLUS_BUTTON={PLUS_BUTTON}
            RIGHT_ARROW={RIGHT_ARROW}
            placeholderFontSize={placeholderFontSize}
            type={type}
            maxLength={maxLength}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {PLUS_BUTTON && <PlusButton onClick={onCreate} />}
      </InputBox>
    </Wrapper>
  );
};

export default React.memo(StyledInput);
