import React, { useContext, useEffect, useState } from "react";
import FaqPresenter from "./FaqPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { SEE_FAQ_LIST_USER } from "../../../graphql/ServiceCenter/Faq/query";
import { useQuery } from "@apollo/client";

const FaqContainer = () => {
  const { language } = useContext(ThemeContext);
  const [faqList, setFaqList] = useState([]);

  const {
    data: faqData,
    loading: faqLoading,
    refetch: faqRefetch,
  } = useQuery(SEE_FAQ_LIST_USER);

  useEffect(() => {
    if (faqData?.seeFAQListUser?.result) {
      setFaqList(faqData.seeFAQListUser.FAQList);
    }
  }, [faqData]);

  return (
    <FaqPresenter language={language} faqData={faqData} faqList={faqList} />
  );
};

export default FaqContainer;
