import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import { HugeText, MediumText } from "../../../components/share/StyledText";
import FaqRow from "../../../components/feature/ServiceCenter/FaqRow";

const Wrapper = styled.div`
  //height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => (theme.isApp ? "0 20px 0" : "0 20px")};
`;
const Header = styled.div`
  width: 100%;
  position: fixed;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px 20px" : "20px")};
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.bgColor};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const GrayText = styled(MediumText)`
  line-height: 1.3;
  color: ${({ theme }) => theme.subTextColor};
  margin: 26px 0 0;
`;
const FaqRowWrap = styled.div``;

const FaqPresenter = ({ language = "Kor", faqData = [], faqList }) => {
  const [marginTop, setMarginTop] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (ref && ref?.current) {
      setMarginTop((ref?.current?.offsetHeight || 0) + 20);
    }
  }, [ref]);

  return (
    <Wrapper>
      <Header ref={ref}>
        <GoBack route="/service-center" />
        <HugeText margin="16px 0 10px">
          {language === "Kor" && "만족하는 답을 찾지못하셨으면"}
          {language === "Eng" && "If you can't find a satisfactory answer,"}
        </HugeText>
        <HugeText>
          {language === "Kor" && "일대일 문의해주세요"}
          {language === "Eng" && "Please ask one-on-one questions"}
        </HugeText>
        <GrayText>
          {language === "Kor" && "자주묻는 질문은 주기적으로\n업데이트 됩니다."}
          {language === "Eng" &&
            "Frequently asked questions are,\nIt will be updated."}
        </GrayText>
      </Header>

      <FaqRowWrap style={{ marginTop }}>
        {faqList?.map((faq) => (
          <FaqRow key={faq.faq_id} faq={faq} />
        ))}
      </FaqRowWrap>
    </Wrapper>
  );
};

export default React.memo(FaqPresenter);
