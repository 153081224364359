import { gql } from "@apollo/client";

export const SEE_MY_SIGN_PROJECT = gql`
  query seeMySignProject {
    seeMySignProject {
      result
      error
      projects {
        prj_id
        prj_creatorId
        prj_creatorInfo
        prj_openAvailable
        prj_title
        prj_address
        prj_number
        prj_img
        prj_startDate
        prj_endDate
        prj_endRequestDate
        prj_endRequestUserId
        prj_endRequestUserInfo
        prj_status
        scarCount
      }
    }
  }
`;

export const SEE_MY_PROJECT = gql`
  query seeMyProject(
    $searchTerm: String
    $status: projectStatus
    $startDate: String
    $endDate: String
    $sort: String
  ) {
    seeMyProject(
      searchTerm: $searchTerm
      status: $status
      startDate: $startDate
      endDate: $endDate
      sort: $sort
    ) {
      result
      error
      projectList {
        prj_id
        prj_creatorId
        prj_creatorInfo
        prj_openAvailable
        prj_title
        prj_address
        prj_number
        prj_img
        prj_startDate
        prj_endDate
        prj_endRequestDate
        prj_endRequestUserId
        prj_endRequestUserInfo
        prj_status
      }
    }
  }
`;

export const SEARCH_MY_PROJECT = gql`
  query searchMyProject($searchTerm: String) {
    searchMyProject(searchTerm: $searchTerm) {
      result
      error
      projectList {
        prj_id
        prj_creatorId
        prj_creatorInfo
        prj_openAvailable
        prj_title
        prj_address
        prj_number
        prj_img
        prj_startDate
        prj_endDate
        prj_endRequestDate
        prj_endRequestUserId
        prj_endRequestUserInfo
        prj_status
      }
    }
  }
`;
