import React, {useCallback, useContext, useState} from "react";
import styled, {css} from "styled-components";
import ModalClose from "../../share/ModalClose";
import {HugeText} from "../../share/StyledText";
import {ThemeContext} from "../../../context/ThemeProvider";
import StyledInput from "../../share/StyledInput";
import StyledButton from "../../share/StyledButton";
import {useMutation} from "@apollo/client";
import {ASK_ONE_ON_ONE} from "../../../graphql/ServiceCenter/Qna/mutation";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  top: 0;
  right: -100%;
  z-index: -1;
  background-color: ${({theme}) => theme.whiteColor};
  transition: all 0.3s;

  ${({qnaModal}) => qnaModal && css`
    z-index: 10;
    top: 0;
    right: 0;
  `};
`;

const QnaModal = ({qnaModal, setQnaModal, qnaRefetch}) => {
    const {language} = useContext(ThemeContext);
    const [inputs, setInputs] = useState("");
    const navigate = useNavigate();

    const [askOneOnOne] = useMutation(ASK_ONE_ON_ONE);

    const handleAsk = useCallback(async () => {
        if (inputs === '') {
            alert(
                (language === "Kor" && '문의를 입력해주세요.') ||
                (language === "Eng" && "Please enter an inquiry")
            );
            return
        }
        try {
            const {data} = await askOneOnOne({
                variables: {
                    one_question: inputs,
                },
            });

            if (data?.askOneOnOne?.result) {
                alert(
                    (language === "Kor" && "문의를 등록하였습니다.") ||
                    (language === "Kor" && "Enquiry registration completed")
                );
                navigate("/service-center");
                await qnaRefetch();
            }
            if (data?.askOneOnOne?.error !== "") {
                alert(data?.askOneOnOne?.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [inputs]);

    return (
        <Wrapper qnaModal={qnaModal}>
            <ModalClose setModalClose={setQnaModal}/>

            <HugeText margin="16px 0 26px">
                {language === "Kor" && "일대일 문의등록하기"}
                {language === "Eng" && "Registering a One-on-one questions"}
            </HugeText>

            <StyledInput
                label={
                    (language === "Kor" && `문의사유 입력 (${inputs.length} / 80)`) ||
                    (language === "Eng" && `questions contents (${inputs.length} / 80)`)
                }
                value={inputs}
                onChange={(e) => e.target.value.substring(0, 1) === ' ' ? null : setInputs(e.target.value)}
            />

            <StyledButton
                margin="40px 0"
                onClick={handleAsk}
                title={
                    (language === "Kor" && "문의 등록하기") ||
                    (language === "Eng" && "Register an inquiry")
                }
            />
        </Wrapper>
    );
};

export default React.memo(QnaModal);
