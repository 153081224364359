import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "./ThemeProvider";
import { loginAfterToken } from "../apollo/Client";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { language } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("riskcatch_user_token"),
  );

  const userLogin = async (token) => {
    try {
      loginAfterToken(token);
      setIsLoggedIn(true);
      navigate("/project-list");
    } catch (e) {
      console.info(e.message);
    }
  };

  const userLogout = async (isConfirm = true) => {
    if (
      isConfirm &&
      !window.confirm(
        (language === "Kor" &&
          "설정된 정보가 일부 사라질 수 있습니다. \n 그래도 로그아웃하시겠어요?") ||
          (language === "Eng" &&
            "Some of the information that is set may disappear. \nDo you still want to log out?"),
      )
    ) {
      return false;
    }

    try {
      localStorage.removeItem("riskcatch_user_token");
      setIsLoggedIn(false);
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
      console.info(e.message);
    } finally {
      localStorage.clear();
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userLogin, userLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
