import React, { useCallback, useContext, useEffect, useState } from "react";
import TermsPresenter from "./TermsPresenter";
import { ProfileContext } from "../../../App";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useQuery } from "@apollo/client";
import { SEE_TOS } from "../../../graphql/ServiceCenter/ServiceCenter/query";

const TermContainer = () => {
  const { language } = useContext(ThemeContext);
  const { myProfile } = useContext(ProfileContext);
  const [isOpenPolicyModal, setIsOpenPolicyModal] = useState(false);
  const [isOpenServiceModal, setIsOpenServiceModal] = useState(false);

  const lookUpTerm = useCallback((url) => {
    if (window.ReactNativeWebView) {
      const data = {
        type: "WINDOW_OPEN",
        payload: url,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      window.open(url, "_blank");
    }
  }, []);

  const { data: tos } = useQuery(SEE_TOS);

  return (
    <TermsPresenter
      lookUpTerm={lookUpTerm}
      myProfile={myProfile}
      language={language}
      tos={tos}
      isOpenPolicyModal={isOpenPolicyModal}
      setIsOpenPolicyModal={setIsOpenPolicyModal}
      isOpenServiceModal={isOpenServiceModal}
      setIsOpenServiceModal={setIsOpenServiceModal}
    />
  );
};

export default TermContainer;
