import { gql } from "@apollo/client";

export const EDIT_MY_NOTIFICATION_SETTING = gql`
  mutation editMyNotificationSetting(
    $user_pushNoti: Boolean
    $user_msgNoti: Boolean
    $user_emailNoti: Boolean
    $user_kakaoNoti: Boolean
  ) {
    editMyNotificationSetting(
      user_pushNoti: $user_pushNoti
      user_msgNoti: $user_msgNoti
      user_emailNoti: $user_emailNoti
      user_kakaoNoti: $user_kakaoNoti
    ) {
      result
      error
    }
  }
`;
