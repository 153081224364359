import { gql } from "@apollo/client";

export const SEE_MY_ONE_LIST = gql`
  query seeMyOneList {
    seeMyOneList {
      result
      error
      myOneOnOneInfo {
        one_id
        one_createdAt
        one_question
        one_answerText
        one_answer
      }
    }
  }
`;
