import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import CheckListDetailPresenter from "./CheckListDetailPresenter";
import {ThemeContext} from "../../../context/ThemeProvider";
import {getExtension} from "../../../utils/commons";
import {ALLOW_FILE_EXTENSION} from "../../../utils/constants";
import {SEE_CHECK_LIST_DETAIL} from "../../../graphql/Project/CheckList/query";
import {
    DELETE_CHECK_LIST_IMG,
    EDIT_CHECK_LIST_EVALUATION,
    UPDATE_CHECK_LIST_EVALUATION
} from "../../../graphql/Project/CheckList/mutation";
import {useNavigate} from "react-router-dom";

export const CheckListDetailContext = createContext(null);

const CheckListDetailContainer = () => {
    const {language} = useContext(ThemeContext);
    const navigate = useNavigate();
    const [commentModal, setCommentModal] = useState(false);
    const [comment, setComment] = useState('');
    const [imgFile, setImgFile] = useState([]);
    const [evaluatorDetailModal, setEvaluatorDetailModal] = useState(false);
    const [weeklyEvaluationModal, setWeeklyEvaluationModal] = useState(false);
    const [wscId, setWscId] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [fullView, setFullView] = useState(false);

    const wclId = parseInt(localStorage.getItem('wclId'), 10);
    const evaluationPermission = parseInt(localStorage.getItem('evaluationPermission'), 10);

    // Mutation
    const [updateCheckListEvaluation] = useMutation(UPDATE_CHECK_LIST_EVALUATION);
    const [editCheckListEvaluation] = useMutation(EDIT_CHECK_LIST_EVALUATION);
    const [deleteCheckListImg] = useMutation(DELETE_CHECK_LIST_IMG);

    // Query
    const {data, loading, refetch} = useQuery(SEE_CHECK_LIST_DETAIL, {
        variables: {
            wclId: wclId
        },
        fetchPolicy: 'network-only'
    });

    // 종합 검토 의견 입력하기
    const handleEnterComments = useCallback(async () => {
        if (comment === '') {
            alert(
                (language === "Kor" && "의견을 입력해주세요.") ||
                (language === "Eng" && "Please enter your comments")
            );
            return;
        }
        try {
            const {data} = await editCheckListEvaluation({
                variables: {
                    wclEvaluation: comment,
                    wclId: wclId
                }
            });

            if (data?.editCheckListEvaluation) {
                await refetch();
                setCommentModal(false);
                alert(
                    (language === "Kor" && "의견을 입력했습니다.") ||
                    (language === "Eng" && "You've entered your comments")
                );
            }
        } catch (e) {
            alert(e.message);
            console.info(e.message);
        }
    }, [comment]);

    const onChangeImage = useCallback(async e => { // 이미지 등록 함수
        let files = e.target.files;

        if (files.length < 1) return;

        if ((data?.seeCheckListDetail?.wcl_img.length + files.length) > 5) {
            alert((language === "Kor" && "최대 5장까지 업로드할 수 있습니다.") || (language === "Eng" && "You can upload up to 5 images"));
            files = null;
            return;
        }

        for (let i = 0; i < files.length; i++) {
            if (files[i].size / 1024 / 1024 > 10) {
                alert((language === "Kor" && "이미지 파일은 10MB 이하로 업로드해주세요.") || (language === "Eng" && "Please upload the image file under 10MB"));
                return;
            }

            let ext = getExtension(files[i].name);

            if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === '') {
                alert((language === "Kor" && "이미지 파일만 업로드 가능합니다.") || (language === "Eng" && "Only image files can be uploaded"));
                return;
            }
        }
        setUploadLoading(true);
        setImgFile([...imgFile, ...files]);

        try {
            const {data: updateCheckListEvaluationData} = await updateCheckListEvaluation({
                variables: {
                    wclId: wclId,
                    wciImgs: files
                }
            });

            if (updateCheckListEvaluationData?.updateCheckListEvaluation) {
                await refetch();
                e.target.files = null;
                setUploadLoading(false);
            }
        } catch(e) {
            alert(e.message);
            console.info(e.message);
        } finally {
            setUploadLoading(false);
        }

    }, [imgFile, wclId, data]);

    // 이미지 삭제
    const handleDeleteImage = useCallback(async wcliId => {
        try {
            const { data } = await deleteCheckListImg({
                variables: {
                    wcliId: wcliId
                }
            });

            if (data?.deleteCheckListImg) {
                await refetch();
            }
        } catch(e) {
            console.info(e.message);
            alert(e.message);
        }
    }, []);

    // 평가 완료
    const handleCompleteEvaluation = useCallback(() => {
        alert(
            (language === "Kor" && "평가를 완료했습니다.") ||
            (language === "Eng" && "The evaluation has been completed")
        );
        navigate('/check-list');
    }, [language]);

    useEffect(() => {
        if (!loading && data) {
            setComment(data?.seeCheckListDetail?.wcl_evaluation);
        }
    }, [loading, data]);

    return (
        <CheckListDetailContext.Provider value={{
            comment,
            setComment,
            commentModal,
            setCommentModal,
            // images,
            onChangeImage,
            handleDeleteImage,
            handleCompleteEvaluation,
            handleEnterComments,
            // 세부 평가 내용 모달
            evaluatorDetailModal,
            setEvaluatorDetailModal,
            // 주간 평가 모달
            weeklyEvaluationModal,
            setWeeklyEvaluationModal,
            checkListDetail: data?.seeCheckListDetail,
            refetch,
            wscId,
            setWscId,
            weeklySafetyCategory: data?.seeCheckListDetail?.weeklySafetyCategory,
            evaluationPermission,
            wclImages: data?.seeCheckListDetail?.wcl_img,
            fullView,
            setFullView
        }}>
            <CheckListDetailPresenter
                language={language}
                loading={loading}
                evaluationPermission={evaluationPermission}
                checkListDetail={data?.seeCheckListDetail}
                weeklySafetyCategory={data?.seeCheckListDetail?.weeklySafetyCategory}
                uploadLoading={uploadLoading}
            />
        </CheckListDetailContext.Provider>
    )
}

export default CheckListDetailContainer;
