import {createContext, useState, useContext, useCallback, useEffect} from 'react'
import {ThemeProvider as StyledProvider} from "styled-components";
import {lightTheme, darkTheme} from "../styles/theme";

export const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
    const [themeMode, setThemeMode] = useState("light");
    const [language, setLanguage] = useState("Kor");

    const themeObject = themeMode === "light" ? lightTheme : darkTheme;
    const isApp = !!window.ReactNativeWebView;
    const isTablet = window.innerWidth > 430; // width 430px 이상이면 tablet 으로 판단
    const isTabletLeft = 'calc((100vw - 430px) / 2)'; // position absolute 가운데 정렬 값

    return (
        <ThemeContext.Provider value={{ themeMode, setThemeMode, language, setLanguage }}>
            <StyledProvider theme={{...themeObject, isApp: isApp, isTablet, isTabletLeft}}>
                {children}
            </StyledProvider>
        </ThemeContext.Provider>
    )
}

const useTheme = () => {
    const themeContext = useContext(ThemeContext);
    const { themeMode, setThemeMode } = themeContext;

    const handleToggleTheme = useCallback(() => {
        if (themeMode === "light") {
            setThemeMode("dark");
        } else {
            setThemeMode("light");
        }
    }, [themeMode]);

    return [themeMode, handleToggleTheme];
}

export { ThemeProvider, useTheme };
