import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {HugeText} from "../../../components/share/StyledText";
import CheckBox from "../../../components/share/CheckBox";
import StyledInput from "../../../components/share/StyledInput";
import StyledPost from "../../../components/share/StyledPost";
import StyledImgUpload from "../../../components/share/StyledImgUpload";
import StyledButton from "../../../components/share/StyledButton";
import StyledCalendar from "../../../components/share/StyledCalendar";

const Wrapper = styled.div`
  //height: calc(var(--vh, 1vh) * 100);
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;
const Contents = styled.div`
  flex: 3;
`;
const FlexBox = styled.div`
  display: flex;flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : "row"};
  align-items: ${({alignItems}) => (alignItems ? alignItems : "center")};
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : "flex-start"};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const ImgBox = styled.div`
  width: 55px;
  height: 55px;
  margin-bottom: 16px;
  border-radius: 10px;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ButtonBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const CreateProjectPresenter = ({
                                    language = "Kor",
                                    goBack,
                                    nonDisclosure,
                                    setNonDisclosure,
                                    postModal,
                                    setPostModal,
                                    handleCompletePost,
                                    projectInfo,
                                    onChangeInput,
                                    onChangePeriod,
                                    imgUrl,
                                    onChangeImage,
                                    buttonStatus,
                                    handleAddMembers,
                                }) => {
    return (
        <Wrapper>
            <Contents>
                <GoBack onClick={goBack} />
                <FlexBox justifyContent="space-between">
                    <HugeText>
                        {language === "Kor" && "프로젝트 생성하기"}
                        {language === "Eng" && "Create Project"}
                    </HugeText>
                    <CheckBox
                        label={
                            (language === "Kor" && "비공개") ||
                            (language === "Eng" && "Private")
                        }
                        name="hidden"
                        checked={nonDisclosure}
                        onChange={(e) => setNonDisclosure(e.target.checked)}
                    />
                </FlexBox>
                <StyledInput
                    label={
                        (language === "Kor" && "프로젝트명*") ||
                        (language === "Eng" && "Project Name*")
                    }
                    margin="26px 0 14px"
                    name="name"
                    value={projectInfo.name}
                    onChange={onChangeInput}
                    maxLength={50}
                />
                <StyledPost
                    label={
                        (language === "Kor" && "현장주소*") ||
                        (language === "Eng" && "Location (site's address)*")
                    }
                    postModal={postModal}
                    setPostModal={setPostModal}
                    handleCompletePost={handleCompletePost}
                    value={projectInfo.address}
                />
                <StyledInput
                    name="detailAddress"
                    value={projectInfo.detailAddress}
                    onChange={onChangeInput}
                    maxLength={50}
                    placeholderFontSize={18}
                    placeholder={
                        (language === "Kor" && "상세주소 입력") ||
                        (language === "Eng" && "Enter detailed address")
                    }
                />
                <StyledCalendar
                    label={
                        (language === "Kor" && "기간선택*") ||
                        (language === "Eng" && "Construction Period*")
                    }
                    margin="14px 0"
                    value={projectInfo.period}
                    onChange={onChangePeriod}
                    placeholder={
                        (language === "Kor" && "기간을 입력해주세요.") ||
                        (language === "Eng" && "Please enter the period")
                    }
                />
                <StyledInput
                    label={
                        (language === "Kor" && "문서번호*") ||
                        (language === "Eng" && "Document Number Prefix*")
                    }
                    margin="14px 0"
                    name="documentNumber"
                    value={projectInfo.documentNumber}
                    onChange={onChangeInput}
                    placeholder={"HG-SCAR"}
                />
                <StyledImgUpload // 이미지 업로드 컴포넌트
                    label={
                        (language === "Kor" && "프로젝트 메인 사진*") ||
                        (language === "Eng" && "Project Main Image*")
                    }
                    onChange={onChangeImage}
                    placeholder={
                        (language === "Kor" && "1장의 메인 사진을 업로드해주세요.") ||
                        (language === "Eng" && "Please upload 1 main picture")
                    }
                />
                <ImgBox>{imgUrl && <Image src={imgUrl}/>}</ImgBox>
            </Contents>
            <ButtonBox>
                <StyledButton
                    title={
                        (language === "Kor" && "구성원 추가하기") ||
                        (language === "Eng" && "Invite Project Members")
                    }
                    onClick={handleAddMembers}
                    disabled={buttonStatus}
                />
            </ButtonBox>
        </Wrapper>
    );
};

export default React.memo(CreateProjectPresenter);
