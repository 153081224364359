import React from "react";
import styled, { css } from "styled-components";
import imgPlusIcon from "../../assets/icons/share/imgPlusIcon.svg";
import emptyImg from "../../assets/icons/share/emptyImg.svg";

const Wrapper = styled.label`
  width: 64px;
  height: 64px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  
  ${({ BORDER, theme }) => BORDER && css`
    border: 1px solid ${theme.whiteColor};
  `};
`;
const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
const Image = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyImg})` : `url(${src})`};
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
`;
const PlusIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const ProfileUpload = ({ BORDER, imgUrl, onChange }) => {
  return (
    <Wrapper BORDER={BORDER}>
      <FileInput type="file" accept=".png, .jpg, .jpeg" onChange={onChange} />
      <Image src={imgUrl} />
      <PlusIcon src={imgPlusIcon} />
    </Wrapper>
  );
};

export default React.memo(ProfileUpload);
