import React, {useContext} from "react";
import styled, {css} from "styled-components";
import {MediumText, SmallText} from "../../share/StyledText";
import CustomCalendar from "../../share/CustomCalendar";
import searchIcon from "../../../assets/icons/project/searchIcon.svg";
import sortIcon from "../../../assets/icons/project/sortIcon.svg";
import refreshIcon from "../../../assets/icons/project/refreshIcon.svg";
import arrowTopIcon from "../../../assets/icons/project/arrowTopIcon.svg";
import {ThemeContext} from "../../../context/ThemeProvider";

const Wrapper = styled.div`
  margin: 20px 0;
`;
const SortingBox = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${({theme}) => theme.sortingBoxColor};
`;
const Sorting = styled.div`
  width: 50%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SortingText = styled(MediumText)`
  word-break: keep-all;
  margin: 0 12px 2px 0;
`;
const Image = styled.img`
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const SortText = styled(MediumText)`
  color: ${({theme}) => theme.subTextColor};
  margin: 0 0 4px 8px;
`;
const DetailSortingForm = styled.div`
  height: 0;
  padding: 0 16px;
  margin-top: 8px;
  border-radius: 8px;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  opacity: 0;

  ${({detailSort}) =>
          detailSort &&
          css`
            height: 238px;
            padding: 25px 0 0 16px;
            border: 1px solid ${({theme}) => theme.lightBorderColor};
            opacity: 1;
            overflow: visible;
          `};
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) =>
          flexDirection ? flexDirection : "row"};
  align-items: ${({alignItems}) => (alignItems ? alignItems : "center")};
  justify-content: ${({justifyContent}) =>
          justifyContent ? justifyContent : "flex-start"};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const StatusButton = styled.button`
  height: 30px;
  color: ${({theme}) => theme.subTextColor};
  padding: 5px 14px;
  border-radius: 8px;
  word-break: keep-all;
  transition: 0.3s;
  border: 1px solid ${({theme}) => theme.lightBorderColor};
  background-color: ${({theme}) => theme.sortingBoxColor};

  & + & {
    margin-left: 6px;
  }

  ${({active, theme}) =>
          active &&
          css`
            color: ${theme.whiteColor};
            border: 1px solid ${({theme}) => theme.activeColor};
            background-color: ${theme.activeColor};
          `};
`;
const Line = styled.div`
  height: 1px;
  margin: 20px 0 22px;
  background-color: ${({theme}) => theme.lightBorderColor};
`;
const SortingButton = styled.button`
  height: 30px;
  color: ${({theme}) => theme.subTextColor};
  padding: 5px 14px;
  border-radius: 15px;
  word-break: keep-all;
  border: 1px solid ${({theme}) => theme.lightBorderColor};
  transition: 0.3s;
  background-color: ${({theme}) => theme.sortingBoxColor};

  & + & {
    margin-left: 6px;
  }

  ${({active, theme}) =>
          active &&
          css`
            color: ${theme.whiteColor};
            border: 1px solid ${({theme}) => theme.activeColor};
            background-color: ${theme.activeColor};
          `};
`;
const RefreshBox = styled.div`
  width: 100%;
  height: 0;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({theme}) => theme.lightBorderColor};

  ${({detailSort}) =>
          detailSort &&
          css`
            height: 36px;
          `};
`;
const RefreshText = styled(SmallText)`
  margin: 0 26px 0 8px;
  color: ${({theme}) => theme.subTextColor};
`;

const SortingForm = ({
                         navigate = () => null,
                         detailSort,
                         setDetailSort,
                         sortType = {},
                         handleSortType,
                         handleSortRefresh,
                     }) => {
    const {language} = useContext(ThemeContext);

    return (
        <Wrapper>
            <SortingBox>
                <Sorting onClick={() => navigate("project-search")}>
                    <Image src={searchIcon}/>
                    <SortText>
                        {language === "Kor" && "검색"}
                        {language === "Eng" && "Search"}
                    </SortText>
                </Sorting>
                <Sorting onClick={() => setDetailSort(!detailSort)}>
                    <Image src={sortIcon}/>
                    <SortText>
                        {language === "Kor" && "필터"}
                        {language === "Eng" && "Sort"}
                    </SortText>
                </Sorting>
            </SortingBox>

            <DetailSortingForm detailSort={detailSort}>
                <FlexBox>
                    <SortingText>
                        {language === "Kor" && "상태"}
                        {language === "Eng" && "Status"}
                    </SortingText>
                    <FlexBox>
                        <StatusButton
                            active={sortType.status === "전체"}
                            onClick={() => handleSortType("status", "전체")}
                        >
                            {language === "Kor" && "전체"}
                            {language === "Eng" && "All"}
                        </StatusButton>
                        <StatusButton
                            active={sortType.status === "진행중"}
                            onClick={() => handleSortType("status", "진행중")}
                        >
                            {language === "Kor" && "진행중"}
                            {language === "Eng" && "Ongoing"}
                        </StatusButton>
                        <StatusButton
                            active={sortType.status === "종료예정"}
                            onClick={() => handleSortType("status", "종료예정")}
                        >
                            {language === "Kor" && "종료예정"}
                            {language === "Eng" && "Closing"}
                        </StatusButton>
                        <StatusButton
                            active={sortType.status === "종료"}
                            onClick={() => handleSortType("status", "종료")}
                        >
                            {language === "Kor" && "종료"}
                            {language === "Eng" && "Closed"}
                        </StatusButton>
                    </FlexBox>
                </FlexBox>
                <Line/>
                <FlexBox>
                    <CustomCalendar
                        value={sortType.period}
                        onChange={handleSortType}
                        language={language}
                    />
                </FlexBox>
                <FlexBox margin="18px 0 0">
                    <MediumText margin="0 16px 2px 0">
                        {language === "Kor" && "정렬"}
                        {language === "Eng" && "Sort"}
                    </MediumText>
                    <FlexBox>
                        <SortingButton
                            active={sortType.sort === "내림차순"}
                            onClick={() => handleSortType("sort", "내림차순")}
                        >
                            {language === "Kor" && "내림차순"}
                            {language === "Eng" && "desc"}

                        </SortingButton>
                        <SortingButton
                            active={sortType.sort === "오름차순"}
                            onClick={() => handleSortType("sort", "오름차순")}
                        >
                            {language === "Kor" && "오름차순"}
                            {language === "Eng" && "asc"}
                        </SortingButton>
                    </FlexBox>
                </FlexBox>
                <RefreshBox detailSort={detailSort}>
                    <FlexBox>
                        <Image src={refreshIcon}/>
                        <RefreshText onClick={handleSortRefresh}>
                            {language === "Kor" && "초기화"}
                            {language === "Eng" && "Reset"}
                        </RefreshText>
                        <Image
                            margin="4px 0 0"
                            src={arrowTopIcon}
                            onClick={() => setDetailSort(!detailSort)}
                        />
                    </FlexBox>
                </RefreshBox>
            </DetailSortingForm>
        </Wrapper>
    );
};

export default React.memo(SortingForm);
