import React from "react";
import styled, {keyframes} from "styled-components";

const Spin = keyframes`
    0% { 
      transform: rotate(0deg); 
    }
    100% { 
      transform: rotate(360deg); 
    }
`;
const Wrapper = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  height: ${({ height }) => height ? height : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoaderBox = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid ${({ theme }) => theme.borderColor}; /* Light grey */
  border-top: 3px solid ${({ theme }) => theme.activeColor}; /* Blue */
  border-radius: 50%;
  animation: ${Spin} 1.3s linear infinite;
`;

const Loader = ({ width, height }) => {
    return (
        <Wrapper width={width} height={height}>
            <LoaderBox />
        </Wrapper>
    )
}

export default Loader;