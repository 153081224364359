import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { LargeText, MediumText } from "../../share/StyledText";
import { ProjectDetailContext } from "../../../pages/Project/ProjectDetail/ProjectDetailContainer";

const Button = styled.button`
  width: 100%;
  padding: 0 20px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: 0.35s;
  text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};

  & * {
    color: ${({ theme }) => theme.subTextColor};
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.activeBorderColor};
      & * {
        color: ${({ theme }) => theme.defaultTextColor};
      }
    `};
`;
const CountBox = styled.div`
  display: flex;
  align-items: center;
`;
const Count = styled(LargeText)`
  font-family: "AppleSDGothicNeoBold", "Apple SD Gothic Neo", sans-serif;
  margin-right: 10px;
`;

const ScarRadioButton = ({ title, count, textAlign }) => {
  const { scarSortType, setScarSortType } = useContext(ProjectDetailContext);

  const saveSort = (data) => {
    setScarSortType(data);
    sessionStorage.setItem("sort", data);
  }


  return (
    <Button
      active={title === scarSortType}
      onClick={() => saveSort(title)}
      textAlign={textAlign}
    >
      <MediumText>{title}</MediumText>
      <CountBox>
        <Count>{count}</Count>
        <MediumText>건</MediumText>
      </CountBox>
    </Button>
  );
};

export default React.memo(ScarRadioButton);
