import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation createProject(
    $prj_openAvailable: Boolean
    $prj_title: String!
    $prj_address: String!
    $prj_startDate: String!
    $prj_endDate: String!
    $prj_number: String!
    $selectImg: Boolean
    $prj_img: Upload
    $prj_members: [member]
  ) {
    createProject(
      prj_openAvailable: $prj_openAvailable
      prj_title: $prj_title
      prj_address: $prj_address
      prj_startDate: $prj_startDate
      prj_endDate: $prj_endDate
      prj_number: $prj_number
      selectImg: $selectImg
      prj_img: $prj_img
      prj_members: $prj_members
    ) {
      result
      error
    }
  }
`;
