import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {
    HugeText,
    LargeText,
    MediumText,
} from "../../../components/share/StyledText";
import StyledInput from "../../../components/share/StyledInput";
import ProfileImage from "../../../components/share/ProfileImage";
import StyledSelect from "../../../components/share/StyledSelect";
import FlexBox from "../../../components/share/FlexBox";
import exportIcon from "../../../assets/icons/project/exportIcon.svg";
import {engGroupChanger, groupChanger} from "../../../lib/ProfileUtils";
import countryCode from "../../../utils/countryCode";

const Wrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
`;
const Contents = styled.div`
  flex: 8;
`;
const UserList = styled.div`
  //height: calc(56vh);
  height: calc(var(--vh, 1vh) * 58);
  overflow-y: scroll;
`;
const UserBox = styled.div`
  padding: 16px 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const ProfileBox = styled.div`
  width: 80%;
  display: flex;
  align-items: flex-start;
`;
const InfoBox = styled.div`
  width: 80%;
  max-width: 78%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
`;
const InfoRowBox = styled(FlexBox)`
  width: 100%;
  align-items: flex-end;
`;
const MemberName = styled(LargeText)`
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const GrayText = styled(MediumText)`
  font-size: 16px;
  color: ${({theme}) => theme.subTextColor};
  margin-right: 8px;
`;
const UserTypeGrayText = styled(MediumText)`
  white-space: nowrap;
  font-size: 16px;
  color: ${({theme}) => theme.subTextColor};
`;
const Text = styled(MediumText)`
  color: ${({theme}) => theme.defaultTextColor};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const Icon = styled.img`
  margin-bottom: -4px;
`;

const EditMembersPresenter = ({
                                  language,
                                  inputs,
                                  onChangeInput,
                                  addMember,
                                  memberData,
                                  handleDelMember,
                              }) => {
    return (
        <Wrapper>
            <Contents>
                <GoBack route="/project-detail"/>
                <HugeText>
                    {language === "Kor" && "구성원 수정하기"}
                    {language === "Eng" && "Edit Members"}
                </HugeText>
                <FlexBox justifyContent="space-between" margin="26px 0 0">
                    <StyledSelect
                        label={(language === "Kor" && "소속") || (language === "Eng" && "")}
                        width="calc((100% - 16px) / 2)"
                        options={
                            (language === "Kor" && ["PM현장", "시공사", "발주처"]) ||
                            (language === "Eng" && ["PM", "Contractor", "Client"])
                        }
                        name="affiliation"
                        value={inputs.affiliation}
                        onChange={onChangeInput}
                    />
                    <StyledSelect
                        label={(language === "Kor" && "분류") || (language === "Eng" && "")}
                        width="calc((100% - 16px) / 2)"
                        options={
                            (language === "Kor" && ["연락처", "이메일"]) ||
                            (language === "Eng" && ["Phone number", "Email"])
                        }
                        name="type"
                        value={inputs.type}
                        onChange={onChangeInput}
                    />
                </FlexBox>
                <FlexBox
                    margin="26px 0 10px"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    {inputs.type === "연락처" || inputs.type === "Phone number" ? (
                        <>
                            <StyledSelect
                                label={
                                    (language === "Kor" && "국가") ||
                                    (language === "Eng" && "Country Code")
                                }
                                width={(language === 'Kor' && '30%') || (language === 'Eng' && '32%')}
                                options={countryCode}
                                name="countryCode"
                                value={inputs.countryCode}
                                onChange={onChangeInput}
                            />
                            <StyledInput
                                PLUS_BUTTON
                                label={
                                    (language === "Kor" && "연락처로 추가하기*") ||
                                    (language === "Eng" && "Invite with phone number or email*")
                                }
                                width={(language === 'Kor' && 'calc(70% - 16px)') || (language === 'Eng' && 'calc(68% - 16px)')}
                                name="phoneNumber"
                                type="tel"
                                value={inputs.phoneNumber}
                                onChange={onChangeInput}
                                onCreate={addMember}
                            />
                        </>
                    ) : (
                        <StyledInput
                            PLUS_BUTTON
                            label={
                                (language === "Kor" && "메일로 추가하기*") ||
                                (language === "Eng" && "Invite with phone number or email*")
                            }
                            name="email"
                            value={inputs.email}
                            onChange={onChangeInput}
                            onCreate={addMember}
                        />
                    )}
                </FlexBox>
                <UserList>
                    {memberData?.seeScarMember?.prj_member?.map((member, index) => (
                        <UserBox key={index + 1}>
                            {console.info('멤버 : ', member)}
                            <ProfileBox>
                                <ProfileImage
                                    width={48}
                                    height={48}
                                    src={member?.user_profileImg}
                                />
                                <InfoBox>
                                    <InfoRowBox>
                                        <GrayText>
                                            {language === "Kor" && groupChanger(member?.user_changedPermission)}
                                            {language === "Eng" && engGroupChanger(member?.user_changedPermission)}
                                        </GrayText>
                                        <MemberName>
                                            {member?.user_name} {member?.user_rank}
                                        </MemberName>
                                    </InfoRowBox>
                                    <Text margin="12px 0 4px">
                                        {member?.user_cellphone !== "" && member?.user_countryCode} {member?.user_cellphone}
                                    </Text>
                                    <Text>{member?.user_email}</Text>
                                </InfoBox>
                            </ProfileBox>
                            <FlexBox
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <UserTypeGrayText>
                                    {member?.unsignedUser_id === 0 ? (
                                        <>
                                            {language === "Kor" && "가입자"}
                                            {language === "Eng" && "user"}
                                        </>
                                    ) : (
                                        <>
                                            {language === "Kor" && "미가입자"}
                                            {language === "Eng" && "unsign user"}
                                        </>
                                    )}
                                </UserTypeGrayText>
                                {!member?.creator && (
                                    <Icon
                                        src={exportIcon}
                                        onClick={() =>
                                            handleDelMember(member?.user_id, member?.unsignedUser_id)
                                        }
                                    />
                                )}
                            </FlexBox>
                        </UserBox>
                    ))}
                </UserList>
            </Contents>
        </Wrapper>
    );
};

export default React.memo(EditMembersPresenter);
