import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {PieChart, Pie, Cell} from "recharts";
import {MediumText} from "../../share/StyledText";
import {ThemeContext} from "../../../context/ThemeProvider";

const COLORS = ["#008AF9", "#FF804B", "#FFBB41", "#B5B5B5"];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
`;
const ChartBox = styled.div`
  margin-right: 28px;
`;
const DataBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextBox = styled.div`
  margin-bottom: 16px;
`;
const ColorText = styled.div`
  font-size: 16px;
  font-family: "AppleSDGothicNeoBold";
  color: ${({fontColor}) => fontColor};
  margin-top: 8px;
  white-space: nowrap;
`;

const CircleChart = ({
                         CHECK_LIST,
                         status,
                     }) => {
    const {language, themeMode} = useContext(ThemeContext);

    const [data, setData] = useState([]);
    const [checkListData, setCheckListData] = useState([]);

    useEffect(() => {
        if (status) {
            if (CHECK_LIST) {
                console.info(status);
                setCheckListData([
                    {
                        name: '준수',
                        value: status.compliantCount,
                        ratio: status.compliant
                    },
                    {
                        name: '대부분준수',
                        value: status.mainlyCompliantCount,
                        ratio: status.mainlyCompliant
                    },
                    {
                        name: '일부준수',
                        value: status.partiallyCompliantCount,
                        ratio: status.partiallyCompliant
                    },
                    {
                        name: '해당없음',
                        value: status?.notapplicableCount,
                        ratio: status?.notapplicable
                    },
                ])
            } else {
                setData([
                    {
                        name: "최종완료",
                        value: status.completeCount,
                        ratio: status.completeRatio,
                    },
                    {
                        name: "진행중",
                        value: status.inProgressCount,
                        ratio: status.inProgressRatio,
                    },
                    {
                        name: "지연",
                        value: status.delayCount,
                        ratio: status.delayRatio,
                    },
                ]);
            }
        }
    }, [status, CHECK_LIST]);

    useEffect(() => {
        if (CHECK_LIST) {
            setTimeout(() => {
                setData(checkListData);
            }, 100);
        }
    }, [CHECK_LIST, checkListData]);

    return (
        <Wrapper>
            {!CHECK_LIST
                ?
                <>
                    <ChartBox>
                        <PieChart width={190} height={190}>
                            <Pie
                                data={data}
                                cx={90}
                                cy={90}
                                innerRadius={74}
                                outerRadius={90}
                                fill="#8884d8"
                                // paddingAngle={2}
                                dataKey="value"
                                startAngle={90}
                                endAngle={-360}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                    </ChartBox>
                    <DataBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "최종완료"}
                                {language === "Eng" && "Closed"}
                            </MediumText>
                            <ColorText fontColor={COLORS[0]}>
                                {data[0]?.ratio}% ({data[0]?.value} / {status?.totalCount})
                            </ColorText>
                        </TextBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "진행중"}
                                {language === "Eng" && "In Progress"}
                            </MediumText>
                            <ColorText fontColor={COLORS[1]}>
                                {data[1]?.ratio}% ({data[1]?.value} / {status?.totalCount})
                            </ColorText>
                        </TextBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "지연"}
                                {language === "Eng" && "Delay"}
                            </MediumText>
                            <ColorText fontColor={COLORS[2]}>
                                {data[2]?.ratio}% ({data[2]?.value} / {status?.totalCount})
                            </ColorText>
                        </TextBox>
                    </DataBox>
                </>
                :
                <>
                    <ChartBox>
                        <PieChart width={190} height={190}>
                            <Pie
                                data={data}
                                cx={90}
                                cy={90}
                                innerRadius={74}
                                outerRadius={90}
                                fill="#8884d8"
                                // paddingAngle={2}
                                dataKey="value"
                                startAngle={90}
                                endAngle={-360}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                    </ChartBox>
                    <DataBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "준수"}
                                {language === "Eng" && "준수"}
                            </MediumText>
                            <ColorText fontColor={COLORS[0]}>
                                {data[0]?.ratio}% ({data[0]?.value} / {status?.totalCheckItemCount})
                            </ColorText>
                        </TextBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "대부분준수"}
                                {language === "Eng" && "대부분준수"}
                            </MediumText>
                            <ColorText fontColor={COLORS[1]}>
                                {data[1]?.ratio}% ({data[1]?.value} / {status?.totalCheckItemCount})
                            </ColorText>
                        </TextBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "일부 준수"}
                                {language === "Eng" && "일부 준수"}
                            </MediumText>
                            <ColorText fontColor={COLORS[2]}>
                                {data[2]?.ratio}% ({data[2]?.value} / {status?.totalCheckItemCount})
                            </ColorText>
                        </TextBox>
                        <TextBox>
                            <MediumText>
                                {language === "Kor" && "해당 없음"}
                                {language === "Eng" && "해당 없음"}
                            </MediumText>
                            <ColorText fontColor={COLORS[3]}>
                                {data[3]?.ratio}% ({data[3]?.value} / {status?.totalCheckItemCount})
                            </ColorText>
                        </TextBox>
                    </DataBox>
                </>
            }

        </Wrapper>
    );
};

export default React.memo(CircleChart);
