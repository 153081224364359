import styled from "styled-components";

export const HugeText = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 24)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "AppleSDGothicNeoBold"},
    "Apple SD Gothic Neo", sans-serif;
  white-space: pre-wrap;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultTextColor};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

export const LargeText = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 18)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "AppleSDGothicNeoMedium"},
    "Apple SD Gothic Neo", sans-serif;
  white-space: pre-wrap;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultTextColor};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

export const MediumText = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 16)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "AppleSDGothicNeoMedium"},
    "Apple SD Gothic Neo", sans-serif;
  white-space: pre-wrap;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultTextColor};
  margin: ${({ margin }) => (margin ? margin : 0)};
  cursor: pointer;
`;

export const SmallText = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 14)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  font-family: ${({ fontFamily }) =>
      fontFamily ? fontFamily : "AppleSDGothicNeoMedium"},
    "Apple SD Gothic Neo", sans-serif;
  white-space: pre-wrap;
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultTextColor};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;
