import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import sideBarArrow from "../../../assets/icons/sideBar/sideBarArrow.svg";
import {
  HugeText,
  LargeText,
  MediumText,
} from "../../../components/share/StyledText";
import { dateFormat } from "../../../utils/commons";
import PolicyModal from "../../../components/feature/Setting/PolicyModal";
import ServiceModal from "../../../components/feature/Setting/ServiceModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
  flex: 1;
`;
const TabBox = styled.div`
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const TextBox = styled.div`
  height: 100%;
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DateText = styled(MediumText)`
  color: ${({ theme }) => theme.subTextColor};
`;
const Arrow = styled.img``;

const TermsPresenter = ({
  lookUpTerm,
  tos,
  myProfile,
  language,
  isOpenPolicyModal,
  setIsOpenPolicyModal,
  isOpenServiceModal,
  setIsOpenServiceModal,
}) => {
  console.log(tos);
  return (
    <Wrapper>
      <GoBack route={"/"} />
      <HugeText margin="0 0 26px">
        {(language === "Kor" && "약관보기") ||
          (language === "Eng" && "Terms and Conditions")}
      </HugeText>

      <TabBox
        onClick={() => lookUpTerm("https://agreementshg.riskzero.ai/policy")}
      >
        <TextBox>
          <LargeText>
            {(language === "Kor" && "개인정보처리방침") ||
              (language === "Eng" && "Personal information processing policy")}
          </LargeText>
          <DateText>
            {dateFormat(
              new Date(Number(myProfile?.user_privacyPolicyAgreeDate)),
              "yyyy.MM.dd",
            )}
          </DateText>
        </TextBox>
        <Arrow src={sideBarArrow} />
      </TabBox>

      <TabBox onClick={() => setIsOpenServiceModal(true)}>
        <TextBox>
          <LargeText>
            {(language === "Kor" && "서비스이용약관") ||
              (language === "Eng" && "Terms and Conditions of Service")}
          </LargeText>
          <DateText>
            {dateFormat(
              new Date(Number(myProfile?.user_tosAgreeDate)),
              "yyyy.MM.dd",
            )}
          </DateText>
        </TextBox>
        <Arrow src={sideBarArrow} />
      </TabBox>
      <PolicyModal
        isModalOpen={isOpenPolicyModal}
        setIsOpenModal={setIsOpenPolicyModal}
      />
      <ServiceModal
        tos={tos?.seeTOS?.tosList}
        isModalOpen={isOpenServiceModal}
        setIsOpenModal={setIsOpenServiceModal}
      />
    </Wrapper>
  );
};

export default React.memo(TermsPresenter);
