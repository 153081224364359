import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { LargeText } from "../../../share/StyledText";
import { ThemeContext } from "../../../../context/ThemeProvider";
import FlexBox from "../../../share/FlexBox";
import { CheckListDetailContext } from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";
import { lightTheme } from "../../../../styles/theme";
import CheckListCheckboxButton from "../../../../assets/images/button/btn-checkList-checkbox.png";
import CheckListCheckboxActiveButton from "../../../../assets/images/button/btn-checkList-checkbox-active.png";

const Wrapper = styled.div`
  padding: 20px 0;
`;
const RegularText = styled(LargeText)`
  max-width: 78%;
  flex: 1;
  color: ${({ theme, complete, fontColor }) =>
    fontColor
      ? fontColor
      : complete
      ? theme.subTextColor
      : theme.defaultTextColor};
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
  line-height: 1.2;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const GrayText = styled(LargeText)`
  color: ${({ theme }) => theme.subTextColor};
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
  white-space: nowrap;
  margin-left: 8px;
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `}
`;
const LinkBox = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
`;
const ArrowIcon = styled.img`
  transform: rotate(270deg);
`;
const Text = styled.span`
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultFontColor};
`;

const EvaluatorItem = ({ CHECKER, item, checkListDetail }) => {
  const { language } = useContext(ThemeContext);
  const [fontColor, setFontColor] = useState(lightTheme.negativeColor);
  const { setEvaluatorDetailModal, setWeeklyEvaluationModal, setWscId } =
    useContext(CheckListDetailContext);
  const evaluationPermission = parseInt(
    localStorage.getItem("evaluationPermission"),
    10,
  );

  const handleWeeklyEvaluation = useCallback(() => {
    if (
      checkListDetail?.wcl_status === "inProgress" &&
      evaluationPermission === 1
    ) {
      setWeeklyEvaluationModal(true);
      document.body.style.overflow = "hidden";
      setWscId(item.wsc_id);
    } else {
      setEvaluatorDetailModal(true);
      document.body.style.overflow = "hidden";
      setWscId(item.wsc_id);
    }
  }, [checkListDetail]);

  const checkListStatusText = useCallback(() => {
    switch (language) {
      case "Kor":
        switch (item.wsc_status) {
          case "inProgress":
            return "진행중";
          case "notEvaluation":
            return "미평가";
          case "complete":
            return "평가완료";
        }
        break;
      case "Eng":
        switch (item.wsc_status) {
          case "inProgress":
            return "In Progress";
          case "notEvaluation":
            return "Incomplete";
          case "complete":
            return "Completed";
        }
        break;
    }
  }, [item]);

  return (
    <Wrapper>
      <LinkBox onClick={handleWeeklyEvaluation}>
        {CHECKER ? (
          <>
            <RegularText complete={item.complete}>
              {language === "Kor" && item.wsc_safetyCategory_kr}
              {language === "Eng" && item.wsc_safetyCategory_eng}
              &nbsp;
            </RegularText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <RegularText fontColor={lightTheme.negativeColor}>
                {item.wsc_complyRate}%
              </RegularText>
              &nbsp;
              {/*<GrayText lineHeight={1.2}>{checkListStatusText()}</GrayText>*/}
              <img
                src={
                  item.wsc_status !== "notEvaluation"
                    ? CheckListCheckboxActiveButton
                    : CheckListCheckboxButton
                }
                alt={checkListStatusText()}
                style={{ width: "16px", height: "15px" }}
              />
            </div>
          </>
        ) : (
          <>
            <RegularText>
              {language === "Kor" && `${item.wsc_safetyCategory_kr}`}
              {language === "Eng" && `${item.wsc_safetyCategory_eng}`}
              &nbsp;
              <Text fontColor={lightTheme.negativeColor}>
                {item.wsc_complyRate}%
              </Text>
            </RegularText>
            <img src={CheckListCheckboxButton} alt={""} />
          </>
        )}
      </LinkBox>
    </Wrapper>
  );
};

export default React.memo(EvaluatorItem);
