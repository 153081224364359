import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import whiteCloseIcon from "../../../assets/icons/share/whiteCloseIcon.svg";
import {MediumText} from "../../share/StyledText";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  height: 100vh;
  max-height: 932px;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: ${({ theme }) => theme.isTablet ? theme.isTabletLeft : 0};
  z-index: 100;
  background-color: ${({theme}) => theme.blackColor};
  transition: 0.3s;
`;
const CloseButton = styled.img`
  margin-left: 10px;
`;
const PageCount = styled(MediumText)`
  font-size: 16px;
  text-align: center;
  color: ${({theme}) => theme.whiteColor};
  font-family: "AppleSDGothicNeoBold";
  margin: 24px 0 42px;
`;
const SlideBox = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  max-height: calc(932px - 200px);
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

`;
const FullImage = styled.div`
  width: ${({width}) => width}px;
  height: 100%;
  flex: 0 0 auto;
  scroll-snap-align: start;
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  object-fit: contain;
  
  border: 1px solid red;
  & > img  {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const FullViewModal = ({setFullView, images = []}) => {
    let imgSize = window.innerWidth;
    let [currentImg, setCurrentImg] = useState(1);

    const getScroll = useCallback((slideBox) => {
        let scroll = slideBox.scrollLeft;

        if (scroll === 0) {
            setCurrentImg(1);
        } else if (scroll === imgSize) {
            setCurrentImg(2);
        } else if (scroll === imgSize * 2) {
            setCurrentImg(3);
        } else if (scroll === imgSize * 3) {
            setCurrentImg(4);
        } else if (scroll === imgSize * 4) {
            setCurrentImg(5);
        }
    }, [imgSize]);

    const handleClose = useCallback(() => {
        setFullView(false);
        document.getElementById('check-list-detail-wrapper').style.height = 'auto';
    }, []);

    useEffect(() => {
        const slideBox = document.getElementById("scroll");

        slideBox.addEventListener("scroll", () => getScroll(slideBox));
        return () => slideBox.removeEventListener("scroll", getScroll);
    }, []);

    return (
        <Wrapper>
            <CloseButton src={whiteCloseIcon} onClick={handleClose}/>
            <PageCount>
                {currentImg} / {images.length}
            </PageCount>

            <SlideBox height={imgSize} id="scroll">
                {images?.map((img, index) => (
                        <FullImage width={imgSize} key={`img-${index}`}>
                            <Image src={img} />
                        </FullImage>
                ))}
                {/*{images?.map((img, index) => (*/}
                {/*    <Iframe key={index} src={img}>*/}
                {/*        <FullImage width={imgSize}>*/}
                {/*            <Image src={img}/>*/}
                {/*        </FullImage>*/}
                {/*    </Iframe>*/}
                {/*))}*/}
            </SlideBox>
        </Wrapper>
    );
};

export default React.memo(FullViewModal);
