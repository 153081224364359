import React, {useContext} from "react";
import styled from "styled-components";
import cameraIcon from "../../assets/icons/project/camera.svg";
import {MediumText, SmallText} from "./StyledText";
import {darkTheme, lightTheme} from "../../styles/theme";
import {ThemeContext} from "../../context/ThemeProvider";

const Wrapper = styled.div`
  margin: ${({margin}) => margin};
`;
const Label = styled.label`
  display: flex;
  align-items: center;
`;
const LabelImage = styled.span`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.activeColor};
  background: url(${cameraIcon}) no-repeat 50% 50%;
`;
const Text = styled(SmallText)`
  color: ${({theme}) => theme.subTextColor};
  margin-left: 10px;
`;
const Input = styled.input`
  display: none;
`;

const StyledImgUpload = ({
                            label,
                             margin = "10px 0 16px",
                             placeholder = "1장의 메인 사진을 업로드해주세요.",
                             multiple,
                             onChange = () => null,
                         }) => {
    const { themeMode } = useContext(ThemeContext);

    return (
        <Wrapper margin={margin}>
            {label && (
                <MediumText
                    margin="0 0 14px"
                    fontColor={themeMode === "light" ? lightTheme.subTextColor : darkTheme.subTextColor}
                >
                    {label}
                </MediumText>
            )}
            <Label>
                <LabelImage/>
                <Text>{placeholder}</Text>
                <Input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    multiple={multiple}
                    onChange={onChange}
                />
            </Label>
        </Wrapper>
    )
}

export default React.memo(StyledImgUpload);