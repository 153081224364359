import React, {useContext} from "react";
import styled from "styled-components";
import {ThemeContext} from "../../context/ThemeProvider";
import {MediumText} from "./StyledText";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
`;
const Alert = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 20px 28px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.whiteColor};
`;
const Text = styled(MediumText)`
  line-height: 1.2;
`;
const Button = styled.div`
  color: ${({ theme }) => theme.subTextColor};
  align-self: flex-end;
  margin-top: 26px;
`;

const CustomAlert = ({ message, onClose }) => {
    const { language } = useContext(ThemeContext);

    return (
        <Wrapper>
            <Alert>
                <Text>
                    {message}
                </Text>
                <Button onClick={onClose}>
                    {language === 'Kor' && '닫기'}
                    {language === 'Eng' && 'Close'}
                </Button>
            </Alert>
        </Wrapper>
    )
}

export default React.memo(CustomAlert);