import React, {
    memo,
    useState,
    useRef,
    useCallback,
    useEffect,
    useContext,
} from "react";
import styled, {css} from "styled-components";
import selectBottomArrow from "../../assets/icons/share/selectBottomArrow.svg";
import {LargeText, SmallText} from "./StyledText";
import "./FlexBox";
import FlexBox from "./FlexBox";
import ProfileImage from "./ProfileImage";
import {engGroupChanger, groupChanger} from "../../lib/ProfileUtils";
import {ThemeContext} from "../../context/ThemeProvider";

const Wrapper = styled.div`
  position: relative;
  margin: ${({margin}) => margin};
`;
const Label = styled(SmallText)`
  height: 18px;
  font-size: 16px;
  color: ${({titleFontColor, theme}) =>
          titleFontColor ? titleFontColor : theme.defaultTextColor};
`;
const SelectBox = styled.div`
  width: ${({width}) => width};
  padding: 16px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  background: url(${selectBottomArrow}) 95% 50% no-repeat ${({theme}) => theme.whiteColor};

  ${({disabled}) => disabled && css`
    background: none;
  `};
`;
const OptionBox = styled.div`
  width: 100%;
  min-height: ${({height}) => (height ? height : 46)}px;
  max-height: 330px;
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: absolute;
  top: 79px;
  left: 0;
  z-index: 2;
  overflow-y: scroll;
  border: 1px solid ${({theme}) => theme.borderColor};
  border-radius: 8px;
  background-color: ${({theme}) => theme.bgColor};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const ValueBox = styled(FlexBox)`
  width: 100%;
  align-items: center;
`;
const Option = styled(FlexBox)`
  align-items: center;

  & + & {
    margin-top: 18px;
  }
`;
const OptionText = styled(LargeText)`
  font-family: "AppleSDGothicNeoRegular";
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: ${({role, theme}) => role
          ? css`
            ${theme.subTextColor};
          `
          : css`
            ${theme.defaultTextColor}
          `};
`;
const EllipsisText = styled.div`
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : 120}px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomSelect = ({
                          label,
                          titleFontColor,
                          width = "100%",
                          margin = 0,
                          options = [],
                          name,
                          value,
                          onChange,
                          disabled,
                      }) => {
    const {language} = useContext(ThemeContext);
    const boxRef = useRef(null);
    const optRef = useRef(null);
    const [optionVisible, setOptionVisible] = useState(false);

    const onClickOutSide = useCallback(({target}) => {
        let boxRefCurrent = boxRef.current && boxRef.current.contains(target);
        let optRefCurrent = optRef.current && optRef.current.contains(target);

        if (!boxRefCurrent && !optRefCurrent) setOptionVisible(false);
    }, [onChange, boxRef, optRef]);

    const onClickOption = useCallback((opt) => {
        onChange(name, opt);
        setOptionVisible(false);
    }, [onChange, name]);

    useEffect(() => {
        window.addEventListener("click", onClickOutSide);
        return () => window.removeEventListener("click", onClickOutSide);
    }, []);

    return (
        <Wrapper margin={margin}>
            <Label titleFontColor={titleFontColor}>{label}</Label>
            <SelectBox
                disabled={disabled}
                width={width}
                ref={boxRef}
                onClick={() => (disabled ? null : setOptionVisible(!optionVisible))}
            >
                <ValueBox>
                    <ProfileImage
                        width={24}
                        height={25}
                        src={value?.img ? value?.img : ""}
                    />
                    <OptionText role={value?.user_name}>
                        {value ? (
                            <>
                                <EllipsisText maxWidth={100}>{(language === "Kor" && groupChanger(value?.group)) || (language === "Eng" && engGroupChanger(value?.group))}</EllipsisText>&nbsp;
                                <EllipsisText>{value?.name}</EllipsisText>&nbsp;
                                <EllipsisText maxWidth={100}>{value?.rank}</EllipsisText>
                            </>
                        ) : (
                            <>
                                {language === "Kor" && "선택하기"}
                                {language === "Eng" && "Select"}
                            </>
                        )}
                    </OptionText>
                </ValueBox>
            </SelectBox>
            {optionVisible && (
                <OptionBox ref={optRef} width={width}>
                    {options?.map((opt, index) => (
                        <Option key={`${index} ${opt}`} onClick={() => onClickOption(opt)}>
                            <ProfileImage width={24} height={25} src={opt?.user_profileImg}/>
                            <OptionText>
                                <EllipsisText maxWidth={80}>
                                    {(language === "Kor" && groupChanger(opt?.user_changedPermission)) || (language === "Eng" && engGroupChanger(opt?.user_changedPermission))}
                                </EllipsisText>&nbsp;
                                <EllipsisText maxWidth={114}>{opt?.user_name}</EllipsisText>&nbsp;
                                <EllipsisText maxWidth={90}>{opt?.user_rank}</EllipsisText>
                            </OptionText>
                        </Option>
                    ))}
                </OptionBox>
            )}
        </Wrapper>
    );
};

export default memo(CustomSelect);
