import React, { useCallback, useContext, useState } from "react";
import LoginPresenter from "./LoginPresenter";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeProvider";
import {
  getCountryNumberToCode,
  removeHyphen,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/commons";
import { useMutation } from "@apollo/client";
import { REQUEST_AUTH_CODE } from "../../graphql/Login/mutation";
import { requestAuthCodeErrorMessage } from "../../lib/ErrorCodeUtils";

const LoginContainer = () => {
  const navigate = useNavigate();
  const { themeMode, language, setLanguage } = useContext(ThemeContext); // 테마 모드
  const [inputs, setInputs] = useState({
    // Inputs 상태
    type: "PHONE",
    countryCode: "+82",
    phoneNumber: "",
    email: "",
  });

  const [requestAuthCode] = useMutation(REQUEST_AUTH_CODE);

  const handleLoginType = useCallback((type) => {
    setInputs((prev) => ({ ...prev, type }));
  }, []);

  const onChange = useCallback(
    (e) => {
      // Inputs 상태관리 함수
      const { name, value } = e.target;

      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs],
  );

  const handleChangeLanguage = useCallback((language) => {
    setLanguage(language);
    localStorage.setItem("language", language);
    window.location.reload();
  }, []);

  const handleLogin = useCallback(async () => {
    // 로그인 버튼 클릭
    if (inputs.type === "PHONE" && inputs.phoneNumber === "") {
      alert(
        (language === "Kor" && "휴대폰 번호를 입력해주세요.") ||
          (language === "Eng" && "Please enter your cell phone number"),
      );
      return;
    }
    if (inputs.type === "EMAIL" && inputs.email === "") {
      alert(
        (language === "Kor" && "이메일을 입력해주세요.") ||
          (language === "Eng" && "Please enter your e-mail"),
      );
      return;
    }

    if (
      inputs.type === "PHONE" &&
      !validatePhoneNumber(
        inputs.phoneNumber,
        getCountryNumberToCode(inputs.countryCode),
      )
    ) {
      alert(
        (language === "Kor" && "휴대폰번호 형식에 맞게 입력해주세요.") ||
          (language === "Eng" &&
            "Enter the phone number according to the format."),
      );
      return;
    }

    if (inputs.type === "EMAIL" && !validateEmail(inputs.email)) {
      alert(
        (language === "Kor" && "이메일 형식에 맞게 입력해주세요.") ||
          (language === "Eng" && "Enter the email according to the format."),
      );
      return;
    }

    try {
      const { data } = await requestAuthCode({
        variables: {
          type: inputs.type,
          countryCode: inputs.countryCode,
          cellphone: removeHyphen(inputs.phoneNumber),
          authPage: true,
          email: inputs.email,
        },
      });

      requestAuthCodeErrorMessage(data?.requestAuthCode?.error, language);

      if (data?.requestAuthCode?.result) {
        alert(
          (language === "Kor" && "인증번호가 전송되었습니다.") ||
            (language === "Eng" && "Authentication number has been sent"),
        );
        navigate("/auth-num-check", {
          state: {
            countryCode: inputs.countryCode,
            phoneNumber: removeHyphen(inputs.phoneNumber),
            email: inputs.email,
            login: true,
          },
        });
      }
    } catch (e) {
      alert(e);
    }
  }, [inputs]);

  const handleSignUp = useCallback(() => navigate("/signup"), []); // 회원가입 버튼 클릭

  return (
    <LoginPresenter
      themeMode={themeMode}
      inputs={inputs}
      onChange={onChange}
      handleLogin={handleLogin}
      handleSignUp={handleSignUp}
      language={language}
      handleChangeLanguage={handleChangeLanguage}
      handleLoginType={handleLoginType}
    />
  );
};

export default LoginContainer;
