import { gql } from "@apollo/client";

export const SEE_MY_NOTIFICATION_SETTING = gql`
  query seeMyNotificationSetting {
    seeMyNotificationSetting {
      result
      error
      notiSetting {
        user_pushNoti
        user_msgNoti
        user_emailNoti
        user_kakaoNoti
      }
    }
  }
`;
