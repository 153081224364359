import React, {useCallback, useContext, useEffect, useState} from "react";
import EditProjectPresenter from "./EditProjectPresenter";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../../context/ThemeProvider";
import CreateProjectPresenter from "../CreateProject/CreateProjectPresenter";
import {EDIT_PROJECT} from "../../../graphql/Project/EditProject/mutation";
import {useMutation} from "@apollo/client";

const EditProjectContainer = () => {
    const navigate = useNavigate();
    const {language} = useContext(ThemeContext); // 테마 모드
    const {prjInfo} = useLocation().state;
    const [nonDisclosure, setNonDisclosure] = useState(false); // 프로젝트 공개 여부
    const [postModal, setPostModal] = useState(false); // 주소 모달 상태
    const [projectInfo, setProjectInfo] = useState({
        name: "",
        address: "",
        detailAddress: "",
        period: [new Date(), new Date()],
        documentNumber: "",
    });
    const [imgUrl, setImgUrl] = useState(null);

    const [imgFile, setImgFile] = useState(null);

    const [editProject] = useMutation(EDIT_PROJECT);

    const onChangeInput = useCallback((e) => { // input 상태 관리 함수
        const {name, value} = e.target;

        if (value.substring(0, 1) === ' ') return;
        setProjectInfo({
            ...projectInfo,
            [name]: value,
        });
    }, [projectInfo]);

    const onChangePeriod = useCallback((name, value) => { // 기간 변경 함수
        setProjectInfo({
            ...projectInfo,
            [name]: value,
        });
    }, [projectInfo]);

    const onChangeImage = useCallback(async (e) => {// 이미지 등록 함수
        const file = e.target.files[0];

        setImgFile(file);
        setImgUrl(URL.createObjectURL(file));
    }, [imgFile, imgUrl]);

    const handleCompletePost = useCallback((data) => {
        // 주소 api 완료 함수
        // let fullAddress = data.address;
        // let extraAddress = '';
        // if (data.addressType === 'R') {
        //     if (data.bname !== '') {
        //         extraAddress += data.bname;
        //     }
        //     if (data.buildingName !== '') {
        //         extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        //     }
        //     fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        // }
        // console.log(fullAddress);
        setProjectInfo({
            ...projectInfo,
            address: data.address,
        });
        setPostModal(false);
    }, [projectInfo]);

    const handleEditProject = useCallback(async () => {
        let variables;
        if (!imgFile) {
            // 이미지 수정 x
            variables = {
                prjTitle: projectInfo.name,
                prjAddress: projectInfo.address + " " + projectInfo.detailAddress,
                prjStartDate: projectInfo.period[0],
                prjEndDate: projectInfo.period[1],
                prjNumber: projectInfo.documentNumber,
                prjOpenAvailable: !nonDisclosure,
                prjId: prjInfo.prj_id,
                prjImgSame: true,
            };
        } else {
            // 이미지 수정 ㅇ
            variables = {
                prjTitle: projectInfo.name,
                prjAddress: projectInfo.address + " " + projectInfo.detailAddress,
                prjStartDate: projectInfo.period[0],
                prjEndDate: projectInfo.period[1],
                prjNumber: projectInfo.documentNumber,
                prjOpenAvailable: !nonDisclosure,
                prjId: prjInfo.prj_id,
                prjImgSame: false,
                prjImg: imgFile,
            };
        }
        try {
            const {data} = await editProject({variables});
            if (data?.editProject?.result) {
                alert((language === 'Kor' && '프로젝트가 수정되었습니다.') || (language === 'Eng' && 'Project modification successfully'));
                navigate("/project-detail");
                window.location.reload();
            }
            if (data?.editProject?.error !== "") {
                alert(data?.editProject?.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [projectInfo, imgFile, nonDisclosure]);

    useEffect(() => {
        if (prjInfo) {
            setProjectInfo({
                name: prjInfo?.prj_title,
                address: prjInfo?.prj_address,
                detailAddress: "",
                period: [
                    new Date(Number(prjInfo?.prj_startDate)),
                    new Date(Number(prjInfo?.prj_endDate)),
                ],
                documentNumber: prjInfo?.prj_number,
            });
            setNonDisclosure(!prjInfo?.prj_openAvailable);
            setImgUrl(prjInfo?.prj_img);
        }
    }, [prjInfo]);

    return (
        <EditProjectPresenter
            language={language}
            nonDisclosure={nonDisclosure}
            setNonDisclosure={setNonDisclosure}
            postModal={postModal}
            setPostModal={setPostModal}
            handleCompletePost={handleCompletePost}
            projectInfo={projectInfo}
            onChangeInput={onChangeInput}
            onChangePeriod={onChangePeriod}
            imgUrl={imgUrl}
            onChangeImage={onChangeImage}
            handleEditProject={handleEditProject}
        />
    );
};

export default EditProjectContainer;
