import {gql} from "@apollo/client";

export const SCAR_STATISTICS_EXCEL = gql`
    mutation scarStatisticsExcel(
        $prj_id: Int
        $searchStart: String
        $searchEnd: String
    ) {
        scarStatisticsExcel(
            prj_id: $prj_id
            searchStart: $searchStart
            searchEnd: $searchEnd
        ) {
            result
            error
            excel
        }
    }
`;

export const CHECK_LIST_STATISTICS_EXCEL = gql`
    mutation checkListStatisticsExcel (
        $prj_id: Int!
        $searchStart: String!
        $searchEnd: String!
    ) {
        checkListStatisticsExcel (
            prj_id: $prj_id
            searchStart: $searchStart
            searchEnd: $searchEnd
        ) {
            result
            excel
        }
    }
`;