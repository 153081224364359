export default () => {
    // React Native 환경에서만 가능
    const listener = event => {
        const { data, type } = JSON.parse(event.data);

        if (type === "TOKEN") {
            // type 이 TOKEN 이기 때문에 이곳에 콘솔이 찍히게 됩니다.
            console.log(data);

        } else if (type === "NOTIFICATION") {

        }
    };

    if (window.ReactNativeWebView) {
        // Android
        document.addEventListener("message", listener);
        // IOS
        window.addEventListener("message", listener);
    }
    // else {
    //     // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
    //     alert({message: ERROR_TYPES.notMobile});
    // }
}