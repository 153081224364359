import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import ProfileUpload from "../../../components/share/ProfileUpload";
import { darkTheme, lightTheme } from "../../../styles/theme";
import StyledInput from "../../../components/share/StyledInput";
import StyledButton from "../../../components/share/StyledButton";
import StyledSelect from "../../../components/share/StyledSelect";
import CheckBox from "../../../components/share/CheckBox";
import { HugeText } from "../../../components/share/StyledText";
import FlexBox from "../../../components/share/FlexBox";
import Loader from "../../../components/share/Loader";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
`;
const Contents = styled.div`
  flex: 1;
  margin-top: 40px;
`;
const Message = styled.span`
  color: ${({ theme, fontColor }) =>
    fontColor ? fontColor : theme.activeColor};
  position: absolute;
  right: 0;
  top: 0;
`;
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 14px 0;
  position: relative;
`;
const TermText = styled.div`
  font-weight: 600;
  margin-left: 6px;
`;

const SignUpInfoPresenter = ({
  themeMode,
  language = "Kor",
  imgUrl,
  onChangeImage,
  userInfo,
  onChangeUserInfo,
  emailCheck,
  emailValid,
  agree,
  handleAgree,
  handleSignUp,
  signUpLoading,
  buttonStatus,
  lookUpTerm,
  emailDisabled,
}) => {
  if (signUpLoading) return <Loader height="100vh" />;

  return (
    <Wrapper>
      <GoBack />
      <FlexBox justifyContent="space-between">
        <FlexBox flexDirection="column" alignItems="flex-start">
          <HugeText>
            {language === "Kor" && "가입을 위한 정보를"}
            {language === "Eng" && "Please enter the info"}
          </HugeText>
          <HugeText margin="10px 0 0">
            {language === "Kor" && "입력해주세요"}
            {language === "Eng" && "for sign up"}
          </HugeText>
        </FlexBox>
        <FlexBox>
          <ProfileUpload imgUrl={imgUrl} onChange={onChangeImage} />
        </FlexBox>
      </FlexBox>
      <Contents>
        <FlexBox justifyContent="space-between">
          <StyledInput
            label={
              (language === "Kor" && "성함*") || (language === "Eng" && "Name*")
            }
            width="48%"
            name="name"
            value={userInfo.name}
            onChange={onChangeUserInfo}
            maxLength={30}
          />
          <StyledInput
            label={
              (language === "Kor" && "직책") ||
              (language === "Eng" && "Position")
            }
            width="48%"
            name="duty"
            maxLength={30}
            value={userInfo.duty}
            onChange={onChangeUserInfo}
          />
        </FlexBox>
        <ColumnBox>
          <StyledInput
            label={
              (language === "Kor" && "메일주소*") ||
              (language === "Eng" && "Email*")
            }
            name="email"
            value={userInfo.email}
            onChange={!emailDisabled && onChangeUserInfo}
            onBlur={emailValid}
          />
          {emailCheck === "valid" && <Message>올바른 메일 주소입니다.</Message>}
          {emailCheck === "invalid" && (
            <Message
              fontColor={
                themeMode === "light"
                  ? lightTheme.negativeColor
                  : darkTheme.negativeColor
              }
            >
              메일 양식을 확인해주세요.
            </Message>
          )}
        </ColumnBox>
        <FlexBox margin="14px 0">
          <StyledSelect
            label={
              (language === "Kor" && "소속*") ||
              (language === "Eng" && "Affiliation*")
            }
            options={
              (language === "Kor" && [
                "PM본사",
                "PM현장",
                "시공사",
                "발주처",
              ]) ||
              (language === "Eng" && ["PM HQ", "PM", "Contractor", "Client"])
            }
            name="affiliation"
            value={userInfo.affiliation}
            onChange={onChangeUserInfo}
          />
        </FlexBox>
        <FlexBox margin="14px 0">
          <StyledInput
            label={
              (language === "Kor" && "소속회사명*") ||
              (language === "Eng" && "Affiliation Company*")
            }
            name="company"
            value={userInfo.company}
            onChange={onChangeUserInfo}
          />
        </FlexBox>
        <FlexBox margin="14px 0 24px">
          <StyledInput
            label={
              (language === "Kor" && "소속회사 코드 입력*") ||
              (language === "Eng" && "Affiliation Company Code*")
            }
            name="code"
            value={userInfo.code}
            onChange={onChangeUserInfo}
          />
        </FlexBox>
        <FlexBox alignItems="flex-end">
          <CheckBox
            label={
              (language === "Kor" && "약관에 동의합니다.") ||
              (language === "Eng" && "Accept the terms and conditions")
            }
            margin="0 4px 0 0"
            fontFamily="AppleSDGothicNeoRegular"
            checked={agree}
            onChange={handleAgree}
          />
          <TermText onClick={lookUpTerm}>
            {language === "Kor" && "보러가기"}
            {language === "Eng" && "View Terms and Conditions"}
          </TermText>
        </FlexBox>
        <StyledButton
          onClick={handleSignUp}
          title={
            (language === "Kor" && "회원가입하기") ||
            (language === "Eng" && "Sing Up")
          }
          margin="26px 0 0"
          disabled={buttonStatus}
        />
      </Contents>
    </Wrapper>
  );
};

export default React.memo(SignUpInfoPresenter);
