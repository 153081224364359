import React, {useContext} from "react";
import styled, {css} from "styled-components";
import {useNavigate} from "react-router-dom";
import {LargeText, SmallText} from "../../share/StyledText";
import whiteGoBack from "../../../assets/icons/project/whiteGoBack.svg";
import dots from "../../../assets/icons/project/dots.svg";
import grayLocationIcon from "../../../assets/icons/project/grayLocationIcon.svg";
import NotifyModal from "./NotifyModal";
import {ProjectDetailContext} from "../../../pages/Project/ProjectDetail/ProjectDetailContainer";
import {dateFormat} from "../../../utils/commons";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  height: ${({ theme }) => theme.isApp ? '136px' : '116px'};
  padding: ${({ theme }) => theme.isApp ? '20px 0' : '0'};
  position: fixed;
  z-index: 1;
  top: 20px;
  left: ${({ theme }) => theme.isTablet ? theme.isTabletLeft : 0};
  display: flex;
  justify-content: center;

  &:after {
    content: "";
    width: calc(100% + 40px);
    height: ${({ theme }) => theme.isApp ? '176px' : '136px'};
    position: absolute;
    z-index: -1;
    top: ${({ theme }) => theme.isApp ? '-40px' : ' -20px'};
    left: -20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
const BackgroundImage = styled.img`
  width: calc(100% + 40px);
  height: ${({ theme }) => theme.isApp ? '176px' : '136px'};
  position: absolute;
  z-index: -1;
  top: ${({ theme }) => theme.isApp ? '-40px' : ' -20px'};
  left: -20px;
  object-fit: cover;
`;
const ImageBox = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  ${({right}) => right && css`
    right: ${right}px;
  `};

  ${({left}) => left && css`
    left: ${left}px;
  `};
`;
const Image = styled.img`
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const ProjectInfoBox = styled.div`
  width: calc(100%);
  max-width: calc(100% - 60px);
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled(LargeText)`
  width: calc(100% - 36px);
  padding-bottom: 3px;
  color: ${({theme}) => theme.whiteColor};
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 16px;
  overflow: hidden;
`;
const ProjectInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 6px;
`;
const SubText = styled(SmallText)`
  line-height: 1.2;
  color: ${({theme}) => theme.subTextColor};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  word-break: keep-all;
`;

const ProjectDetailHeader = ({prjInfo}) => {
    const navigate = useNavigate();
    const {handleQuitProject, handleProjectTermination, notifyModal, setNotifyModal} = useContext(ProjectDetailContext);

    return (
        <>
            <Wrapper>
                <BackgroundImage src={prjInfo?.prj_img}/>
                <ImageBox left={10} onClick={() => navigate("/")}>
                    <Image src={whiteGoBack}/>
                </ImageBox>
                <ProjectInfoBox>
                    <Title>
                        {prjInfo?.prj_title}
                    </Title>
                    <ProjectInfo>
                        <Image margin="2px 4px 0 0" src={grayLocationIcon}/>
                        <SubText>
                            {prjInfo?.prj_address}
                        </SubText>
                    </ProjectInfo>
                    <SubText>
                        {dateFormat((prjInfo?.prj_startDate), "yyyy.MM.dd")}&nbsp;-&nbsp;{dateFormat((prjInfo?.prj_endDate), "yyyy.MM.dd")}
                    </SubText>
                </ProjectInfoBox>
                <ImageBox
                    right={10}
                    onClick={() => {
                        setNotifyModal(true);
                        document.body.style.overflow = "hidden";
                    }}
                >
                    <Image src={dots}/>
                </ImageBox>
            </Wrapper>

            <NotifyModal
                PROJECT
                notifyModal={notifyModal}
                setNotifyModal={setNotifyModal}
                handleQuitProject={handleQuitProject}
                handleProjectTermination={handleProjectTermination}
                prjInfo={prjInfo}
            />
        </>
    );
};

export default React.memo(ProjectDetailHeader);
