import React from 'react';
import styled, {css} from "styled-components";

const Box = styled.div`
  //height: 28px;
  position: relative;
  margin: ${({margin}) => margin ? margin : 0};
`;
const Label = styled.label`
  font-size: ${({fontSize}) => fontSize ? fontSize : 16}px;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 400};
  font-family: ${({fontFamily}) => fontFamily}, "Apple SD Gothic Neo", sans-serif;
  color: ${({fontColor, theme}) => fontColor ? fontColor : theme.subTextColor};
  margin: ${({textMargin}) => textMargin ? textMargin : "6px 0 0 32px"};
  display: inline-block;

  &:before {
    content: '';
    width: 8px;
    height: 4px;
    vertical-align: top;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 5px;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.3s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.3s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border: 2px solid ${({ theme }) => theme.whiteColor};
    border-radius: 1px;
    border-top-style: none;
    border-right-style: none;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 5px;
    transition: all .2s ease;
    background-color: ${({ theme }) => theme.subTextColor};
    cursor: pointer;
  }

  ${({checked}) => checked && css`
    &:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-color: ${({ theme }) => theme.buttonTextColor};
    }

    &:after {
      border-color: ${({ theme }) => theme.checkedColor};
      background-color: ${({ theme }) => theme.checkedColor};
    }
  `}
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: auto;
  z-index: -1;
`;

const CheckBox = ({
                            label,
                            fontSize,
                            fontWeight,
                            fontFamily,
                            fontColor,
                            margin,
                            textMargin,
                            name = "checkbox",
                            value,
                            checked = true,
                            onChange = () => null,
                        }) => {
    return (
        <Box margin={margin}>
            <Input
                id={name}
                type="checkbox"
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <Label
                htmlFor={name}
                textMargin={textMargin}
                fontSize={fontSize}
                fontWeight={fontWeight}
                fontFamily={fontFamily}
                fontColor={fontColor}
                checked={checked}
            >
                {label}
            </Label>
        </Box>
    )
}

export default React.memo(CheckBox);