import React, {useCallback, useContext, useEffect, useState} from "react";
import CheckListPresenter from "./CheckListPresenter";
import {ThemeContext} from "../../../context/ThemeProvider";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_PROJECT_CHECK_LIST} from "../../../graphql/Project/CheckList/query";
import {UPDATE_EVALUATOR} from "../../../graphql/Project/CheckList/mutation";

const CheckListContainer = () => {
    const prjId = parseInt(localStorage.getItem('prjId'), 10);
    const navigate = useNavigate();
    const {language} = useContext(ThemeContext); // 테마 모드

    // Mutation
    const [updateEvaluator] = useMutation(UPDATE_EVALUATOR);

    // Query
    const { data, loading, refetch, error } = useQuery(SEE_PROJECT_CHECK_LIST, {
        variables: {
            prjId: prjId,
        },
        fetchPolicy: 'network-only'
    });

    const handleEvaluation = useCallback(async () => {
        if (data?.seeProjectCheckList?.evaluationPermission === 0) {
            if (window.confirm(
                (language === "Kor" && "평가를 시작하시면 취소할 수 없으며\n다른 사람은 평가할 수 없습니다.") ||
                (language === "Eng" && "Once you start, you cannot\ncancel it and non-initial users\ncannot modify it.")
            )) {
                try {
                    const { data: updateData } = await updateEvaluator({
                        variables: {
                            wclId: data?.seeProjectCheckList?.thisWeekCheckList?.wcl_id
                        }
                    });

                    console.info('updateEvaluator', updateData);

                    if (updateData?.updateEvaluator?.result) {
                        localStorage.setItem('evaluationPermission', updateData?.updateEvaluator?.evaluationPermission);
                        navigate('/check-list-detail');
                    }
                } catch(e) {
                    console.info(e.message);
                }
            }
        } else {
            navigate('/check-list-detail');
        }
    }, [data]);

    useEffect(() => {
        if (!loading && data) {
            localStorage.setItem('wclId', data?.seeProjectCheckList?.thisWeekCheckList?.wcl_id);
            localStorage.setItem('evaluationPermission', data?.seeProjectCheckList?.evaluationPermission);
        }
        if (error) {
            alert(error.message);
            navigate('/project-detail');
        }
    }, [data, error]);

    return (
        <CheckListPresenter
            language={language}
            handleEvaluation={handleEvaluation}
            loading={loading}
            error={error}
            completeCheckList={data?.seeProjectCheckList?.completeCheckList}
            thisWeekCheckList={data?.seeProjectCheckList?.thisWeekCheckList}
            evaluationPermission={data?.seeProjectCheckList?.evaluationPermission}
        />
    )
}

export default CheckListContainer;