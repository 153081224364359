import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { HugeText, LargeText, MediumText } from "../../../share/StyledText";
import { ThemeContext } from "../../../../context/ThemeProvider";
import { CheckListDetailContext } from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";
import goBack from "../../../../assets/icons/share/goBack.svg";
import "../../../share/FlexBox";
import FlexBox from "../../../share/FlexBox";
import { lightTheme } from "../../../../styles/theme";
import { useLazyQuery } from "@apollo/client";
import { SEE_CHECK_ITEM_LIST } from "../../../../graphql/Project/CheckList/query";
import Loader from "../../../share/Loader";

const Wrapper = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 5;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.3s;
  overflow-y: scroll;

  ${({ evaluatorDetailModal }) =>
    evaluatorDetailModal &&
    css`
      z-index: 10;
      top: 0;
      right: calc(100vw - 50%);
      transform: translateX(50%);
    `};
`;
const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  padding: ${({ theme }) =>
    theme.isApp ? "50px 20px 20px" : "30px 20px 20px"};
  background-color: ${({ theme }) => theme.whiteColor};
`;
const Contents = styled.div`
  padding: 0 20px 100px;
`;
const Title = styled(HugeText)`
  flex: 1;
  text-align: center;
  margin-right: 38px;
`;
const ColorText = styled(HugeText)`
  font-size: 18px;
  text-align: right;
  color: ${({ fontColor }) => fontColor};
  white-space: nowrap;
  margin-left: 4px;
`;
const IconBox = styled.div`
  width: 38px;
  height: 100%;
  display: flex;
  align-items: center;
`;
const Icon = styled.img``;
const ListBox = styled.div`
  padding: 18px 0;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const SubTitle = styled(LargeText)`
  line-height: 1.2;
  word-break: break-all;
`;
const Number = styled.div`
  font-size: 18px;
  padding-top: 2px;
  margin-right: 8px;
`;
const InfoBox = styled.div`
  width: 100%;
`;
const ListTitle = styled(LargeText)`
  font-weight: 400;
  line-height: 1.2;
`;
const RegularText = styled(LargeText)`
  font-family: "AppleSDGothicNeoMedium";
  font-weight: 400;
`;
const WarningText = styled(LargeText)`
  font-family: "AppleSDGothicNeoMedium";
  font-weight: 400;
  color: ${({ theme }) => theme.negativeColor};
  margin-top: 18px;
`;
const GrayText = styled(MediumText)`
  line-height: 1.4;
  color: ${({ theme }) => theme.grayTextColor};
`;

const EvaluatorDetailModal = () => {
  const { language } = useContext(ThemeContext);
  const { evaluatorDetailModal, setEvaluatorDetailModal, wscId } = useContext(
    CheckListDetailContext
  );
  const [fontColor, setFontColor] = useState(lightTheme.checkedColor);

  const [seeCheckItemList, { data, loading, refetch }] =
    useLazyQuery(SEE_CHECK_ITEM_LIST);

  useEffect(() => {
    if (wscId !== null) {
      (async () => {
        try {
          await seeCheckItemList({
            variables: {
              wscId: wscId,
            },
            fetchPolicy: "network-only",
          });
        } catch (e) {
          console.info(e);
        }
      })();
    }
  }, [wscId]);

  useEffect(() => {
    console.info(data?.seeCheckItemList);
    if (80 <= data?.seeCheckItemList?.scComplyRate) {
      setFontColor(lightTheme.checkedColor);
    } else if (50 <= data?.seeCheckItemList?.scComplyRate) {
      setFontColor(lightTheme.darkYellow);
    } else {
      setFontColor(lightTheme.negativeColor);
    }
  }, [data]);

  if (loading) return <Loader height="100vh" />;

  return (
    <Wrapper evaluatorDetailModal={evaluatorDetailModal}>
      <Header>
        <FlexBox>
          <IconBox>
            <Icon
              src={goBack}
              onClick={() => {
                setEvaluatorDetailModal(false);
                document.body.style.overflow = "scroll";
              }}
            />
          </IconBox>
          <Title>
            {language === "Kor" && "세부 평가 내용"}
            {language === "Eng" && "Detailed Evaluation Contents"}
          </Title>
        </FlexBox>
        <FlexBox justifyContent="space-between" margin="30px 0 0">
          <SubTitle>
            {language === "Kor" && data?.seeCheckItemList?.safetyCategoryKr}
            {language === "Eng" && data?.seeCheckItemList?.safetyCategoryEng}
          </SubTitle>
          <ColorText fontColor={fontColor}>
            {data?.seeCheckItemList?.scComplyRate}%{" "}
            {language === "Kor" && "준수"}
            {language === "Eng" && "Comply"}
          </ColorText>
        </FlexBox>
      </Header>
      <Contents>
        {data?.seeCheckItemList?.prjWeeklyCheckItemList.map((item, index) => (
          <ListBox key={item.wci_id}>
            <Number>{String.fromCharCode(index + 97)}.</Number>
            <InfoBox>
              <ListTitle>
                {language === "Kor" && item?.wci_checkItem_kr}
                {language === "Eng" && item?.wci_checkItem_eng}
              </ListTitle>
              <FlexBox justifyContent="space-between" margin="18px 0 0">
                <RegularText>
                  {language === "Kor" && "3점 만점 중"}
                  {language === "Eng" && "Out of 3 points"}
                </RegularText>
                <LargeText>
                  {(language === "Kor" &&
                    `${
                      item?.wci_score === 999 ? "N/A" : item?.wci_score
                    } 점`) ||
                    (language === "Eng" &&
                      `${
                        item?.wci_score === 999 ? "N/A" : item?.wci_score
                      } Point`)}
                </LargeText>
              </FlexBox>
              {item.wci_comment !== "" && (
                <FlexBox margin="18px 0 0">
                  <GrayText>{item.wci_comment}</GrayText>
                </FlexBox>
              )}
              {item?.wci_repeatedViolation && (
                <WarningText>
                  {language === "Kor" && "반복위반"}
                  {language === "Eng" && "Repeated "}
                </WarningText>
              )}
            </InfoBox>
          </ListBox>
        ))}
      </Contents>
    </Wrapper>
  );
};

export default EvaluatorDetailModal;
