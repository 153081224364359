import React, {useCallback, useContext, useEffect, useState} from "react";
import AddMembersPresenter from "./AddMembersPresenter";
import {useLocation} from "react-router-dom";
import {ThemeContext} from "../../../context/ThemeProvider";
import {autoHyphen, removeHyphen} from "../../../utils/commons";
import {ADD_PROJECT_MEMBER} from "../../../graphql/Project/AddMembers/mutation";
import {useMutation, useQuery} from "@apollo/client";
import {groupChanger} from "../../../lib/ProfileUtils";
import {CREATE_PROJECT} from "../../../graphql/Project/CreateProject/mutation";
import {useNavigate} from "react-router-dom";
import {SEE_MY_SIGN_PROJECT} from "../../../graphql/Project/ProjectList/query";

const AddMembersContainer = () => {
    const {state} = useLocation();
    const navigator = useNavigate();
    const {language} = useContext(ThemeContext);
    const [memberList, setMemberList] = useState([]);
    const [inputs, setInputs] = useState({
        affiliation: (language === 'Kor' && 'PM현장') || (language === 'Eng' && 'PM'),
        type: "연락처",
        email: "",
        phoneNumber: "",
        countryCode: (language === 'Kor' && '+82') || (language === 'Eng' && '+1')
    });

    const {refetch: prjRefetch} = useQuery(SEE_MY_SIGN_PROJECT);

    const [addProjectMember] = useMutation(ADD_PROJECT_MEMBER);
    const [createProject] = useMutation(CREATE_PROJECT);

    const onChangeInput = useCallback((e) => {
        const {name, value} = e.target;

        if (name === 'phoneNumber') {
            setInputs({
                ...inputs,
                [name]: value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'),
            });
        } else {
            setInputs({
                ...inputs,
                [name]: value,
            });
        }
    }, [inputs]);

    const includesMemberChecker = useCallback(() => {
        const { phoneNumber, email, type } = inputs;

        return memberList.find(member => {
            if (type === '연락처'){
                return member.user_cellphone === removeHyphen(phoneNumber);
            } else if (type === '이메일') {
                return member.user_email === email;
            }
        });
    }, [inputs, memberList]);

    const addMember = useCallback(async () => {
        if (inputs.email === "" && inputs.phoneNumber === "") {
            alert(
                (language === 'Kor' && '휴대폰번호나 이메일을 입력해주세요.') ||
                (language === 'Eng' && 'Please enter your cell phone number or email')
            );
            return;
        }
        if (includesMemberChecker()) {
            alert(
                (language === 'Kor' && '이미 추가하신 구성원입니다.') ||
                (language === 'Eng' && 'Member you have already added')
            );
            return;
        }

        try {
            const {data} = await addProjectMember({
                variables: {
                    group: groupChanger(inputs.affiliation, language),
                    emailPhone: inputs.type === "연락처",
                    countryCode: inputs.countryCode,
                    term: inputs.type === "연락처" ? removeHyphen(inputs.phoneNumber) : inputs.email,
                },
            });

            if (data?.addProjectMember?.result) {
                alert((language === 'Kor' && '구성원을 추가하였습니다.') || (language === 'Eng' && 'Added members successfully'));
                setMemberList([
                    ...memberList,
                    {
                        user_id: data.addProjectMember.inviMemberInfo.user_id,
                        user_name: data.addProjectMember.inviMemberInfo.user_name,
                        user_groupCompany: data.addProjectMember.inviMemberInfo.user_groupCompany,
                        user_group: data.addProjectMember.inviMemberInfo.user_group,
                        user_profileImg: data.addProjectMember.inviMemberInfo.user_profileImg,
                        user_countryCode: data.addProjectMember.inviMemberInfo.user_countryCode,
                        user_cellphone: data.addProjectMember.inviMemberInfo.user_cellphone,
                        user_email: data.addProjectMember.inviMemberInfo.user_email
                    },
                ]);
                setInputs({
                    affiliation: (language === 'Kor' && 'PM현장') || (language === 'Eng' && 'PM'),
                    type: "연락처",
                    email: "",
                    phoneNumber: "",
                    countryCode: (language === 'Kor' && '+82') || (language === 'Eng' && '+1')
                });
            }
            if (data?.addProjectMember?.error !== "") {
                alert(data?.addProjectMember?.error);
            }
        } catch (e) {
            alert(e.message);
        } finally {
            setInputs({
                ...inputs,
                phoneNumber: '',
                email: '',
            });
        }
    }, [inputs, memberList]);

    const delMember = useCallback(async (index) => {
        memberList.splice(index, 1);
        setMemberList([...memberList]);
        alert((language === 'Kor' && '구성원을 삭제하였습니다.') || (language === 'Eng' && 'Member deleted successfully'));
        // await prjDetailRefetch();
    }, [memberList]);

    const handleCreate = useCallback(async () => {
        try {
            let variables;

            if (!state?.projectInfo?.imgFile) {
                variables = {
                    prj_openAvailable: !state.projectInfo.nonDisclosure,
                    prj_title: state.projectInfo.name,
                    prj_address: state.projectInfo.address + " " + state.projectInfo.detailAddress,
                    prj_startDate: state.projectInfo.period[0],
                    prj_endDate: state.projectInfo.period[1],
                    prj_number: state.projectInfo.documentNumber,
                    selectImg: false,
                    prj_members: memberList,
                };
            } else {
                variables = {
                    prj_openAvailable: !state.projectInfo.nonDisclosure,
                    prj_title: state.projectInfo.name,
                    prj_address: state.projectInfo.address + " " + state.projectInfo.detailAddress,
                    prj_startDate: state.projectInfo.period[0],
                    prj_endDate: state.projectInfo.period[1],
                    prj_number: state.projectInfo.documentNumber,
                    selectImg: true,
                    prj_img: state.projectInfo.imgFile,
                    prj_members: memberList,
                };
            }
            const {data} = await createProject({
                variables,
            });

            if (data?.createProject?.result) {
                alert((language === 'Kor' && '프로젝트를 생성하였습니다.') || (language === 'Eng' && 'Project created successfully'));
                await prjRefetch();
                navigator("/");
                localStorage.removeItem('projectInfo');
            }
            if (data?.createProject?.error !== "") {
                alert(data.createProject.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [state, memberList]);

    return (
        <AddMembersPresenter
            language={language}
            inputs={inputs}
            onChangeInput={onChangeInput}
            memberList={memberList}
            addMember={addMember}
            delMember={delMember}
            handleCreate={handleCreate}
        />
    );
};

export default AddMembersContainer;
