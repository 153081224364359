import React, {useCallback, useContext} from "react";
import styled, {css} from "styled-components";
import {LargeText, SmallText} from "../../share/StyledText";
import {useNavigate} from "react-router-dom";
import locationIcon from "../../../assets/icons/project/location.svg";
import {dateFormat} from "../../../utils/commons";
import {prjStatusChanger} from "../../../lib/ProjectUtils";
import emptyImage from "../../../assets/images/emptyImage.jpeg";
import {ThemeContext} from "../../../context/ThemeProvider";

const Wrapper = styled.div`
  height: 96px;
  display: flex;

  & + & {
    margin-top: 8px;
  }

  ${({APPROVAL}) => APPROVAL && css`
    height: 96px;
    align-items: center;
  `};
`;
const Image = styled.img`
  width: 86px;
  height: 86px;
  border-radius: ${({APPROVAL}) => (APPROVAL ? "50%" : "10px")};
  object-fit: cover;
  flex-shrink: 0;
`;
const InfoBox = styled.div`
  width: calc(100% - 86px);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  flex-shrink: 0;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  
  ${({theme, APPROVAL}) => APPROVAL && css`
    border-bottom: none;
    padding: 0;
  `};
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) =>
          flexDirection ? flexDirection : "row"};
  align-items: ${({alignItems}) => (alignItems ? alignItems : "flex-start")};
  justify-content: ${({justifyContent}) =>
          justifyContent ? justifyContent : "flex-start"};
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const ProjectTitle = styled(LargeText)`
  max-width: 168px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
`;
const LocText = styled(SmallText)`
  max-width: 134px;
  color: ${({theme}) => theme.subTextColor};
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const LocImage = styled.img``;

const StatusText = styled(SmallText)`
  font-family: "AppleSDGothicNeoBold", "Apple SD Gothic Neo", sans-serif;
  color: ${({theme, active}) =>
          active ? theme.activeColor : theme.subTextColor};
  position: absolute;
  right: 16px;
`;
const ScarCount = styled.div`
  width: 80px;
  height: 26px;
  color: ${({theme}) => theme.whiteColor};
  font-family: "AppleSDGothicNeoBold", "Apple SD Gothic Neo", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  background-color: ${({theme}) => theme.activeColor};
  white-space: nowrap;
  flex-shrink: 0;
  position: absolute;
  right: 16px;
`;

const ProjectInfo = ({prjData, APPROVAL}) => {
    const navigate = useNavigate();
    const {language} = useContext(ThemeContext);

    const onClickDetail = useCallback(() => {
        navigate("/project-detail");
        localStorage.setItem("prjId", prjData?.prj_id)
    }, [prjData]);

    return (
        <Wrapper APPROVAL={APPROVAL} onClick={onClickDetail}>
            <Image
                src={prjData?.prj_img === "" ? emptyImage : prjData?.prj_img}
                APPROVAL={APPROVAL}
            />
            <InfoBox APPROVAL={APPROVAL}>
                <FlexBox flexDirection="column">
                    <ProjectTitle>{prjData?.prj_title}</ProjectTitle>
                    <FlexBox margin="8px 0">
                        <LocImage src={locationIcon}/>{" "}
                        <LocText>{prjData?.prj_address}</LocText>
                    </FlexBox>
                    <SmallText>
                        {dateFormat(prjData?.prj_startDate, "yyyy.MM.dd")} -{" "}
                        {dateFormat(prjData?.prj_endDate, "yyyy.MM.dd")}
                    </SmallText>
                </FlexBox>
                {APPROVAL ? (
                    <ScarCount>SCAR + {prjData?.scarCount}</ScarCount>
                ) : (
                    <StatusText active={prjData?.prj_status === "inProgress"}>
                        {prjStatusChanger(prjData?.prj_status, language)}
                    </StatusText>
                )}
            </InfoBox>
        </Wrapper>
    );
};

export default React.memo(ProjectInfo);
