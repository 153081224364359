import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {HugeText, SmallText} from "../../../components/share/StyledText";
import StyledInput from "../../../components/share/StyledInput";
import FlexBox from "../../../components/share/FlexBox";
import exclamationGrayIcon from "../../../assets/icons/myPage/exclamationGrayIcon.svg";
import StyledButton from "../../../components/share/StyledButton";

const Wrapper = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
`;
const Header = styled.div`
  margin-bottom: 40px;
`;
const Icon = styled.img`
  margin-right: 6px;
`;
const GrayText = styled(SmallText)`
  color: ${({theme}) => theme.subTextColor};
`;

const EditMyProfilePresenter = ({
                                    language = "Kor",
                                    inputs,
                                    onChangeInput,
                                    editProfile,
                                }) => {
    return (
        <Wrapper>
            <Header>
                <GoBack/>
                <HugeText>
                    {language === "Kor" && "원하시는 정보를 변경 후"}
                    {language === "Eng" && "Please change the information you want"}
                </HugeText>
                <HugeText margin="10px 0 0">
                    {language === "Kor" && "저장해주세요"}
                    {language === "Eng" && "and save it"}
                </HugeText>
            </Header>
            <FlexBox>
                <StyledInput
                    label={
                        (language === "Kor" && "이름") || (language === "Eng" && "Name")
                    }
                    width="calc((100vw - 32px) / 2)"
                    name="name"
                    value={inputs.name}
                    onChange={onChangeInput}
                    maxLength={30}
                />
                <StyledInput
                    label={
                        (language === "Kor" && "직책") || (language === "Eng" && "Position")
                    }
                    width="calc((100vw - 32px) / 2)"
                    name="position"
                    value={inputs.position}
                    onChange={onChangeInput}
                    maxLength={30}
                />
            </FlexBox>

            <FlexBox margin="14px 0 10px">
                <StyledInput
                    label={
                        (language === "Kor" && "소속") ||
                        (language === "Eng" && "Affiliation")
                    }
                    name="affiliation"
                    value={inputs.affiliation}
                    onChange={onChangeInput}
                    disabled
                />
            </FlexBox>

            <FlexBox alignItems="center">
                <Icon src={exclamationGrayIcon}/>
                <GrayText>
                    {language === "Kor" && "소속 변경시 관리자에게 문의주세요."}
                    {language === "Eng" &&
                    "Please contact the manager when you change your affiliation"}
                </GrayText>
            </FlexBox>

            <FlexBox margin="20px 0 50px">
                <StyledInput
                    label={
                        (language === "Kor" && "소속회사명") ||
                        (language === "Eng" && "Affiliation Company")
                    }
                    name="AffiliationCompany"
                    value={inputs.AffiliationCompany}
                    onChange={onChangeInput}
                />
            </FlexBox>

            <StyledButton
                title={
                    (language === "Kor" && "회원정보 변경하기") ||
                    (language === "Eng" && "Change my info")
                }
                onClick={editProfile}
            />
        </Wrapper>
    );
};

export default React.memo(EditMyProfilePresenter);
