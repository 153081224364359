import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {HugeText} from "../../../components/share/StyledText";
import manual_1 from "../../../assets/manual/01.png";
import manual_2 from "../../../assets/manual/02.png";
import manual_3 from "../../../assets/manual/03.png";
import manual_4 from "../../../assets/manual/04.png";
import manual_4_1 from "../../../assets/manual/04 – 1.png";
import manual_5 from "../../../assets/manual/05.png";
import manual_6 from "../../../assets/manual/06.png";
import manual_6_1 from "../../../assets/manual/06 – 1.png";
import manual_7 from "../../../assets/manual/07.png";
import manual_8 from "../../../assets/manual/08.png";
import manual_9 from "../../../assets/manual/09.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 130px 20px 20px;
`;
const Header = styled.div`
  width: 100%;
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({theme}) => theme.bgColor};
`;
const Contents = styled.div`
  
`;
const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ManualPresenter = ({ language }) => {
    return (
        <Wrapper>
            <Header>
                <GoBack />
                <HugeText margin="16px 0 10px">
                    {language === "Kor" && "사용법 보기"}
                    {language === "Eng" && "Manual"}
                </HugeText>
            </Header>
            <Contents>
                <Image src={manual_1} />
                <Image src={manual_2} />
                <Image src={manual_3} />
                <Image src={manual_4} />
                <Image src={manual_4_1} />
                <Image src={manual_5} />
                <Image src={manual_6} />
                <Image src={manual_6_1} />
                <Image src={manual_7} />
                <Image src={manual_8} />
                <Image src={manual_9} />
            </Contents>
        </Wrapper>
    )
}

export default React.memo(ManualPresenter);