import React, {forwardRef, useContext, useEffect} from "react";
import styled, {css} from "styled-components";
import {SmallText} from "./StyledText";
import DatePicker from "react-datepicker";
import {ko} from 'date-fns/esm/locale';
import "react-datepicker/dist/react-datepicker.css";
import {darkTheme, lightTheme} from "../../styles/theme";
import {ThemeContext} from "../../context/ThemeProvider";
import CalendarHeader from "./CalendarHeader";

const Wrapper = styled.div`
  width: ${({width}) => width};
  margin: ${({margin}) => margin};
`;
const InputBox = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  position: relative;

  input {
    width: 100%;
    font-size: 18px;
  }
`;
const Placeholder = styled.div`
  width: 100%;
  font-size: 18px;
  padding: 2px 0 10px;
  color: ${({theme}) => theme.subTextColor};
  background-color: ${({theme}) => theme.bgColor};

  ${({active}) => active && css`
    color: ${({theme}) => theme.defaultTextColor};
  `};
`;

const CustomInput = forwardRef(({value, onClick}, ref) => {
    const {language} = useContext(ThemeContext);

    return (
        <Placeholder onClick={onClick} ref={ref} active={!(value.split(" - ")[0] === value.split(" - ")[1])}>
            {value.split(" - ")[0] === value.split(" - ")[1]
                ? (
                    (language === 'Kor' && '기간을 입력해주세요.') ||
                    (language === 'Eng' && 'Please enter the period')
                )
                : value}
        </Placeholder>
    )
});

const StyledCalendar = ({
                            width = "100%",
                            margin = 0,
                            name = "period",
                            value = [],
                            onChange = () => null,
                            label = "",
                        }) => {
    const {themeMode} = useContext(ThemeContext);
    const [startDate, endDate] = value;

    useEffect(() => { // 달력 선택 시 키보드창 열리지 않도록 설정
        // const input = document.querySelector(".react-datepicker-wrapper input");
        // input.setAttribute("inputMode", "none");
    }, []);

    return (
        <Wrapper width={width} margin={margin}>
            {label && (
                <SmallText
                    margin="0 0 14px"
                    fontColor={themeMode === "light" ? lightTheme.subTextColor : darkTheme.subTextColor}
                >
                    {label}
                </SmallText>
            )}
            <InputBox>
                <DatePicker
                    locale={ko}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy.MM.dd"
                    onChange={dates => onChange(name, dates)}
                    // popperModifiers={{ // 모바일 web 환경에서 화면을 벗어나지 않도록 하는 설정
                    //     preventOverflow: {
                    //         enabled: true,
                    //     },
                    // }}
                    customInput={<CustomInput/>}
                    renderCustomHeader={({ // 달력 커스텀 헤더
                                             date,
                                             changeYear,
                                             changeMonth,
                                             decreaseMonth,
                                             increaseMonth,
                                             prevMonthButtonDisabled,
                                             nextMonthButtonDisabled,
                                         }) => (
                        <CalendarHeader
                            date={date}
                            changeYear={changeYear}
                            changeMonth={changeMonth}
                            decreaseMonth={decreaseMonth}
                            increaseMonth={increaseMonth}
                            prevMonthButtonDisabled={prevMonthButtonDisabled}
                            nextMonthButtonDisabled={nextMonthButtonDisabled}
                        />
                    )}
                />
            </InputBox>
        </Wrapper>
    )
}

export default React.memo(StyledCalendar);