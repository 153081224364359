import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import StyledButton from "../../../components/share/StyledButton";
import {
    HugeText,
    LargeText,
    MediumText,
} from "../../../components/share/StyledText";
import StyledInput from "../../../components/share/StyledInput";
import ProfileImage from "../../../components/share/ProfileImage";
import nonMember from "../../../assets/icons/project/nonMember.svg";
import StyledSelect from "../../../components/share/StyledSelect";
import FlexBox from "../../../components/share/FlexBox";
import exportIcon from "../../../assets/icons/project/exportIcon.svg";
import {engGroupChanger, groupChanger} from "../../../lib/ProfileUtils";
import countryCode from "../../../utils/countryCode";

const Wrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Contents = styled.div`
  flex: 8;
`;
const UserList = styled.div`
  height: calc(100% - 280px);
  padding-bottom: 20px;
  overflow-y: scroll;
`;
const UserBox = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const ProfileBox = styled.div`
  display: flex;
  align-items: center;
`;
const InfoBox = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  flex: 1;
`;
const UserName = styled(LargeText)`
  max-width: 200px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const GrayText = styled(MediumText)`
  color: ${({theme}) => theme.subTextColor};
`;
const ButtonBox = styled.div`
`;
const Icon = styled.img`
  margin-top: 4px;
`;

const AddMembersPresenter = ({
                                 language = "Kor",
                                 inputs,
                                 onChangeInput,
                                 memberList,
                                 addMember,
                                 delMember,
                                 handleCreate,
                             }) => {
    return (
        <Wrapper>
            <Contents>
                <GoBack/>
                <HugeText>
                    {language === "Kor" && "구성원 추가하기"}
                    {language === "Eng" && "Invite Project Members"}
                </HugeText>
                <FlexBox justifyContent="space-between" margin="26px 0 0">
                    <StyledSelect
                        label={(language === "Kor" && "소속") || (language === "Eng" && "")}
                        width="calc((100% - 16px) / 2)"
                        options={
                            (language === "Kor" && ["PM현장", "시공사", "발주처"]) ||
                            (language === "Eng" && ["PM", "Contractor", "Client"])
                        }
                        name="affiliation"
                        value={inputs.affiliation}
                        onChange={onChangeInput}
                    />
                    <StyledSelect
                        label={(language === "Kor" && "분류") || (language === "Eng" && "")}
                        width="calc((100% - 16px) / 2)"
                        options={
                            (language === "Kor" && ["연락처", "이메일"]) ||
                            (language === "Eng" && ["Phone number", "Email"])
                        }
                        name="type"
                        value={inputs.type}
                        onChange={onChangeInput}
                    />
                </FlexBox>
                <FlexBox
                    margin="26px 0 10px"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    {inputs.type === "연락처" ? (
                        <>
                            <StyledSelect
                                label={language === 'Kor' && '국가' || language === 'Eng' && 'Country Code'}
                                width="30%"
                                options={countryCode}
                                name="countryCode"
                                value={inputs.countryCode}
                                onChange={onChangeInput}
                            />
                            <StyledInput
                                PLUS_BUTTON
                                label={
                                    (language === "Kor" && "연락처로 추가하기*") ||
                                    (language === "Eng" && "Invite With phone number or email*")
                                }
                                width="calc(70% - 16px)"
                                name="phoneNumber"
                                type="tel"
                                value={inputs.phoneNumber}
                                onChange={onChangeInput}
                                onCreate={addMember}
                            />
                        </>
                    ) : (
                        <StyledInput
                            PLUS_BUTTON
                            label={
                                (language === "Kor" && "메일로 추가하기*") ||
                                (language === "Eng" && "Add By phone number or Email*")
                            }
                            name="email"
                            value={inputs.email}
                            onChange={onChangeInput}
                            onCreate={addMember}
                        />
                    )}
                </FlexBox>
                <UserList>
                    {memberList?.map((member, index) => (
                        <UserBox key={index + 1}>
                            <ProfileBox>
                                <ProfileImage
                                    width={48}
                                    height={48}
                                    src={
                                        member.user_profileImg !== ""
                                            ? member.user_profileImg
                                            : nonMember
                                    }
                                />
                                <InfoBox>
                                    <UserName>{member.user_name} {language === 'Kor' && '님'}</UserName>
                                    <GrayText>
                                        {member.user_groupCompany}&nbsp;
                                        ({
                                            (language === 'Kor' && groupChanger(member.user_group)) ||
                                            (language === 'Eng' && engGroupChanger(member.user_group))
                                        })
                                    </GrayText>
                                </InfoBox>
                            </ProfileBox>
                            <FlexBox
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <GrayText>
                                    {member.user_name === "미가입자" ? "미가입자" : "가입자"}
                                </GrayText>
                                <Icon src={exportIcon} onClick={() => delMember(index)}/>
                            </FlexBox>
                        </UserBox>
                    ))}
                </UserList>
            </Contents>
            <ButtonBox>
                <StyledButton
                    title={
                        (language === "Kor" && "프로젝트 생성하기") ||
                        (language === "Eng" && "Create Project")
                    }
                    margin="0 0 40px"
                    // onClick={handleAddMembers}
                    // disabled={buttonStatus}
                    onClick={handleCreate}
                />
            </ButtonBox>
        </Wrapper>
    );
};

export default React.memo(AddMembersPresenter);
