import React, { useState } from "react";
import styled, { css } from "styled-components";
import { MediumText } from "../../share/StyledText";
import questionIcon from "../../../assets/icons/serviceCenter/questionIcon.svg";
import grayQuestion from "../../../assets/icons/serviceCenter/grayQuestion.svg";
import selectBottomArrow from "../../../assets/icons/share/selectBottomArrow.svg";

const Wrapper = styled.div`
  margin-bottom: 13px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
`;
const Icon = styled.img`
  margin-right: 10px;
`;
const Arrow = styled.img`
  transition: 0.3s;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `};
`;
const QnaTitle = styled(MediumText)`
  word-break: break-all;
`;
const AnswerBox = styled.div`
  height: 0;
  font-size: 16px;
  line-height: 1.2;
  transition: 0.3s;
  overflow: hidden;

  ${({ open }) =>
    open &&
    css`
      height: auto;
      min-height: 35px;
      margin-bottom: 16px;
    `};
`;

const QnaRow = ({ qna }) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper onClick={() => qna.one_answer && setOpen(!open)}>
      <TitleBox>
        <Title>
          <Icon src={qna.one_answer ? questionIcon : grayQuestion} />
          <QnaTitle>{qna.one_question}</QnaTitle>
        </Title>
        <Arrow open={open} src={selectBottomArrow} />
      </TitleBox>
      {qna.one_answer && (
        <AnswerBox open={open}>{qna.one_answerText}</AnswerBox>
      )}
    </Wrapper>
  );
};

export default React.memo(QnaRow);
