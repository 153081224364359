import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {HugeText, MediumText} from "../../../components/share/StyledText";
import {dateFormat} from "../../../utils/commons";

const Wrapper = styled.div`
  //height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '150px 20px 20px' : '130px 20px 20px'};
`;
const Header = styled.div`
  width: 100%;
  position: fixed;
  padding: ${({ theme }) => theme.isApp ? '40px 20px 20px' : '20px'};
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({theme}) => theme.bgColor};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const NoticeRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const NoticeTitle = styled(MediumText)`
  max-width: 75%;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
`;
const GrayText = styled(MediumText)`
  color: ${({theme}) => theme.subTextColor};
`;

const NoticePresenter = ({language = "Kor", navigate, noticeList}) => {
    return (
        <Wrapper>
            <Header>
                <GoBack route='/service-center'/>
                <HugeText margin="16px 0 10px">
                    {language === "Kor" && "공지사항"}
                    {language === "Eng" && "Notice"}
                </HugeText>
            </Header>

            <Contents>
                {noticeList?.map((notice, index) => (
                    <NoticeRow
                        key={index}
                        onClick={() => navigate("/notice-detail", {state: {notice}})}
                    >
                        <NoticeTitle>{notice?.noti_title}</NoticeTitle>
                        <GrayText>
                            {dateFormat(notice?.noti_createdAt, "yyyy.MM.dd")}
                        </GrayText>
                    </NoticeRow>
                ))}
            </Contents>
        </Wrapper>
    );
};

export default React.memo(NoticePresenter);
