import React from "react";
import styled, { css } from "styled-components";
import StyledInput from "../../components/share/StyledInput";
import StyledSelect from "../../components/share/StyledSelect";
import StyledButton from "../../components/share/StyledButton";
import { darkTheme, lightTheme } from "../../styles/theme";
import {
  HugeText,
  MediumText,
  SmallText,
} from "../../components/share/StyledText";
import countryCode from "../../utils/countryCode";
import { onlyNumber } from "../../utils/commons";

const Wrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  padding: ${({ theme }) => (theme.isApp ? "80px 20px" : "70px 20px")};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Contents = styled.div`
  flex: 1;
`;
const FlexBox = styled.div`
  display: flex;
  margin: 40px 0;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ margin }) => (margin ? margin : "")};
`;
const LanguageBox = styled.div`
  margin-top: 14px;
  text-align: center;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.subTextColor};

  ${({ active }) =>
    active &&
    css`
      font-weight: 600;
      color: ${({ theme }) => theme.defaultTextColor};
    `};
`;

const LoginPresenter = ({
  themeMode,
  inputs,
  onChange,
  handleLogin,
  handleSignUp,
  language,
  handleChangeLanguage,
  handleLoginType,
}) => {
  return (
    <Wrapper>
      {language === "Kor" && (
        <>
          <HugeText>반가워요,</HugeText>
          <HugeText margin="10px 0 26px">리스크제로HG입니다.</HugeText>
        </>
      )}
      {language === "Eng" && (
        <HugeText margin="10px 0 26px">Welcome to RiskZero HG</HugeText>
      )}
      <SmallText
        fontColor={
          themeMode === "light"
            ? lightTheme.subTextColor
            : darkTheme.subTextColor
        }
      >
        {language === "Kor" && "가입하신 휴대폰 번호를 입력해주세요."}
        {language === "Eng" && "Sign in with your phone number."}
      </SmallText>

      <Contents>
        {inputs?.type === "PHONE" ? (
          <FlexBox>
            <StyledSelect
              label={
                (language === "Kor" && "국가") ||
                (language === "Eng" && "Country Code")
              }
              width={
                (language === "Kor" && "30%") || (language === "Eng" && "35%")
              }
              options={countryCode}
              name="countryCode"
              value={inputs.countryCode}
              onChange={onChange}
            />
            <StyledInput
              label={
                (language === "Kor" && "휴대폰번호") ||
                (language === "Eng" && "Phone Number")
              }
              type="tel"
              margin="0 0 0 16px"
              maxLEngth={13}
              name="phoneNumber"
              value={onlyNumber(inputs.phoneNumber)}
              onChange={onChange}
            />
          </FlexBox>
        ) : null}
        {inputs?.type === "EMAIL" ? (
          <FlexBox>
            <StyledInput
              label={
                (language === "Kor" && "이메일 주소") ||
                (language === "Eng" && "E-Mail Address")
              }
              type="text"
              margin="0 0 0 16px"
              name="email"
              value={inputs.email}
              onChange={onChange}
            />
          </FlexBox>
        ) : null}
        <StyledButton
          title={
            (language === "Kor" && "로그인하기") ||
            (language === "Eng" && "Sign In")
          }
          onClick={handleLogin}
        />
      </Contents>
      <Footer
        onClick={() =>
          handleLoginType(inputs.type === "PHONE" ? "EMAIL" : "PHONE")
        }
      >
        <MediumText
          fontColor={
            themeMode === "light"
              ? lightTheme.subTextColor
              : darkTheme.subTextColor
          }
        >
          {inputs?.type === "PHONE" ? (
            <>
              {language === "Kor" && "이메일주소로 로그인"}
              {language === "Eng" && "Login with e-mail"}
            </>
          ) : null}

          {inputs?.type === "EMAIL" ? (
            <>
              {language === "Kor" && "휴대폰번호로 로그인"}
              {language === "Eng" && "Login with phone"}
            </>
          ) : null}
        </MediumText>
      </Footer>
      <Footer margin="14px 0 0 0">
        <MediumText
          fontColor={
            themeMode === "light"
              ? lightTheme.subTextColor
              : darkTheme.subTextColor
          }
        >
          {language === "Kor" && "가입하지 않으셨나요?"}
          {language === "Eng" && `Don't have an account?`}
        </MediumText>
        &nbsp;
        <MediumText fontWeight={600} onClick={handleSignUp}>
          {language === "Kor" && "회원가입하기"}
          {language === "Eng" && "Sign Up"}
        </MediumText>
      </Footer>
      <LanguageBox>
        <Text
          onClick={() => handleChangeLanguage("Kor")}
          active={language === "Kor"}
        >
          KOR
        </Text>
        &nbsp;|&nbsp;
        <Text
          onClick={() => handleChangeLanguage("Eng")}
          active={language === "Eng"}
        >
          ENG
        </Text>
      </LanguageBox>
    </Wrapper>
  );
};

export default React.memo(LoginPresenter);
