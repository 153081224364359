import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { ProjectDetailContext } from "../../../pages/Project/ProjectDetail/ProjectDetailContainer";
import FlexBox from "../../share/FlexBox";
import ProfileImage from "../../share/ProfileImage";
import { HugeText, MediumText, SmallText } from "../../share/StyledText";
import { ThemeContext } from "../../../context/ThemeProvider";
import callIcon from "../../../assets/icons/project/callIcon.svg";
import goBack from "../../../assets/icons/share/goBack.svg";
import { groupChanger } from "../../../lib/ProfileUtils";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: -1;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.3s;

  ${({ userProfileModal }) =>
    userProfileModal &&
    css`
      z-index: 10;
      top: 0;
      right: 0;
    `};
`;
const Header = styled.div`
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  position: sticky;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
const BackIcon = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;
const Image = styled.img``;
const GrayText = styled(MediumText)`
  color: ${({ theme }) => theme.subTextColor};
`;
const Contents = styled.div`
  padding: 26px 20px;
`;
const InfoBox = styled.div`
  margin-top: 24px;
`;
const SmallGrayText = styled(SmallText)`
  color: ${({ theme }) => theme.subTextColor};
`;
const InfoText = styled(MediumText)`
  margin-top: 14px;
`;
const CallPositionBox = styled(FlexBox)`
  position: relative;
`;
const Icon = styled.img`
  position: absolute;
  right: 0;
  bottom: -16px;
`;

const UserProfileModal = ({}) => {
  const { language } = useContext(ThemeContext);
  const { memberModalInfo, userProfileModal, setUserProfileModal } =
    useContext(ProjectDetailContext);

  return (
    <Wrapper userProfileModal={userProfileModal}>
      <Header>
        <BackIcon>
          <Image src={goBack} onClick={() => setUserProfileModal(false)} />
        </BackIcon>
        <FlexBox alignItems="center">
          <ProfileImage
            width={64}
            height={64}
            src={memberModalInfo?.user_profileImg}
          />
          <FlexBox flexDirection="column" margin="0 0 0 16px">
            <HugeText>{memberModalInfo?.user_name}</HugeText>
            <GrayText margin="6px 0 0">
              {groupChanger(memberModalInfo?.user_group, language)}{" "}
              {memberModalInfo?.user_groupCompany}
            </GrayText>
          </FlexBox>
        </FlexBox>
      </Header>
      <Contents>
        <MediumText margin="0 0 26px">
          {language === "Kor" && "프로필 정보"}
          {language === "Eng" && "Profile Info"}
        </MediumText>
        <InfoBox>
          <SmallGrayText>
            {language === "Kor" && "성함과 직책"}
            {language === "Eng" && "Position of office"}
          </SmallGrayText>
          <InfoText>{memberModalInfo?.user_name}</InfoText>
        </InfoBox>
        <InfoBox>
          <SmallGrayText>
            {language === "Kor" && "소속 및 소속회사명"}
            {language === "Eng" && "Affiliated and affiliated company name"}
          </SmallGrayText>
          <InfoText>
            {" "}
            {groupChanger(memberModalInfo?.user_group)}
            {memberModalInfo?.user_groupCompany !== "" && " - "}
            {memberModalInfo?.user_groupCompany}
          </InfoText>
        </InfoBox>
        <InfoBox>
          <SmallGrayText>
            {language === "Kor" && "연락처"}
            {language === "Eng" && "Phone number"}
          </SmallGrayText>
          <CallPositionBox>
            <InfoText>
              {memberModalInfo?.user_cellphone === "" ? (
                "-"
              ) : (
                <>
                  {memberModalInfo?.user_countryCode}{" "}
                  {memberModalInfo?.user_cellphone}
                </>
              )}
            </InfoText>
            <Icon
              src={callIcon}
              onClick={() =>
                (window.location.href = `tel:${memberModalInfo?.user_cellphone}`)
              }
            />
          </CallPositionBox>
        </InfoBox>
        <InfoBox>
          <SmallGrayText>
            {language === "Kor" && "메일주소"}
            {language === "Eng" && "E-mail"}
          </SmallGrayText>
          <InfoText>
            {memberModalInfo?.user_email !== ""
              ? memberModalInfo?.user_email
              : "-"}
          </InfoText>
        </InfoBox>
      </Contents>
    </Wrapper>
  );
};

export default React.memo(UserProfileModal);
