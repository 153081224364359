import React from "react";
import styled from "styled-components";
import deleteIcon from "../../assets/icons/project/deleteIcon.svg";

const ChipBox = styled.div`
  padding: 8px 16px;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.borderColor};
`;
const DeleteIcon = styled.img`
  position: absolute;
  top: -4px;
  right: -4px;
`;

const Chips = ({ chip, handleDeleteChip }) => {
    return (
        <ChipBox>
            {chip}
            <DeleteIcon src={deleteIcon} onClick={() => handleDeleteChip(chip)}/>
        </ChipBox>
    )
}

export default React.memo(Chips);