import React, {useContext, useEffect, useRef} from "react";
import styled, {css} from "styled-components";
import selectRightArrow from "../../assets/icons/share/selectRightArrow.svg";
import {SmallText} from "./StyledText";
import {darkTheme, lightTheme} from "../../styles/theme";
import {ThemeContext} from "../../context/ThemeProvider";

const Wrapper = styled.div`
  width: ${({width}) => width};
  margin: ${({margin}) => margin};
`;
const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.borderColor};
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  font-size: 18px;
  color: ${({theme}) => theme.defaultTextColor};
  padding: 12px;
  border: none;
  border-radius: 4px;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: ${({placeholderFontSize}) => placeholderFontSize}px;
    color: ${({theme}) => theme.subTextColor};
  }
`;

const StyledTextArea = ({
                            margin,
                            label,
                            commentModal,
                            value,
                            onChange,
                            maxLength = 500,
                            placeholder = '의견을 입력해주세요'
                        }) => {
    const {themeMode} = useContext(ThemeContext);
    const textareaRef = useRef(null);

    useEffect(() => {
        if (commentModal) {
            textareaRef.current.focus();
        } else {
            textareaRef.current.blur();
        }
    }, [commentModal]);

    return (
        <Wrapper margin={margin}>
            {label && (
                <SmallText
                    margin="0 0 14px"
                    fontColor={
                        themeMode === "light"
                            ? lightTheme.subTextColor
                            : darkTheme.subTextColor
                    }
                >
                    {label}
                </SmallText>
            )}
            <InputBox>
                <Textarea
                    autoFocus
                    ref={textareaRef}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                />
            </InputBox>
        </Wrapper>
    )
}

export default React.memo(StyledTextArea);
