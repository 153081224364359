import React, {useContext} from "react";
import styled from "styled-components";
import {getYear, getMonth} from "date-fns";
import bottomArrowIcon from "../../assets/icons/calendar/bottomArrowIcon.svg";
import {ThemeContext} from "../../context/ThemeProvider";
import {getYears} from "../../utils/commons";
import leftArrowIcon from "../../assets/icons/calendar/leftArrowIcon.svg";
import rightArrowIcon from "../../assets/icons/calendar/rightArrowIcon.svg";

const Header = styled.div`
  width: 100%;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Button = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({imgUrl}) => `url(${imgUrl}) no-repeat 50% 50%`};
`;
const Select = styled.select`
  width: ${({ width }) => width};
  height: 100%;
  padding-left: 6px;
  font-size: 16px;
  color: ${({ theme }) => theme.blackColor};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 6px;
  background: url(${bottomArrowIcon}) no-repeat 95% 50% white;
`;

const CalendarHeader = ({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => {
    const { language } = useContext(ThemeContext);
    const years = getYears(); // 기간 설정 년도 구하기
    const months = {
        Kor: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        Eng: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };

    return (
        <Header>
            <Button imgUrl={leftArrowIcon} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}/>
            <Select
                width="34%"
                value={getYear(date)}
                onChange={e => changeYear(e.target.value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
            <Select
                width="30%"
                value={months[language][getMonth(date)]}
                onChange={e => changeMonth(months[language].indexOf(e.target.value))}
            >
                {months[language].map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
            <Button imgUrl={rightArrowIcon} onClick={increaseMonth} disabled={nextMonthButtonDisabled}/>
        </Header>
    )
}

export default React.memo(CalendarHeader);