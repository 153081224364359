import React, {useContext} from "react";
import ManualPresenter from "./ManualPresenter";
import {ThemeContext} from "../../../context/ThemeProvider";

const ManualContainer = () => {
    const { language } = useContext(ThemeContext);

    return (
        <ManualPresenter
            language={language}
        />
    )
}

export default ManualContainer;