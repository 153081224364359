import React from "react";
import styled, { css } from "styled-components";
import {HugeText, SmallText} from "../../../components/share/StyledText";
import GoBack from "../../../components/share/GoBack";
import FlexBox from "../../../components/share/FlexBox";
import StyledInput from "../../../components/share/StyledInput";
import StyledButton from "../../../components/share/StyledButton";
import {darkTheme, lightTheme} from "../../../styles/theme";

const Wrapper = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
`;
const Header = styled.div`
  margin-bottom: 40px;
`;
const SendMailButton = styled.button`
  width: 56px;
  height: 34px;
  color: ${({theme}) => theme.activeColor};
  font-family: "AppleSDGothicNeoBold";
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.activeColor};
  
  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.subTextColor};
    border: 1px solid ${({theme}) => theme.subTextColor};
  `};
`;
const MailInputBox = styled(FlexBox)`
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
`;
const Message = styled.span`
  font-size: 14px;
  color: ${({theme, fontColor}) => fontColor ? fontColor : theme.activeColor};
  position: absolute;
  right: 0;
  top: 2px;
`;

const EditEmailPresenter = ({
                                language = "Kor",
                                inputs,
                                onChangeInput,
                                sendMail,
                                editEmail,
                                emailCheck,
                                themeMode,
                            }) => {
    return (
        <Wrapper>
            <Header>
                <GoBack/>
                <HugeText>
                    {language === "Kor" && "메일 주소 입력 후 수신된 6자리"}
                    {language === "Eng" && "Please enter your email address"}
                </HugeText>
                <HugeText margin="10px 0">
                    {language === "Kor" && "코드인증을 완료해주세요"}
                    {language === "Eng" && "complete the 6 digit code"}
                </HugeText>
                {language === "Eng" && <HugeText>authentication received</HugeText>}
            </Header>

            <MailInputBox>
                <StyledInput
                    label={
                        (language === "Kor" && "메일") || (language === "Eng" && "Email")
                    }
                    width="70%"
                    name="email"
                    value={inputs.email}
                    onChange={onChangeInput}
                />

                {emailCheck === "valid" && <Message>올바른 메일 주소입니다.</Message>}
                {emailCheck === "invalid" && (
                    <Message
                        fontColor={
                            themeMode === "light"
                                ? lightTheme.negativeColor
                                : darkTheme.negativeColor
                        }
                    >
                        메일 양식을 확인해주세요.
                    </Message>
                )}

                <SendMailButton onClick={sendMail} disabled={emailCheck !== 'valid'}>
                    {language === "Kor" && "전송"}
                    {language === "Eng" && "Send"}
                </SendMailButton>
            </MailInputBox>

            <FlexBox margin="14px 0 50px">
                <StyledInput
                    label={
                        (language === "Kor" && "메일로 수신된 6자리 코드 입력") ||
                        (language === "Eng" && "6 digit code received by email")
                    }
                    name="code"
                    value={inputs.code}
                    onChange={onChangeInput}
                />
            </FlexBox>

            <StyledButton
                title={
                    (language === "Kor" && "메일 주소 인증하기") ||
                    (language === "Eng" && "Authenticate email")
                }
                onClick={editEmail}
            />
        </Wrapper>
    );
};

export default React.memo(EditEmailPresenter);
