import React, { useEffect } from "react";
import styled from "styled-components";
import { MediumText } from "./StyledText";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import CalendarHeader from "./CalendarHeader";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: auto;
  }
  input {
    width: 208px;
    height: 30px;
    font-size: 16px;
    color: ${({ theme }) => theme.activeColor};
    text-align: center;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
  }
`;
const Title = styled(MediumText)`
  margin-right: 12px;
`;

const CustomCalendar = ({
  name = "period",
  value = [],
  language,
  onChange = () => null,
}) => {
  const [startDate, endDate] = value;

  useEffect(() => {
    // 달력 선택 시 키보드창 열리지 않도록 설정
    const input = document.querySelector(".react-datepicker-wrapper input");
    input.setAttribute("inputMode", "none");
  }, []);

  return (
    <Wrapper>
      <Title>
        {language === "Kor" && "기간"}
        {language === "Eng" && "Period"}
      </Title>
      <DatePicker
        locale={ko}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        dateFormat="yyyy.MM.dd"
        onChange={(dates) => onChange(name, dates)}
        renderCustomHeader={({
          // 달력 커스텀 헤더
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CalendarHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
      />
    </Wrapper>
  );
};

export default React.memo(CustomCalendar);
