import React, {useContext} from "react";
import styled from "styled-components";
import {ThemeContext} from "../../context/ThemeProvider";
import fullViewIcon from "../../assets/icons/project/fullViewIcon.svg";

const Button = styled.button`
  width: 55px;
  height: 55px;
  padding: 8px 0 4px;
  font-size: 12px;
  color: ${({theme}) => theme.activeColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.activeColor};
`;
const Image = styled.img``;

const ImageFullViewButton = ({ onClick }) => {
    const { language } = useContext(ThemeContext);


    return (
        <Button onClick={onClick}>
            <Image src={fullViewIcon}/>

            {(language === 'Kor' && '전체보기') ||
            (language === 'Eng' && 'Full View')}
        </Button>
    )
}

export default ImageFullViewButton;