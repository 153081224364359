import React, {useContext} from "react";
import styled from "styled-components";
import {LargeText, MediumText} from "../../../share/StyledText";
import FlexBox from "../../../share/FlexBox";
import ProfileImage from "../../../share/ProfileImage";
import userImage from "../../../../assets/images/emptyUser.png";
import {ThemeContext} from "../../../../context/ThemeProvider";
import {groupChanger} from "../../../../lib/ProfileUtils";

const Wrapper = styled(FlexBox)`
  align-items: center;
  margin-bottom: 24px;
`;
const Text = styled(MediumText)`
  font-size: 18px;
  margin-right: 12px;
`;
const UserInfoText = styled(LargeText)`
  max-width: 60%;
  line-height: 1.2;
`;

const CheckerProfile = ({ checkListDetail }) => {
    const { language } = useContext(ThemeContext);

    return (
        <Wrapper>
            <Text>
                {(language === 'Kor' && '평가자') || (language === 'Eng' && 'Inspector')} :
            </Text>
            <ProfileImage
                width={24}
                height={24}
                margin='0 6px 0 0'
                src={checkListDetail?.wcl_evaluatorImg === '' ? userImage : checkListDetail?.wcl_evaluatorImg}
            />
            <UserInfoText>
                {`${!!groupChanger(checkListDetail?.wcl_evaluatorGroup) ? groupChanger(checkListDetail?.wcl_evaluatorGroup) : '없음'} ${checkListDetail?.wcl_evaluatorInfo}`}
            </UserInfoText>
        </Wrapper>
    )
}

export default React.memo(CheckerProfile);
