import { gql } from "@apollo/client";

export const ADD_PROJECT_MEMBER = gql`
  mutation addProjectMember(
    $group: String
    $emailPhone: Boolean
    $countryCode: String
    $term: String
  ) {
    addProjectMember(
      group: $group
      emailPhone: $emailPhone
      countryCode: $countryCode
      term: $term
    ) {
      result
      error
      inviMemberInfo {
        user_id
        user_name
        user_groupCompany
        user_group
        user_profileImg
        user_countryCode
        user_cellphone
        user_email
      }
    }
  }
`;
