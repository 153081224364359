import { gql } from "@apollo/client";

export const SEE_FAQ_LIST_USER = gql`
  query seeFAQListUser {
    seeFAQListUser {
      result
      error
      FAQList {
        faq_id
        faq_createdAt
        faq_question
        faq_answer
      }
    }
  }
`;
