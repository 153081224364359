import React, {useContext, useEffect, useState} from "react";
import ReportPresenter from "./ReportPresenter";
import {useQuery} from "@apollo/client";
import {SHARED_SCAR_DETAIL} from "../../graphql/Report/query";
import {useLocation} from "react-router-dom";
import {lightTheme} from "../../styles/theme";
import {ThemeContext} from "../../context/ThemeProvider";

const ReportContainer = () => {
    const {pathname} = useLocation();
    const {language} = useContext(ThemeContext);

    const [memberModal, setMemberModal] = useState(false);
    const [processDotColor, setProcessDotColor] = useState(
        lightTheme.subTextColor
    );

    const {data, refetch, loading} = useQuery(SHARED_SCAR_DETAIL, {
        variables: {
            scarId: Number(pathname.split("/").at(-1)),
        },
    });

    useEffect(() => {
        switch (data?.sharedScarDetail?.scar?.scar_signStatus) {
            case "impossible": //조치불가
                setProcessDotColor(lightTheme.negativeColor);
                break;
            case "reject": //반려
                setProcessDotColor(lightTheme.negativeColor);
                break;
            case "complete": //완료
                setProcessDotColor(lightTheme.subTextColor);
                break;
            default:
                setProcessDotColor(lightTheme.activeColor);
                break;
        }
    }, [data]);

    return (
        <ReportPresenter
            language={language}
            data={data?.sharedScarDetail}
            setMemberModal={setMemberModal}
            memberModal={memberModal}
            processDotColor={processDotColor}
        />
    );
};

export default ReportContainer;
