import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import closeIcon from "../../../assets/icons/share/closeIcon.svg";
import { HugeText } from "../../share/StyledText";
import { ThemeContext } from "../../../context/ThemeProvider";
import StyledInput from "../../share/StyledInput";
import StyledButton from "../../share/StyledButton";
import { SCAR_APPROVAL } from "../../../graphql/Project/ScarDetail/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { ScarDetailContext } from "../../../pages/Project/ScarDetail/ScarDetailContainer";
import { SEE_MY_SIGN_PROJECT } from "../../../graphql/Project/ProjectList/query";
import { SEE_SCAR_LIST } from "../../../graphql/Project/ProjectDetail/query";
import Loader from "../../share/Loader";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  height: 100vh;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: -1;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.3s;

  ${({ scarCheckModal }) =>
    scarCheckModal &&
    css`
      z-index: 10;
      left: calc(100vw - 50%);
      transform: translateX(-50%);
    `};
`;
const Icon = styled.img`
  margin: 12px 0 24px;
`;

const ScarCheckModal = ({ scarCheckModal, setScarCheckModal }) => {
  const { language } = useContext(ThemeContext);
  const { scarRefetch } = useContext(ScarDetailContext);
  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { scarId } = useParams();
  const [checkApprovalContent, setCheckApprovalContent] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true); // 조치하기 버튼 상태
  const [loading, setLoading] = useState(false);

  const [scarApproval] = useMutation(SCAR_APPROVAL);

  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });

  const handleAction = useCallback((e) => {
    const {
      target: { value = "" },
    } = e;

    if (value.trim()) setButtonStatus(false);
    else setButtonStatus(true);

    setCheckApprovalContent(value);
  }, []);

  const handleApproval = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await scarApproval({
        variables: {
          scar_id: Number(scarId),
          approval: true,
          checkApprovalContent,
          rejectText: "",
        },
      });
      if (data?.scarApproval?.result) {
        alert(
          (language === "Kor" && "승인되었습니다.") ||
            (language === "Eng" && "Approved"),
        );
        await scarRefetch();
        setScarCheckModal(false);
        await signLineRefetch();
        await scarListRefetch();
        setLoading(false);
      }
      if (data?.scarApproval?.error !== "") {
        alert(data?.scarApproval?.error);
      }
    } catch (e) {
      alert(e.message);
    }
  }, [checkApprovalContent, language]);

  return (
    <Wrapper scarCheckModal={scarCheckModal}>
      <Icon src={closeIcon} onClick={() => setScarCheckModal(false)} />
      <HugeText>
        {language === "Kor" && "승인하기"}
        {language === "Eng" && "Approval"}
      </HugeText>
      <StyledInput
        label={
          (language === "Kor" &&
            `코멘트* (${checkApprovalContent.length} / 80)`) ||
          (language === "Eng" &&
            `Comment* (${checkApprovalContent.length} / 80)`)
        }
        margin="14px 0"
        value={checkApprovalContent}
        onChange={handleAction}
      />

      <StyledButton
        title={
          loading ? (
            <Loader />
          ) : (
            (language === "Kor" && "승인하기") ||
            (language === "Eng" && "Approval")
          )
        }
        margin="50px 0"
        onClick={handleApproval}
        disabled={buttonStatus}
      />
    </Wrapper>
  );
};

export default React.memo(ScarCheckModal);
