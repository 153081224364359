import React, { useContext, useEffect, useState } from "react";
import QnaPresenter from "./QnaPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useQuery } from "@apollo/client";
import { SEE_MY_ONE_LIST } from "../../../graphql/ServiceCenter/Qna/query";

const QnaContainer = () => {
  const { language } = useContext(ThemeContext);
  const [qnaModal, setQnaModal] = useState(false);
  const [qnaList, setQnaList] = useState([]);

  const {
    data: qnaData,
    loading: qnaLoading,
    refetch: qnaRefetch,
  } = useQuery(SEE_MY_ONE_LIST);

  useEffect(() => {
    if (qnaData?.seeMyOneList?.result) {
      setQnaList(qnaData.seeMyOneList.myOneOnOneInfo);
    }
  }, [qnaData]);

  return (
    <QnaPresenter
      language={language}
      qnaData={qnaData}
      qnaModal={qnaModal}
      setQnaModal={setQnaModal}
      qnaList={qnaList}
      qnaRefetch={qnaRefetch}
    />
  );
};

export default QnaContainer;
