import React, { useCallback, useContext, useState } from "react";
import SignUpPresenter from "./SignUpPresenter";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeProvider";
import {
  removeHyphen,
  getCountryNumberToCode,
  validatePhoneNumber,
  validateEmail,
} from "../../../utils/commons";
import { useMutation } from "@apollo/client";
import { REQUEST_AUTH_CODE } from "../../../graphql/Login/mutation";

const SignUpContainer = () => {
  const navigate = useNavigate();
  const { language, themeMode } = useContext(ThemeContext); // 테마 모드
  const [inputs, setInputs] = useState({
    // Inputs 상태
    type: "PHONE",
    countryCode: "+82",
    phoneNumber: "",
    email: "",
  });

  const validateContryCode = {
    "+1": /^\d{9}$/,
    "+44": /^\d{10}$/,
    "+48": /^\d{10}$/,
    "+82": /^\d{11}$/,
    "+84": /^\d{10}$/,
    "+86": /^\d{11}$/,
    "+966": /^\d{9}$/,
  };

  const [requestAuthCode] = useMutation(REQUEST_AUTH_CODE);

  const handleType = useCallback(
    (type) => setInputs((prev) => ({ ...prev, type })),
    [],
  );

  const onChange = useCallback(
    (e) => {
      // Inputs 상태관리 함수
      const { name, value } = e.target;

      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs],
  );

  const handleSendAuthNumber = useCallback(async () => {
    if (inputs.type === "PHONE" && !inputs.phoneNumber.trim()) {
      alert(
        (language === "Kor" && "핸드폰 번호를 입력해주세요.") ||
          (language === "Eng" && "Please enter the phone number"),
      );
      return;
    }
    if (inputs.type === "EMAIL" && !inputs.email.trim()) {
      alert(
        (language === "Kor" && "이메일 주소를 입력해주세요.") ||
          (language === "Eng" && "Please enter the e-mail address"),
      );
      return;
    }

    if (inputs.type === "PHONE") {
      const refexPattern = validateContryCode[inputs.countryCode]; // 국가코드에 따른 정규식 선택
      const isValidNumber = refexPattern.test(inputs.phoneNumber); // 정규식 테스트

      if (!isValidNumber) {
        alert(
          (language === "Kor" && "휴대폰번호를 확인해주세요.") ||
            (language === "Eng" && "Please check your phone number"),
        );
        return;
      }
    }

    if (inputs.type === "EMAIL") {
      if (!validateEmail(inputs.email)) {
        alert(
          (language === "Kor" && "이메일 주소를 확인해주세요.") ||
            (language === "Eng" && "Please check your e-mail address"),
        );
        return;
      }
    }

    if (
      // common에 유효성검사 코드가 있길래 사용 문제시 삭제
      inputs.type === "PHONE" &&
      !validatePhoneNumber(
        inputs.phoneNumber,
        getCountryNumberToCode(inputs.countryCode),
      )
    ) {
      alert(
        (language === "Kor" && "휴대폰번호 형식에 맞게 입력해주세요.") ||
          (language === "Eng" &&
            "Enter the phone number according to the format."),
      );
      return;
    }

    try {
      const { data } = await requestAuthCode({
        // 인증번호 요청 로직 실행 후
        variables: {
          type: inputs.type,
          countryCode: inputs.countryCode,
          cellphone: removeHyphen(inputs.phoneNumber),
          authPage: false,
          email: inputs.email,
        },
      });
      if (data?.requestAuthCode?.result) {
        navigate("/auth-num-check", {
          state: {
            countryCode: inputs.countryCode,
            phoneNumber: removeHyphen(inputs.phoneNumber),
            email: inputs.email,
          },
        });
      }
      if (data?.requestAuthCode?.error === "1") {
        alert(
          (language === "Kor" && "가입되어있는 번호입니다.") ||
            (language === "Eng" && "It's a registered number"),
        );
        return;
      }
      if (data?.requestAuthCode?.error === "3") {
        alert(
          (language === "Kor" && "문자 전송에 실패하였습니다.") ||
            (language === "Eng" && "Failed to send text message"),
        );
      }
    } catch (e) {
      alert(e);
    }
  }, [inputs]);

  return (
    <SignUpPresenter
      language={language}
      themeMode={themeMode}
      inputs={inputs}
      onChange={onChange}
      handleSendAuthNumber={handleSendAuthNumber}
      handleType={handleType}
    />
  );
};

export default SignUpContainer;
