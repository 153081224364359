import React, { useContext, useEffect, useState } from "react";
import NoticePresenter from "./NoticePresenter";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useQuery } from "@apollo/client";
import { SEE_NOTICE_LIST_USER } from "../../../graphql/ServiceCenter/Notice/query";

const NoticeContainer = () => {
  const navigate = useNavigate();
  const { language } = useContext(ThemeContext);
  const [noticeList, setNoticeList] = useState([]);

  const {
    data: noticeData,
    loading: noticeLoading,
    refetch: noticeRefetch,
  } = useQuery(SEE_NOTICE_LIST_USER);

  useEffect(() => {
    if (noticeData?.seeNoticeListUser?.result) {
      setNoticeList(noticeData.seeNoticeListUser.noticeList);
    }
  }, [noticeData]);

  return (
    <NoticePresenter
      navigate={navigate}
      language={language}
      noticeList={noticeList}
    />
  );
};

export default NoticeContainer;
