import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {
  HugeText,
  MediumText,
  SmallText,
} from "../../../components/share/StyledText";
import { darkTheme, lightTheme } from "../../../styles/theme";
import StyledSelect from "../../../components/share/StyledSelect";
import StyledInput from "../../../components/share/StyledInput";
import StyledButton from "../../../components/share/StyledButton";
import countryCode from "../../../utils/countryCode";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
`;
const Contents = styled.div`
  flex: 1;
`;
const FlexBox = styled.div`
  display: flex;
  margin: 40px 0;
`;

const SignUpPresenter = ({
  language,
  themeMode,
  inputs,
  onChange,
  handleSendAuthNumber,
  handleType,
}) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <GoBack />
      {inputs.type === "PHONE" ? (
        <>
          <HugeText>
            {language === "Kor" && "가입을 위해 휴대폰번호를"}
            {language === "Eng" && "Create Account with"}
          </HugeText>
          <HugeText margin="10px 0 26px">
            {language === "Kor" && "입력해주세요"}
            {language === "Eng" && "Your Phone Number"}
          </HugeText>
        </>
      ) : (
        <>
          <HugeText>
            {language === "Kor" && "가입을 위해 이메일 주소를"}
            {language === "Eng" && "Create Account with"}
          </HugeText>
          <HugeText margin="10px 0 26px">
            {language === "Kor" && "입력해주세요"}
            {language === "Eng" && "Your Email"}
          </HugeText>
        </>
      )}
      <Contents>
        {inputs.type === "PHONE" ? (
          <>
            <SmallText
              fontColor={
                themeMode === "light"
                  ? lightTheme.subTextColor
                  : darkTheme.subTextColor
              }
            >
              {language === "Kor" && "가입하실 휴대폰 번호를 입력해주세요."}
              {language === "Eng" &&
                `Enter your phone number.\nWe won't share your details with anyone`}
            </SmallText>
            <FlexBox>
              <StyledSelect
                label={
                  (language === "Kor" && "국가") ||
                  (language === "Eng" && "Country Code")
                }
                width={
                  (language === "Kor" && "30%") || (language === "Eng" && "35%")
                }
                options={countryCode}
                name="countryCode"
                value={inputs.countryCode}
                onChange={onChange}
              />
              <StyledInput
                label={
                  (language === "Kor" && "휴대폰번호") ||
                  (language === "Eng" && "Phone Number")
                }
                margin="0 0 0 16px"
                type="tel"
                name="phoneNumber"
                value={inputs.phoneNumber}
                onChange={onChange}
              />
            </FlexBox>
          </>
        ) : null}

        {inputs.type === "EMAIL" ? (
          <>
            <SmallText
              fontColor={
                themeMode === "light"
                  ? lightTheme.subTextColor
                  : darkTheme.subTextColor
              }
            >
              {language === "Kor" && "가입하실 이메일 주소를 입력해주세요."}
              {language === "Eng" &&
                `Enter your e-mail address.\nWe won't share your details with anyone`}
            </SmallText>
            <FlexBox>
              <StyledInput
                label={
                  (language === "Kor" && "이메일 주소") ||
                  (language === "Eng" && "E-Mail Address")
                }
                margin="0 0 0 16px"
                type="text"
                name="email"
                value={inputs.email}
                onChange={onChange}
              />
            </FlexBox>
          </>
        ) : null}

        <StyledButton
          title={
            (language === "Kor" && "인증번호 받기") ||
            (language === "Eng" && "Sign Up")
          }
          onClick={handleSendAuthNumber}
        />
        <MediumText
          fontColor={
            themeMode === "light"
              ? lightTheme.subTextColor
              : darkTheme.subTextColor
          }
          textAlign="center"
          margin="16px 0 0 0"
          onClick={() =>
            handleType(inputs.type === "PHONE" ? "EMAIL" : "PHONE")
          }
        >
          {inputs?.type === "PHONE" ? (
            <>
              {language === "Kor" && "이메일주소로 회원가입"}
              {language === "Eng" && "Sign up with e-mail"}
            </>
          ) : null}
          {inputs?.type === "EMAIL" ? (
            <>
              {language === "Kor" && "휴대폰번호로 회원가입"}
              {language === "Eng" && "Sign up with phone"}
            </>
          ) : null}
        </MediumText>
        <MediumText
          fontColor={
            themeMode === "light"
              ? lightTheme.subTextColor
              : darkTheme.subTextColor
          }
          textAlign="center"
          margin="16px 0 0 0"
          onClick={() => navigate(-1)}
        >
          {language === "Kor" && "로그인 하기"}
          {language === "Eng" && "Log in"}
        </MediumText>
      </Contents>
    </Wrapper>
  );
};

export default React.memo(SignUpPresenter);
