import React, { useCallback, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import closeIcon from "../../../assets/icons/share/closeIcon.svg";
import { HugeText } from "../../share/StyledText";
import { ThemeContext } from "../../../context/ThemeProvider";
import StyledInput from "../../share/StyledInput";
import StyledButton from "../../share/StyledButton";
import StyledImgUpload from "../../share/StyledImgUpload";
import deleteIcon from "../../../assets/icons/project/deleteIcon.svg";
import FlexBox from "../../share/FlexBox";
import { TAKE_ACTION_SCAR } from "../../../graphql/Project/ScarDetail/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { ScarDetailContext } from "../../../pages/Project/ScarDetail/ScarDetailContainer";
import { SEE_MY_SIGN_PROJECT } from "../../../graphql/Project/ProjectList/query";
import { SEE_SCAR_LIST } from "../../../graphql/Project/ProjectDetail/query";
import { getExtension } from "../../../utils/commons";
import { ALLOW_FILE_EXTENSION } from "../../../utils/constants";
import Loader from "../../share/Loader";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  height: 100vh;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: -1;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.3s;

  ${({ scarActionModal }) =>
    scarActionModal &&
    css`
      z-index: 10;
      left: calc(100vw - 50%);
      transform: translateX(-50%);
    `};
`;
const Icon = styled.img`
  margin: 12px 0 24px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius}px;
    `};
`;
const DeleteIcon = styled.img`
  position: absolute;
  top: -4px;
  right: -4px;
`;
const PreviewBox = styled.div`
  width: 55px;
  height: 55px;
  position: relative;
  margin-right: 10px;
  border-radius: 8px;
`;

const ScarActionModal = ({ scarActionModal, setScarActionModal }) => {
  const { language } = useContext(ThemeContext);
  const { scarRefetch } = useContext(ScarDetailContext);
  const { refetch: signLineRefetch } = useQuery(SEE_MY_SIGN_PROJECT);
  const { scarId } = useParams();
  const [action, setAction] = useState("");
  const [thumbnail, setThumbnail] = useState([]);
  const [imgFile, setImgFile] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(true); // 조치하기 버튼 상태
  const [loading, setLoading] = useState(false);

  const [takeActionScar] = useMutation(TAKE_ACTION_SCAR);
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });

  useEffect(() => {
    if (action !== "" && imgFile.length !== 0) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [action, imgFile]);

  const onChangeImage = useCallback(
    (e) => {
      // 이미지 등록 함수
      let files = e.target.files;

      if (files.length < 1) return;

      if (imgFile.length + files.length > 5) {
        alert(
          (language === "Kor" && "최대 5장까지 업로드할 수 있습니다.") ||
            (language === "Eng" && "You can upload up to 5 images"),
        );
        files = null;
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].size / 1024 / 1024 > 10) {
          alert(
            (language === "Kor" &&
              "이미지 파일은 10MB 이하로 업로드해주세요.") ||
              (language === "Eng" && "Please upload the image file under 10MB"),
          );
          return;
        }

        let ext = getExtension(files[i].name);

        if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
          alert(
            (language === "Kor" && "이미지 파일만 업로드 가능합니다.") ||
              (language === "Eng" && "Only image files can be uploaded"),
          );
          return;
        }
      }

      setImgFile([...imgFile, ...files]); // 뮤테이션 보낼 데이터

      //여기부턴 썸네일
      let thumbnailImgList = [...thumbnail];
      for (let i = 0; i < files.length; i++) {
        thumbnailImgList.push(URL.createObjectURL(files[i]));
      }
      setThumbnail(thumbnailImgList);
    },
    [thumbnail, imgFile],
  );

  const handleDeleteImage = useCallback(
    (index) => {
      thumbnail.splice(index, 1);
      //   Array.prototype.splice(scarImages, index, 2);
      setThumbnail([...thumbnail]);
      setImgFile(imgFile.filter((el) => imgFile[index] !== el));
    },
    [imgFile, thumbnail],
  );

  const handleSendReport = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await takeActionScar({
        variables: {
          scar_id: Number(scarId),
          sa_text: action,
          sa_imgSelect: true,
          scarActionImg: imgFile,
        },
      });
      if (data?.takeActionScar?.result) {
        alert(
          (language === "Kor" && "조치를 완료했습니다.") ||
            (language === "Eng" && "Report sent successfully"),
        );

        await scarRefetch();
        setScarActionModal(false);
        await signLineRefetch();
        await scarListRefetch();
        setLoading(false);
      }
      if (data?.takeActionScar?.error !== "") {
        alert(data?.takeActionScar?.error);
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  }, [action, language, imgFile]);

  return (
    <Wrapper scarActionModal={scarActionModal}>
      <Icon src={closeIcon} onClick={() => setScarActionModal(false)} />
      <HugeText>
        {language === "Kor" && "조치하기"}
        {language === "Eng" && "Corrective Action Report"}
      </HugeText>
      <StyledInput
        label={
          (language === "Kor" && `조치사항* (${action.length} / 80)`) ||
          (language === "Eng" &&
            `Resolution Description* (${action.length} / 80)`)
        }
        margin="14px 0"
        value={action}
        onChange={(e) => setAction(e.target.value)}
      />

      <StyledImgUpload
        multiple
        label={
          (language === "Kor" && "사진*") || (language === "Eng" && "Images*")
        }
        placeholder={
          (language === "Kor" && "최대 5장까지 업로드할 수 있습니다.") ||
          (language === "Eng" && "You can upload up to 5 images")
        }
        onChange={onChangeImage}
      />
      <FlexBox>
        {thumbnail.map((url, index) => (
          <PreviewBox key={`${index}`}>
            <Image borderRadius={8} src={url} />
            <DeleteIcon
              src={deleteIcon}
              onClick={() => handleDeleteImage(index)}
            />
          </PreviewBox>
        ))}
      </FlexBox>
      <StyledButton
        title={
          loading ? (
            <Loader />
          ) : (
            (language === "Kor" && "조치하기") ||
            (language === "Eng" && "Send Report")
          )
        }
        margin="50px 0"
        onClick={handleSendReport}
        disabled={buttonStatus}
      />
    </Wrapper>
  );
};

export default React.memo(ScarActionModal);
