import React, {useCallback, useState, useContext, useEffect} from "react";
import NotificationPresenter from "./NotificationPresenter";
import {SEE_MY_NOTIFICATION_SETTING} from "../../../graphql/Setting/Notification/query";
import {EDIT_MY_NOTIFICATION_SETTING} from "../../../graphql/Setting/Notification/mutation";
import {ThemeContext} from "../../../context/ThemeProvider";
import {useQuery, useMutation} from "@apollo/client";

const NotificationContainer = () => {
    const {language} = useContext(ThemeContext);
    const [notifications, setNotifications] = useState({
        user_emailNoti: false,
        user_kakaoNoti: false,
        user_msgNoti: false,
        user_pushNoti: false,
    });

    const {data: notiSetData, loading: notiSetLoading, refetch: notiSetRefetch} = useQuery(SEE_MY_NOTIFICATION_SETTING);

    const [editMyNotificationSetting] = useMutation(EDIT_MY_NOTIFICATION_SETTING);

    const handleToggleSwitch = useCallback(async (e) => {
        const {name} = e.target;
        setNotifications({
            ...notifications,
            [name]: !notifications[name],
        });

        try {
            const {data} = await editMyNotificationSetting({
                variables: {
                    ...notifications,
                    [name]: !notifications[name],
                },
            });

            if (data?.editMyNotificationSetting?.result) {
                await notiSetRefetch();
                // alert("알림 설정이 변경되었습니다.");
            }
            if (data?.editMyNotificationSetting?.error !== "") {
                alert(data?.editMyNotificationSetting?.error);
            }
        } catch (e) {
            alert(e.message);
        }
    }, [notifications]);

    useEffect(() => {
        if (notiSetData?.seeMyNotificationSetting?.result) {
            setNotifications(notiSetData?.seeMyNotificationSetting?.notiSetting);
        }
    }, [notiSetData]);

    return (
        <NotificationPresenter
            notifications={notifications}
            handleToggleSwitch={handleToggleSwitch}
            language={language}
        />
    );
};

export default NotificationContainer;
