import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp/SignUp";
import AuthNumCheck from "./pages/SignUp/AuthNumCheck";
import SignUpInfo from "./pages/SignUp/SignUpInfo";
import Notification from "./pages/Setting/Notification";
import Language from "./pages/Setting/Language";
import Terms from "./pages/Setting/Terms";
import ProjectList from "./pages/Project/ProjectList";
import ProjectSearch from "./pages/Project/ProjectSearch";
import CreateProject from "./pages/Project/CreateProject";
import AddMembers from "./pages/Project/AddMembers";
import EditMembers from "./pages/Project/EditMembers";
import ProjectDetail from "./pages/Project/ProjectDetail";
import ProjectStatistics from "./pages/Project/ProjectStatistics";
import ScarSearch from "./pages/Project/ScarSearch";
import CreateScar from "./pages/Project/CreateScar";
import ScarDetail from "./pages/Project/ScarDetail";
import ApprovalProcessView from "./pages/Project/ApprovalProcessView";
import EditProject from "./pages/Project/EditProject";
import MyProfile from "./pages/MyPage/MyProfile";
import EditMyProfile from "./pages/MyPage/EditMyProfile";
import EditEmail from "./pages/MyPage/EditEmail";
import ServiceCenter from "./pages/ServiceCenter/ServiceCenter";
import Faq from "./pages/ServiceCenter/Faq";
import Qna from "./pages/ServiceCenter/Qna";
import Notice from "./pages/ServiceCenter/Notice";
import NoticeDetail from "./pages/ServiceCenter/NoticeDetail";
import RNListener from "./RNListener";
import Manual from "./pages/Setting/Manual";
import CheckList from "./pages/Project/CheckList";
import CheckListDetail from "./pages/Project/CheckListDetail";
import { useContext } from "react";

const LoggedOutRoutes = () => (
  <Routes>
    <Route path="/*" exact element={<Login />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/auth-num-check" element={<AuthNumCheck />} />
    <Route path="/signup-info" element={<SignUpInfo />} />
  </Routes>
);

const LoggedInRoutes = () => (
  <Routes>
    <Route path="/*" exact element={<ProjectList />} />
    <Route path="/language-setting" element={<Language />} />
    <Route path="/notification-setting" element={<Notification />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/project-list" element={<ProjectList />} />
    <Route path="/project-search" element={<ProjectSearch />} />
    <Route path="/create-project" element={<CreateProject />} />
    <Route path="/edit-project" element={<EditProject />} />
    <Route path="/add-members" element={<AddMembers />} />
    <Route path="/edit-members" element={<EditMembers />} />
    <Route path="/project-detail" element={<ProjectDetail />} />
    <Route path="/statistics" element={<ProjectStatistics />} />
    <Route path="/scar-search" element={<ScarSearch />} />
    <Route path="/create-scar" element={<CreateScar />} />
    <Route path="/scar-detail/:scarId" element={<ScarDetail />} />
    <Route path="/approval-process-view" element={<ApprovalProcessView />} />
    <Route path="/my-profile" element={<MyProfile />} />
    <Route path="/edit-my-profile" element={<EditMyProfile />} />
    <Route path="/edit-email" element={<EditEmail />} />
    <Route path="/service-center" element={<ServiceCenter />} />
    <Route path="/faq" element={<Faq />} />
    <Route path="/qna" element={<Qna />} />
    <Route path="/notice" element={<Notice />} />
    <Route path="/notice-detail" element={<NoticeDetail />} />
    <Route path="/manual" element={<Manual />} />
    <Route path="/check-list" element={<CheckList />} />
    <Route path="/check-list-detail" element={<CheckListDetail />} />
  </Routes>
);

const Contents = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  max-height: 932px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
`;

const ContentsWrapper = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Contents>{isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}</Contents>
  );
};

const AppRouter = () => {
  return (
    <AuthProvider>
      <RNListener />
      <ContentsWrapper />
    </AuthProvider>
  );
};

export default AppRouter;
