import { gql } from "@apollo/client";

export const SEE_NOTICE_LIST_USER = gql`
  query seeNoticeListUser {
    seeNoticeListUser {
      result
      error
      noticeList {
        noti_id
        noti_createdAt
        noti_title
        noti_text
        noti_img
      }
    }
  }
`;
