import React, {useContext, useState} from "react";
import styled, {css} from "styled-components";
import NumericalInfo from "./NumericalInfo";
import CheckerProfile from "./CheckerProfile";
import FlexBox from "../../../share/FlexBox";
import arrowIcon from "../../../../assets/icons/share/selectBottomArrow.svg";
import {LargeText, MediumText} from "../../../share/StyledText";
import {ThemeContext} from "../../../../context/ThemeProvider";
import EvaluatorItem from "./EvaluatorItem";
import StyledInput from "../../../share/StyledInput";
import StyledImgUpload from "../../../share/StyledImgUpload";
import StyledButton from "../../../share/StyledButton";
import {CheckListDetailContext} from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";
import PreviewImageBox from "./PreviewImageBox";

const ScoreDescription = styled.div`
  padding: 6px 0 14px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const ArrowBox = styled.div`
  position: relative;
  top: 4px;
`;
const Image = styled.img`
  margin: ${({margin}) => (margin ? margin : 0)};
  transition: .3s;

  ${({rotate}) => rotate && css`
    transform: rotate(${rotate});
  `};
`;
const Text = styled(MediumText)`
  font-size: 18px;
`;
const RegularText = styled(MediumText)`
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const FoldingBox = styled.div`
  height: 60px;
  margin-top: 14px;
  display: flex;
  overflow: hidden;
  transition: .3s;

  ${({isOpen}) => !isOpen && css`
    height: 0;
    margin-top: 0;
  `};
`;
const EvaluatorList = styled.div`
  margin-top: 6px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const ComprehensiveReviewText = styled(MediumText)`
  line-height: 1.2;
  margin: 20px 0;
`;

const Checker = ({weeklySafetyCategory}) => {
    const {language} = useContext(ThemeContext);
    const [isOpen, setIsOpen] = useState(true);
    const {
        comment,
        setCommentModal,
        onChangeImage,
        handleCompleteEvaluation,
        checkListDetail
    } = useContext(CheckListDetailContext);

    return (
        <>
            <ScoreDescription isOpen={isOpen}>
                <FlexBox alignItems='flex-end' justifyContent='space-between'>
                    <Text>
                        {(language === 'Kor' && '점수 설명') || (language === 'Eng' && 'Description')}
                    </Text>
                    <FlexBox alignItems='flex-end' onClick={() => setIsOpen(!isOpen)}>
                        <Text>
                            {isOpen ? (
                                <>
                                    {language === "Kor" && "접기"}
                                    {language === "Eng" && "Close"}
                                </>
                            ) : (
                                <>
                                    {language === "Kor" && "열기"}
                                    {language === "Eng" && "Open"}
                                </>
                            )}
                        </Text>
                        <ArrowBox>
                            <Image margin='0 0 0 4px' rotate={isOpen ? '180deg' : 0} src={arrowIcon}/>
                        </ArrowBox>
                    </FlexBox>
                </FlexBox>
                <FoldingBox isOpen={isOpen}>
                    <FlexBox flexDirection='column'>
                        <RegularText>
                            0 : {(language === 'Kor' && '준수하지 않음') || (language === 'Eng' && 'Unsatisfactory')}
                        </RegularText>
                        <RegularText margin='4px 0'>
                            1 : {(language === 'Kor' && '일부 준수(0~50%)') || (language === 'Eng' && 'Marginal(0~50%)')}
                        </RegularText>
                        <RegularText>
                            2 : {(language === 'Kor' && '대부분 준수(50~80%)') || (language === 'Eng' && 'Partial(50~80%)')}
                        </RegularText>
                    </FlexBox>
                    <FlexBox flexDirection='column' margin='0 0 0 12px'>
                        <RegularText>
                            3 : {(language === 'Kor' && '준수(80~100%)') || (language === 'Eng' && 'Satisfactory(80~100%)')}
                        </RegularText>
                        <RegularText margin='4px 0'>
                            N/A : {(language === 'Kor' && '해당없음') || (language === 'Eng' && 'Not applicable')}
                        </RegularText>
                    </FlexBox>
                </FoldingBox>
            </ScoreDescription>

            <CheckerProfile
                checkListDetail={checkListDetail}
            />
            <NumericalInfo
                checkListDetail={checkListDetail}
            />

            <EvaluatorList>
                {weeklySafetyCategory.map(item => (
                    <EvaluatorItem
                        CHECKER
                        key={item.wsc_id}
                        item={item}
                        checkListDetail={checkListDetail}
                    />
                ))}
            </EvaluatorList>

            <LargeText margin='30px 0 20px'>
                {(language === 'Kor' && '평가 사진 및 의견') || (language === 'Eng' && 'Pictures and Comments')}
            </LargeText>


            {checkListDetail?.wcl_status !== 'complete' ? (
                <>
                    <StyledInput
                        RIGHT_ARROW
                        label={(language === 'Kor' && `종합 검토 의견 (${comment?.length} / 500)`) || (language === 'Eng' && `Comments (${comment?.length} / 500)`)}
                        disabled
                        placeholder={
                            comment === ''
                                ? (language === 'Kor' && '의견을 입력해주세요') || (language === 'Eng' && 'Write additional Comments')
                                : comment}
                        onClick={() => checkListDetail?.wcl_status !== 'complete' ? setCommentModal(true) : null}
                    />
                    <StyledImgUpload
                        multiple
                        label={
                            (language === "Kor" && "사진*") ||
                            (language === "Eng" && "Pictures*")
                        }
                        placeholder={
                            (language === "Kor" && "최대 5장까지 업로드 할 수 있습니다.") ||
                            (language === "Eng" && "You can upload up to 5 images.")
                        }
                        onChange={onChangeImage}
                    />
                </>
            ) : (
                <ComprehensiveReviewText>
                    {comment}
                </ComprehensiveReviewText>
            )}

            <PreviewImageBox />

            {checkListDetail?.wcl_status !== 'complete' && (
                <StyledButton
                    title={
                        (language === "Kor" && "평가 완료하기") ||
                        (language === "Eng" && "Save the checklist")
                    }
                    margin='40px 0'
                    onClick={handleCompleteEvaluation}
                />
            )}
        </>
    )
}

export default React.memo(Checker);