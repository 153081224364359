import React, {useContext} from "react";
import styled from "styled-components";
import {HugeText, MediumText} from "../../../share/StyledText";
import FlexBox from "../../../share/FlexBox";
import {ThemeContext} from "../../../../context/ThemeProvider";

const Wrapper = styled(FlexBox)`
  padding: 18px 16px;
  justify-content: space-between;
  border-radius: 6px;
  background-color: ${({theme}) => theme.sortingBoxColor};
`;
const InfoBox = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
`;
const RegularText = styled(MediumText)`
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
  margin: ${({margin}) => (margin ? margin : 0)};
`;
const BoldText = styled(HugeText)`
  font-size: 22px;
  margin-top: 12px;
`;

const NumericalInfo = ({ checkListDetail }) => {
    const { language } = useContext(ThemeContext);

    return (
        <Wrapper>
            <InfoBox>
                <RegularText>
                    {(language === 'Kor' && '총준수율') || (language === 'Eng' && 'Rate')}
                </RegularText>
                <BoldText>{checkListDetail?.wcl_complyRate}%</BoldText>
            </InfoBox>
            <InfoBox>
                <RegularText>
                    {(language === 'Kor' && '평가 총점') || (language === 'Eng' && 'Total')}
                </RegularText>
                <BoldText>{checkListDetail?.wcl_totalScore}</BoldText>
            </InfoBox>
            <InfoBox>
                <RegularText>
                    {(language === 'Kor' && '평가 점수') || (language === 'Eng' && 'Score')}
                </RegularText>
                <BoldText>{checkListDetail?.wcl_evaluationScore}</BoldText>
            </InfoBox>
            <InfoBox>
                <RegularText>
                    {(language === 'Kor' && '반복위반') || (language === 'Eng' && 'Repeat')}
                </RegularText>
                <BoldText>{checkListDetail?.wcl_repeatViolationCount}</BoldText>
            </InfoBox>
        </Wrapper>
    )
}

export default React.memo(NumericalInfo);