import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import FlexBox from "../../share/FlexBox";
import { MediumText } from "../../share/StyledText";
import ProfileImage from "../../share/ProfileImage";
import { ThemeContext } from "../../../context/ThemeProvider";
import { CreateScarProvider } from "../../../pages/Project/CreateScar/CreateScarContainer";
import { SEE_SAVE_SIGN_LINE } from "../../../graphql/Project/CreateScar/query";
import { useMutation, useQuery } from "@apollo/client";
import { engGroupChanger, groupChanger } from "../../../lib/ProfileUtils";
import Accordion from "../../Accordion";
import { DELETE_SAVE_SIGN_LINE } from "../../../graphql/Project/CreateScar/mutation";
import useModal from "../../../hooks/useModal";
import BottomSheet from "../../BottomSheet";

const Wrapper = styled.div`
  padding: 0 0 100px;
`;

const ApprovalButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
`;

const ApprovalButton = styled.button`
  flex-grow: 1;
  padding: 12px 16px;
  border-radius: 5px;
  font-weight: bold;

  background-color: ${({ color }) => {
    if (color === "red") return "#dc3545";
    if (color === "blue") return "#00C0F3";

    return "#ffffff";
  }};

  border: ${({ color }) => {
    if (color === "red") return "1px solid #dc3545";
    if (color === "blue") return "1px solid #00C0F3";

    return "1px solid #e6e6e6";
  }};

  color: ${({ color }) => {
    if (color === "red") return "#ffffff";
    if (color === "blue") return "#ffffff";

    return "#000000";
  }};
`;

const RowBox = styled.div`
  padding: 20px 0;
`;

const Title = styled(MediumText)`
  color: ${({ theme }) => theme.subTextColor};
  padding-left: 32px;
  margin-bottom: 16px;
`;

const Contents = styled(FlexBox)`
  padding-left: 32px;
  align-items: center;
`;

const EllipsisText = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 180)}px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Text = styled(MediumText)`
  line-height: 1.2;
  margin-bottom: 2px;
`;

const ButtonBox = styled.div`
  display: flex;

  ${({ flexDirection }) => {
    if (flexDirection === "column") {
      return css`
        flex-direction: column;
      `;
    }

    return css`
      flex-direction: row;
    `;
  }}

  & > * {
    flex-grow: 1;
  }

  & > *:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const ApprovalLine = ({ title, uri, group, name }) => {
  const { language } = useContext(ThemeContext);

  return (
    <RowBox>
      <Title>{title}</Title>
      <Contents>
        <ProfileImage width={24} height={24} margin="0 10px 0 0" src={uri} />
        <EllipsisText maxWidth={80}>
          {(language === "Kor" && groupChanger(group)) ||
            (language === "Eng" && engGroupChanger(group))}
        </EllipsisText>
        &nbsp;
        <EllipsisText>{name}</EllipsisText>
      </Contents>
    </RowBox>
  );
};

const ApprovalLoadForm = ({ selected, setSelected }) => {
  const { language } = useContext(ThemeContext);
  const { setApprovalList, setApprovalSettingModal } =
    useContext(CreateScarProvider);
  const { prjId } = useContext(CreateScarProvider);
  const { isOpen, handleModalOpen, handleModalClose } = useModal();
  const [selectedMore, setSelectedMore] = useState(null);

  const { data: signLineData, refetch: refetchSignLine } = useQuery(
    SEE_SAVE_SIGN_LINE,
    {
      variables: { prjId: prjId },
      fetchPolicy: "network-only",
    },
  );

  const [deleteSaveSignLine] = useMutation(DELETE_SAVE_SIGN_LINE);

  const handleModalOpenAndSelected = useCallback(
    (line) => {
      setSelectedMore(line);
      handleModalOpen();
    },
    [handleModalOpen],
  );

  const getSignLineName = useCallback(
    (name, selected) => {
      const selectedLabel = language === "Kor" ? "(선택됨)" : "(Selected)";

      let label = "Line";
      if (name) label = name;
      else if (language === "Kor") label = `라인`;

      if (selected) label = `${label} ${selectedLabel}`;

      return label;
    },
    [language],
  );

  const handleSelectLine = useCallback((line) => {
    setSelected(line.ssl_id);
    setApprovalList({
      create: {
        id: line.create_id,
        group: line.create_group,
        name: line.create_name,
        rank: line.create_rank,
        countryCode: line.create_countryCode,
        cellphone: line.create_cellphone,
        email: line.create_email,
        img: line.create_img,
      },
      check: {
        id: line.check_id,
        group: line.check_group,
        name: line.check_name,
        rank: line.check_rank,
        countryCode: line.check_countryCode,
        cellphone: line.check_cellphone,
        email: line.check_email,
        img: line.check_img,
      },
      action: {
        id: line.action_id,
        group: line.action_group,
        name: line.action_name,
        rank: line.action_rank,
        countryCode: line.action_countryCode,
        cellphone: line.action_cellphone,
        email: line.action_email,
        img: line.action_img,
      },
      manager: {
        id: line.manager_id,
        group: line.manager_group,
        name: line.manager_name,
        rank: line.manager_rank,
        countryCode: line.manager_countryCode,
        cellphone: line.manager_cellphone,
        email: line.manager_email,
        img: line.manager_img,
      },
      actionCheck: {
        id: line.actionCheck_id,
        group: line.actionCheck_group,
        name: line.actionCheck_name,
        rank: line.actionCheck_rank,
        countryCode: line.actionCheck_countryCode,
        cellphone: line.actionCheck_cellphone,
        email: line.actionCheck_email,
        img: line.actionCheck_img,
      },
      finalSign: {
        id: line.finalSign_id,
        group: line.finalSign_group,
        name: line.finalSign_name,
        rank: line.finalSign_rank,
        countryCode: line.finalSign_countryCode,
        cellphone: line.finalSign_cellphone,
        email: line.finalSign_email,
        img: line.finalSign_img,
      },
      circulators: line.saveCirculator?.map(
        (cir) => cir?.circ_email + cir?.circ_cellphone,
      ),
    });

    setApprovalSettingModal(false);
  }, []);

  const handleDeleteLine = useCallback(
    async (sslId) => {
      handleModalClose();

      try {
        const { data } = await deleteSaveSignLine({
          variables: {
            sslId,
          },
        });

        if (data?.deleteSaveSignLine?.result) {
          alert(
            language === "Kor"
              ? "결제 라인 삭제 성공!"
              : "Success Delete Sign Line!",
          );

          if (selected === sslId) {
            setApprovalList(null);
            setSelected(null);
          }
          refetchSignLine();
        }
      } catch (e) {
        console.log(e);
        alert(
          language === "Kor"
            ? "결제 라인 삭제 실패!"
            : "Fail Delete Sign Line!",
        );
      }
    },
    [
      deleteSaveSignLine,
      handleModalClose,
      language,
      refetchSignLine,
      selected,
      setApprovalList,
      setSelected,
    ],
  );

  return (
    <Wrapper>
      {signLineData?.seeSignLineHistory?.signLine?.length === 0 ? (
        <RowBox>
          <Title>
            {(language === "Kor" && "저장된 결재라인이 없습니다.") ||
              (language === "Eng" && "No approval lines saved.")}
          </Title>
        </RowBox>
      ) : (
        <>
          {signLineData?.seeSaveSignLine?.signLine?.map((list, index) => (
            <Accordion
              key={index}
              label={getSignLineName(
                list?.name?.trim(),
                selected === list.ssl_id,
              )}
              handleMoreOpen={() => handleModalOpenAndSelected(list)}
              handleMoreClose={handleModalClose}
              selected={selected === list.ssl_id}
              language={language}
            >
              <ApprovalLine
                title={
                  (language === "Kor" && "발행자") ||
                  (language === "Eng" && "HSE Officer (PM)")
                }
                uri={list?.create_img}
                group={list?.create_group}
                name={list?.create_name}
              />
              <ApprovalLine
                title={
                  (language === "Kor" && "발행확인자") ||
                  (language === "Eng" && "Resident Engineer (PM)")
                }
                uri={list?.check_img}
                group={list?.check_group}
                name={list?.check_name}
              />
              <ApprovalLine
                title={
                  (language === "Kor" && "조치자") ||
                  (language === "Eng" && "Safety Officer")
                }
                uri={list?.action_img}
                group={list?.action_group}
                name={list?.action_name}
              />
              <ApprovalLine
                title={
                  (language === "Kor" && "책임자") ||
                  (language === "Eng" && "Manager")
                }
                uri={list?.manager_img}
                group={list?.manager_group}
                name={list?.manager_name}
              />

              <ApprovalLine
                title={
                  (language === "Kor" && "조치확인자") ||
                  (language === "Eng" && "Safety Checker")
                }
                uri={list?.actionCheck_img}
                group={list?.actionCheck_group}
                name={list?.actionCheck_name}
              />

              <ApprovalLine
                title={
                  (language === "Kor" && "최종승인자") ||
                  (language === "Eng" && "Final Approver")
                }
                uri={list?.finalSign_img}
                group={list?.finalSign_group}
                name={list?.finalSign_name}
              />

              <RowBox>
                <Title>
                  {(language === "Kor" && "회람자") ||
                    (language === "Eng" && "Distribution")}
                </Title>
                {list?.saveCirculator?.length === 0 && <Contents>-</Contents>}
                {list?.saveCirculator?.map((circurator, index) => (
                  <Contents key={index}>
                    <Text>
                      {circurator?.circ_email}
                      {circurator?.circ_cellphone}
                    </Text>
                  </Contents>
                ))}
              </RowBox>

              <ApprovalButtonBox>
                <ApprovalButton
                  color="blue"
                  onClick={() => handleSelectLine(list)}
                >
                  {(language === "Kor" && "설정하기") ||
                    (language === "Eng" && "Set Approval Process")}
                </ApprovalButton>
              </ApprovalButtonBox>
            </Accordion>
          ))}
        </>
      )}
      {isOpen ? (
        <BottomSheet closeBottomSheet={handleModalClose}>
          <ButtonBox flexDirection="column">
            <ApprovalButton
              color="red"
              onClick={() => handleDeleteLine(selectedMore.ssl_id)}
            >
              {(language === "Kor" && "삭제") ||
                (language === "Eng" && "Delete")}
            </ApprovalButton>
          </ButtonBox>
          <ButtonBox>
            <ApprovalButton onClick={() => handleModalClose()}>
              닫기
            </ApprovalButton>
          </ButtonBox>
        </BottomSheet>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(ApprovalLoadForm);
