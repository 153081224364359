import React, {useState, useContext, useEffect, useCallback} from "react";
import styled from "styled-components";
import hamburger from "../../assets/icons/share/hamburger.svg";
import alarmIcon from "../../assets/icons/share/alarm.svg";
import SideBar from "./SideBar/SideBar";
import SideNotifications from "./SideBar/SideNotifications";
import {HugeText} from "../share/StyledText";
import FlexBox from "../share/FlexBox";
import {SubScriptContext} from "../../App";
import {useLazyQuery, useQuery} from "@apollo/client";
import {SEE_MY_NOTIFICATION} from "../../graphql/SideNotification/query";
import {ProjectListContext} from "../../pages/Project/ProjectList/ProjectListContainer";
import {ThemeContext} from "../../context/ThemeProvider";

const Wrapper = styled.div`
  width: 100%;
  //height: 132px;
  //max-width: 768px;
  max-width: 430px;
  padding: 20px 0;
  //position: fixed;
  //top: 0;
  //left: 0;
  //z-index: 5;
  background-color: ${({bgColor, theme}) => bgColor ? bgColor : theme.bgColor};
`;
const HeaderTop = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({margin}) => (margin ? margin : "0 0 20px")};
`;
const Icon = styled.div`
  position: relative;
`;
const Image = styled.img``;
const Alarm = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  top: -1px;
  right: -2px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.negativeColor};
`;

const ProjectHeader = () => {
    const {language} = useContext(ThemeContext); // 테마 모드
    const {setSideBar, setNotifications} = useContext(ProjectListContext);
    const {subScriptOn} = useContext(SubScriptContext);

    const handleOpenSideBar = useCallback(() => { // react native 로 메세지 보내기
        if (!!window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'SIDE_BAR_OPEN'
            }));
        }
        setSideBar(true);
    }, []);

    const handleOpenSideNotification = useCallback(() => {
        if (!!window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'SIDE_NOTIFICATION_OPEN'
            }));
        }
        setNotifications(true);
    }, []);

    return (
        <Wrapper>
            <HeaderTop>
                {/*<Icon onClick={() => setSideBar(true)}>*/}
                <Icon onClick={handleOpenSideBar}>
                    <Image src={hamburger}/>
                </Icon>
                {/*<Icon onClick={() => setNotifications(true)}>*/}
                <Icon onClick={handleOpenSideNotification}>
                    <Image src={alarmIcon}/>
                    {subScriptOn && <Alarm/>}
                </Icon>
            </HeaderTop>
            <FlexBox justifyContent="space-between">
                <HugeText>
                    {(language === "Kor" && "프로젝트 목록") || (language === "Eng" && "Project List")}
                </HugeText>
            </FlexBox>
        </Wrapper>
    );
};

export default ProjectHeader;
