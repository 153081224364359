import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import { HugeText } from "../../../components/share/StyledText";
import serviceCenterImg from "../../../assets/images/serviceCenterImg.png";

const Wrapper = styled.div`
  flex: 1;
  padding: 20px;
`;
const Header = styled.div`
  position: sticky;
  top: 0;
  padding-bottom: 26px;
  background-color: ${({ theme }) => theme.bgColor};
`;
const Contents = styled.div`
  line-height: 1.3;
  font-size: 16px;
  margin-top: 20px;
  word-break: break-all;
`;
const Image = styled.img`
  width: 100%;
  margin-top: 30px;
  //object-fit: cover;
`;
const Title = styled(HugeText)`
  width: 100%;
  line-height: 1.2;
  overflow-wrap: break-word;
`;

const NoticeDetail = ({ notice }) => {
  return (
    <Wrapper>
      <Header>
        <GoBack />
        <Title>{notice?.noti_title}</Title>
      </Header>
      <Contents
        dangerouslySetInnerHTML={{
          __html: notice?.noti_text?.replaceAll("\n", "<br />"),
        }}
      ></Contents>
      {notice?.noti_img !== "" && <Image src={notice?.noti_img} />}
    </Wrapper>
  );
};

export default React.memo(NoticeDetail);
