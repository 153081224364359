import React from "react";
import styled from "styled-components";
import emptyUser from "../../assets/images/emptyUser.png";

const Wrapper = styled.div`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  border-radius: 50%;
  margin: ${({margin}) => margin};
  overflow: hidden;
  border: ${({BORDER, theme}) =>
          BORDER ? `1px solid ${theme.borderColor}` : "none"};
`;
const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({src}) =>
          src === "" ? `url(${emptyUser})` : `url(${src})`};
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
`;

const ProfileImage = ({BORDER, width = 76, height = 76, margin, src}) => {
    return (
        <Wrapper BORDER={BORDER} width={width} height={height} margin={margin}>
            <Image src={src || emptyUser} onError={e => e.target.src = emptyUser}/>
        </Wrapper>
    );
};

export default React.memo(ProfileImage);
