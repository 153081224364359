import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: -100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  //transition: .3s;

  ${({modalState}) => modalState && css`
    z-index: 100;
    opacity: 1;
  `};
`;

const ModalBackground = ({ modalState, children }) => {
    return (
        <Wrapper modalState={modalState}>
            {children}
        </Wrapper>
    )
}

export default React.memo(ModalBackground);