import { gql } from "@apollo/client";

export const EDIT_MY_PROFILE_IMG = gql`
  mutation editMyProfileImg($user_profileImg: Upload) {
    editMyProfileImg(user_profileImg: $user_profileImg) {
      result
      error
    }
  }
`;

export const WITHDRAWAL = gql`
  mutation withdrawal {
    withdrawal {
      result
      error
    }
  }
`;
