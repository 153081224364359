import { gql } from "@apollo/client";

export const EDIT_MY_PROFILE = gql`
  mutation editMyProfile(
    $user_name: String
    $user_rank: String
    $user_groupCompany: String
  ) {
    editMyProfile(
      user_name: $user_name
      user_rank: $user_rank
      user_groupCompany: $user_groupCompany
    ) {
      result
      error
    }
  }
`;
