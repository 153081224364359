import React, { useCallback, useContext, useState } from "react";
import AuthNumCheckPresenter from "./AuthNumCheckPresenter";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeProvider";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@apollo/client";
import {
  CONFIRM_AUTH_CODE_MOBILE,
  RESEND_REQUEST_AUTH_CODE,
} from "../../../graphql/Login/mutation";
import { confirmAuthCodeErrorMessage } from "../../../lib/ErrorCodeUtils";
import { ProfileContext } from "../../../App";

const AuthNumCheckContainer = () => {
  const navigate = useNavigate();
  const {
    state: { countryCode, phoneNumber, login, email },
  } = useLocation();
  const { userLogin } = useContext(AuthContext);
  const { themeMode, language } = useContext(ThemeContext); // 테마 모드
  const [authNumber, setAuthNumber] = useState(""); // 인증번호 상태
  const { fcmToken } = useContext(ProfileContext);

  const [confirmAuthCodeMobile] = useMutation(CONFIRM_AUTH_CODE_MOBILE);
  const [resendRequestAuthCode] = useMutation(RESEND_REQUEST_AUTH_CODE);

  const onChange = useCallback((e) => {
    // Inputs 상태관리 함수
    const { value } = e.target;
    setAuthNumber(value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
  }, []);

  const handleAuthNumber = useCallback(async () => {
    // 인증하기 클릭
    if (authNumber === "") {
      alert(
        (language === "Kor" && "인증번호를 입력해주세요.") ||
          (language === "Eng" && "Please enter the authentication number"),
      );
      return;
    }
    if (6 - (authNumber || "").length > 0) {
      alert(
        (language === "Kor" && "인증번호 6자리를 입력해주세요.") ||
          (language === "Eng" &&
            "Please enter 6 digits of the authentication number."),
      );
      return;
    }
    try {
      // 인증번호 인증 로직 실행 후
      if (login) {
        const { data } = await confirmAuthCodeMobile({
          variables: {
            countryCode: countryCode,
            cellphone: phoneNumber,
            email,
            authcode: authNumber,
            authPage: true,
            fcmDeviceToken: fcmToken,
          },
        });
        confirmAuthCodeErrorMessage(
          data?.confirmAuthCodeMobile?.error,
          language,
        );

        if (data?.confirmAuthCodeMobile?.result) {
          userLogin(data?.confirmAuthCodeMobile?.token);
        }
      } else {
        const { data } = await confirmAuthCodeMobile({
          variables: {
            countryCode: countryCode,
            cellphone: phoneNumber,
            email,
            authcode: authNumber,
            authPage: false,
            fcmDeviceToken: fcmToken,
          },
        });
        confirmAuthCodeErrorMessage(
          data?.confirmAuthCodeMobile?.error,
          language,
        );

        if (data?.confirmAuthCodeMobile?.result) {
          navigate("/signup-info", {
            state: {
              email,
            },
          });
          localStorage.setItem(
            "authInfo",
            JSON.stringify({
              countryCode: countryCode,
              cellphone: phoneNumber,
            }),
          );
        }
      }
    } catch (e) {
      alert(e.message);
    }
  }, [authNumber, countryCode, phoneNumber, login, fcmToken]);

  const handleResendAuthNumber = useCallback(async () => {
    // 인증번호 재전송 클릭
    try {
      let variables = {};
      if (phoneNumber) {
        variables = {
          type: "PHONE",
          countryCode: countryCode,
          cellphone: phoneNumber,
        };
      }

      if (email) {
        variables = {
          type: "EMAIL",
          email,
        };
      }
      const { data } = await resendRequestAuthCode({
        variables,
      });

      if (data?.resendRequestAuthCode?.result) {
        alert(
          (language === "Kor" && "인증번호가 전송되었습니다.") ||
            (language === "Eng" && "Authentication number has been sent"),
        );
        setAuthNumber("");
      }
    } catch (e) {
      alert(e.message);
    }
  }, [email]);

  return (
    <AuthNumCheckPresenter
      themeMode={themeMode}
      language={language}
      authNumber={authNumber}
      onChange={onChange}
      phoneNumber={phoneNumber}
      handleAuthNumber={handleAuthNumber}
      handleResendAuthNumber={handleResendAuthNumber}
    />
  );
};

export default AuthNumCheckContainer;
