import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ProjectListPresenter from "./ProjectListPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useNavigate } from "react-router-dom";
import {
  SEE_MY_SIGN_PROJECT,
  SEE_MY_PROJECT,
} from "../../../graphql/Project/ProjectList/query";
import { useQuery } from "@apollo/client";
import { sortChanger, sortStatusChanger } from "../../../lib/ProjectUtils";
import { ProfileContext, SubScriptContext } from "../../../App";
import { SEE_MY_NOTIFICATION } from "../../../graphql/SideNotification/query";

export const ProjectListContext = createContext(null);

const ProjectListContainer = () => {
  const navigate = useNavigate();
  const {
    myProfile,
    setPrjListSort,
    sideBar,
    setSideBar,
    notifications,
    setNotifications,
  } = useContext(ProfileContext);
  const { subScriptOn } = useContext(SubScriptContext);
  const { language } = useContext(ThemeContext);
  const now = new Date();

  const searchStartDate = new Date(2019, 0, 1);
  const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);

  const [detailSort, setDetailSort] = useState(false); // 정렬 클릭 시 상세 정렬 모드
  const [signPrjList, setSignPrjList] = useState([]);
  const [myPrjList, setMyPrjList] = useState([]);
  const [myPrjView, setMyPrjView] = useState(false);
  const [sortType, setSortType] = useState({
    // 정렬 상태
    status: "전체", // 프로젝트 상태
    period: [searchStartDate, searchEndDate], // 기간 설정(기본 한달전 ~ 오늘까지 설정)
    sort: "내림차순", // 정렬 상태
  });

  const { data: notiData, refetch: notiRefetch } =
    useQuery(SEE_MY_NOTIFICATION);
  const { data: signPrjData, refetch: seeMySignProjectRefetch } =
    useQuery(SEE_MY_SIGN_PROJECT); //내 결재 차례 프로젝트
  const { data: sortPrjData } = useQuery(SEE_MY_PROJECT, {
    variables: {
      searchTerm: "",
      status: sortStatusChanger(sortType.status),
      startDate: sortType.period[0],
      endDate: sortType.period[1],
      sort: sortChanger(sortType.sort),
    },
    fetchPolicy: "network-only",
  });

  const handleSortType = useCallback(
    (name, value) => {
      // 정렬 상태 변경 함수
      setSortType({
        ...sortType,
        [name]: value,
      });
    },
    [sortType],
  );

  const handleSortRefresh = useCallback(() => {
    setSortType({
      status: "전체",
      period: [searchStartDate, searchEndDate],
      sort: "내림차순",
    });
  }, [sortType]);

  useEffect(() => {
    if (signPrjData?.seeMySignProject?.result) {
      setSignPrjList(signPrjData.seeMySignProject.projects);
    }
  }, [signPrjData]);

  useEffect(() => {
    if (sortPrjData?.seeMyProject?.result) {
      setMyPrjList(sortPrjData.seeMyProject.projectList);
    }
  }, [sortPrjData]);

  // useEffect(() => {
  //     if (notiData?.seeMyNotification?.result) {
  //         // setNotiList(notiData?.seeMyNotification?.notificationList);
  //     }
  // }, [notiData]);

  useEffect(() => {
    (async () => {
      await notiRefetch();
    })();
  }, [subScriptOn]);

  useEffect(() => {
    setPrjListSort(sortType);
  }, [sortType]);

  useEffect(() => {
    (async () => {
      await seeMySignProjectRefetch();
    })();
  }, [seeMySignProjectRefetch]);

  return (
    <ProjectListContext.Provider
      value={{
        sideBar,
        setSideBar,
        notifications,
        setNotifications,
        // subScriptOn,
        notiData,
      }}
    >
      <ProjectListPresenter
        language={language}
        navigate={navigate}
        detailSort={detailSort}
        setDetailSort={setDetailSort}
        sortType={sortType}
        handleSortType={handleSortType}
        handleSortRefresh={handleSortRefresh}
        signPrjList={signPrjList}
        myPrjList={myPrjList}
        setMyPrjView={setMyPrjView}
        myPrjView={myPrjView}
        myProfile={myProfile}
      />
    </ProjectListContext.Provider>
  );
};

export default ProjectListContainer;
