import React, {useCallback, useContext} from "react";
import styled from "styled-components";
import deleteIcon from "../../../../assets/icons/project/deleteIcon.svg";
import {CheckListDetailContext} from "../../../../pages/Project/CheckListDetail/CheckListDetailContainer";
import ImageFullViewButton from "../../../share/ImageFullViewButton";
import FlexBox from "../../../share/FlexBox";

const PositionBox = styled.div`
  position: relative;
  margin-right: 2px;
`;
const ImageBox = styled.div`
  width: ${({width}) => width ? width : '55px'};
  aspect-ratio: 1 / 1;
  margin: ${({margin}) => (margin ? margin : "0 10px 0 0")};
  border-radius: 8px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DeleteIcon = styled.img`
  position: absolute;
  top: -4px;
  right: 2px;
`;

const PreviewImage = () => {
    const {wclImages, setFullView} = useContext(CheckListDetailContext);
    const onClickFullView = useCallback(() => {
        setFullView(true);
        document.getElementById('check-list-detail-wrapper').style.height = '100vh';
    }, []);

    return (
        <>
            <FlexBox margin='0 0 16px'>
                {wclImages[0] && (
                    <ImageBox
                        width='calc(50% - 8px)'
                        margin='0 10px 0 0'
                    >
                        <Image borderRadius={8} src={wclImages[0]?.wcli_url}/>
                    </ImageBox>
                )}
                {wclImages[1] && (
                    <ImageBox
                        width='calc(50% - 8px)'
                        margin='0 10px 0 0'
                    >
                        <Image borderRadius={8} src={wclImages[1]?.wcli_url}/>
                    </ImageBox>
                )}
            </FlexBox>
            <FlexBox>
                {wclImages[2] && (
                    <ImageBox>
                        <Image borderRadius={8} src={wclImages[2]?.wcli_url}/>
                    </ImageBox>
                )}
                {wclImages[3] && (
                    <ImageBox>
                        <Image borderRadius={8} src={wclImages[3]?.wcli_url}/>
                    </ImageBox>
                )}
                {wclImages[4] && (
                    <ImageBox>
                        <Image borderRadius={8} src={wclImages[4]?.wcli_url}/>
                    </ImageBox>
                )}
                {/*{wclImages.length > 2 && [wclImages[2], wclImages[3], wclImages[4]]?.map((img, index) => (*/}
                {/*    <ImageBox*/}
                {/*        key={`preview-${index}`}*/}
                {/*    >*/}
                {/*        <Image borderRadius={8} src={img?.wcli_url}/>*/}
                {/*    </ImageBox>*/}
                {/*))}*/}
                {wclImages.length !== 0 && (
                    <ImageFullViewButton onClick={onClickFullView} />
                )}
            </FlexBox>
        </>
    )
}
const UploadImage = () => {
    const {wclImages, handleDeleteImage} = useContext(CheckListDetailContext);

    return (
        <FlexBox>
            {wclImages?.map((img, index) => (
                <PositionBox key={`upload-${index}`}>
                    <ImageBox>
                        <Image borderRadius={8} src={img.wcli_url}/>
                    </ImageBox>
                    <DeleteIcon
                        src={deleteIcon}
                        onClick={() => handleDeleteImage(img.wcli_id)}
                    />
                </PositionBox>
            ))}
        </FlexBox>
    )
}

const PreviewImageBox = () => {
    const {evaluationPermission, checkListDetail} = useContext(CheckListDetailContext);

    return (
        (evaluationPermission === 1 && checkListDetail?.wcl_status === 'inProgress')
            ? <UploadImage/>
            : <PreviewImage/>
    )
}

export default React.memo(PreviewImageBox);