import { gql } from "@apollo/client";

export const ASK_ONE_ON_ONE = gql`
  mutation askOneOnOne($one_question: String) {
    askOneOnOne(one_question: $one_question) {
      result
      error
    }
  }
`;
