export const prjStatusChanger = (value, language) => {
    switch (language) {
        case "Kor":
            switch (value) {
                // 프로젝트 상태
                case "total":
                    return "전체";
                case "toEnd":
                    return "종료예정";
                case "end":
                    return "종료";
                // SCAR 상태
                case "inProgress":
                    return "진행중";
                case "notAction":
                    return "미조치";
                case "impossible":
                    return "조치불가";
                case "delay":
                    return "지연";
                case "reject":
                    return "반려";
                case "complete":
                    return "최종완료";





                case "전체":
                    return "total";
                case "조치불가":
                    return "impossible";
                case "미조치":
                    return "notAction";
                case "진행중":
                    return "inProgress";
                case "종료예정":
                    return "toEnd";
                case "종료":
                    return "end";
                case "반려":
                    return "reject";
                case "지연":
                    return "delay";
                default:
                    return value;
            }
            break;
        case "Eng":
            switch (value) {
                case "inProgress":
                    return "In progress";
                case "end":
                    return "Closed";
                case "toEnd":
                    return "Waiting for Close";
                case "notAction":
                    return "Not action";
                case "delay":
                    return "Delay";
                case "reject":
                    return "Reject";
                case "complete":
                    return "Complete";
                default:
                    return value;
            }
            break;
        default:
            return value;
    }
};

export const sortStatusChanger = (value) => {
    switch (value) {
        case "전체":
            return "total";
        case "진행중":
            return "inProgress";
        case "종료예정":
            return "toEnd";
        case "종료":
            return "end";
    }
};

export const sortChanger = (value) => {
    switch (value) {
        case "오름차순":
            return "asc";
        case "내림차순":
            return "desc";
    }
};

export const scarSortChanger = (value) => {
    switch (value) {
        case "전체":
            return "total";
        case "진행중":
            return "inProgress";
        case "조치필요":
            return "actionRequier";
        case "확인필요":
            return "confirmRequier";
        case "불가 / 반려":
            return "reject";
        case "최종완료":
            return "final";
        case "Total":
            return "total";
        case "In Progress":
            return "inProgress";
        case "Action Required":
            return "actionRequier";
        case "Approval Required":
            return "confirmRequier";
        case "Rejected":
            return "reject";
        case "Closed":
            return "final";
    }
};

export const gradeChanger = (value) => {
    switch (value) {
        case "상":
            return "A";
        case "중":
            return "B";
        case "하":
            return "C";
        case "A":
            return "상";
        case "B":
            return "중";
        case "C":
            return "하";
    }
};

export const engGradeChanger = (value) => {
    switch (value) {
        case "High":
            return "A";
        case "Medium":
            return "B";
        case "Low":
            return "C";
        case "A":
            return "High";
        case "B":
            return "Medium";
        case "C":
            return "Low";
    }
};

export const scarStateNumberChager = (value) => {
    switch (value) {
        case 1:
            return "발행";
        case 6:
            return "완료";
        default:
            return "진행중";
    }
};

export const engLabelChanger = (value) => {
    switch (value) {
        case "추락":
            return "Fall hazard";
        case "전도":
            return "Slips and falls";
        case "협착":
            return "Pinch Point";
        case "화재/폭발":
            return "Fire/Explosion";
        case "낙하물":
            return "Falling objects";
        case "무너짐":
            return "Collapse";
        case "베임/찔림":
            return "Cut/Sting";
        case "산소결핍":
            return "Risk of suffocation";
        case "유해물질":
            return "Hazardous materials";
        case "개인보호구":
            return "PPE";
        case "정리정돈":
            return "Clean up";
        case "기타":
            return "Others";
        case "Fall hazard":
            return "추락";
        case "Slips and falls":
            return "전도";
        case "Pinch Point":
            return "협착";
        case "Fire/Explosion":
            return "화재/폭발";
        case "Falling objects":
            return "낙하물";
        case "Collapse":
            return "무너짐";
        case "Cut/Sting":
            return "베임/찔림";
        case "Risk of suffocation":
            return "산소결핍";
        case "Hazardous materials":
            return "유해물질";
        case "PPE":
            return "개인보호구";
        case "Clean up":
            return "정리정돈";
        case "Others":
            return "기타";
        default:
            return value;
    }
};

export const engMonthChang = month => {
    switch (month) {
        case 1:
            return 'Jan';
        case 2:
            return 'Feb';
        case 3:
            return 'Mar';
        case 4:
            return 'Apr';
        case 5:
            return 'May';
        case 6:
            return 'Jun';
        case 7:
            return 'Jul';
        case 8:
            return 'Aug';
        case 9:
            return 'Sep';
        case 10:
            return 'Oct';
        case 11:
            return 'Nov';
        case 12:
            return 'Dec';
    }
}
