import {createGlobalStyle} from "styled-components";
import reset from "styled-reset";
import AppleSDGothicNeoBold from "./fonts/AppleSDGothicNeoB.ttf";
import AppleSDGothicNeoMedium from "./fonts/AppleSDGothicNeoM.ttf";
import AppleSDGothicNeoRegular from "./fonts/AppleSDGothicNeoR.ttf";
import AppleSDGothicNeoLight from "./fonts/AppleSDGothicNeoL.ttf";

export default createGlobalStyle`
  :root {
    --vh: 100%;
  }
  @font-face {
    font-family: "AppleSDGothicNeoBold";
    src: url(${AppleSDGothicNeoBold});
  }

  @font-face {
    font-family: "AppleSDGothicNeoMedium";
    src: url(${AppleSDGothicNeoMedium});
  }

  @font-face {
    font-family: "AppleSDGothicNeoRegular";
    src: url(${AppleSDGothicNeoRegular});
  }

  @font-face {
    font-family: "AppleSDGothicNeoLight";
    src: url(${AppleSDGothicNeoLight});
  }

  * {
    font-family: "AppleSDGothicNeoMedium", "Apple SD Gothic Neo", sans-serif;
    box-sizing: border-box;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;  /* Remove scrollbar space */
      height: 0;
      background: transparent;  /* Optional: just make scrollbar invisible */
      -webkit-appearance: none; 
    }
  }

  body {
    font-size: 14px;
    background-color: ${({theme}) => theme.bgColor};
    font-family: "AppleSDGothicNeoMedium", "Apple SD Gothic Neo", sans-serif;
    /* height: 100vh;
    overflow: hidden; */

    box-sizing: border-box;
    scrollbar-width: none;
    /* -ms-overflow-style: none; */

    body::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.defaultTextColor};
  }

  select,
  input {
    height: 34px;
    border: none;
    padding: 0;
    background-color: ${({theme}) => theme.bgColor};
  }

  select:focus,
  input:focus {
    outline: none;
  }

  button {
    border: none;
    background-color: inherit;
  }

  button,
  select {
    cursor: pointer;
    -webkit-appearance: none; /* 화살표 없애기 for chrome*/
    -moz-appearance: none; /* 화살표 없애기 for firefox*/
    appearance: none; /* 화살표 없애기 공통*/
  }

  ${reset};
`;

// height: calc(var(--vh, 1vh) * 100);
