import React, {useCallback, useContext} from "react";
import LanguagePresenter from "./LanguagePresenter";
import {ThemeContext} from "../../../context/ThemeProvider";

const LanguageContainer = () => {
    const { language, setLanguage } = useContext(ThemeContext); // 테마 모드

    const handleSettingLanguage = useCallback(language => {
        setLanguage(language);
        localStorage.setItem("language", language);
    }, []);

    return (
        <LanguagePresenter
            language={language}
            handleSettingLanguage={handleSettingLanguage}
        />
    )
}

export default LanguageContainer;