import parsePhoneNumber from "libphonenumber-js";

// 휴대폰 번호 Hyphen 삽입
export const autoHyphen = (phoneNumber) => {
  return phoneNumber
    ?.replace(/[^0-9]/g, "")
    ?.replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      "$1-$2-$3",
    )
    ?.replace("--", "-");
};

// 휴대폰 번호 hyphen 제거
export const removeHyphen = (phoneNumber) => {
  return phoneNumber.replace(/-/g, "");
};

// 이메일 정규식 검사
export const isEmail = (email) => {
  const emailRegex =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return emailRegex.test(email);
};

// 휴대폰 정규식 검사
export const isPhone = (phoneNumber) => {
  const phonePattern = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})/;
  return phonePattern.test(phoneNumber);
};

export const onlyNumber = (text) => {
  return text.replace(/[^0-9]/g, "");
};

export const isEmptyObj = (obj) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
};

// 날짜 변환
export const dateFormat = (dateString, type) => {
  const weekDay = ["일", "월", "화", "수", "목", "금", "토"];
  let year;
  let month;
  let day;
  let week;
  let date = new Date(
    typeof dateString === "string" ? Number(dateString) : dateString,
  );

  switch (type) {
    case "yyyy.MM.dd":
      year = date.getFullYear().toString().slice();
      month = date.getMonth() + 1;
      day = date.getDate();
      week = weekDay[date.getDay()];

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      return year + "." + month + "." + day;
    case "yyyy":
      year = date.getFullYear().toString();
      return year;
    case "yyyy.MM.dd - hh:mm":
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();

      let hour = date.getHours();
      let minute = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;

      return year + ". " + month + ". " + day + " - " + hour + ":" + minute;
    default:
      return date;
  }
};

// 기간 설정 시 년도 구하기 (10년 전 ~ 20년 후)
export const getYears = () => {
  let monthArray = [];
  let tenYearsAgo = new Date().getFullYear() - 10;

  for (let i = tenYearsAgo; i <= new Date().getFullYear() + 20; i++) {
    let date = dateFormat(new Date(i, 0), "yyyy");
    monthArray.push(date);
  }

  return monthArray.sort();
};

export const getExtension = (str) => {
  return str.slice(str.lastIndexOf(".") + 1).toLowerCase();
};

export const getCountryNumberToCode = (countryNumber) => {
  switch (countryNumber) {
    // 사아디아라비아
    case "+966":
      return "SA";
    // 중국
    case "+86":
      return "CN";
    // 베트남
    case "+84":
      return "VN";
    // 대한민국
    case "+82":
      return "KR";
    // 폴란드
    case "+48":
      return "PL";
    // 영국
    case "+44":
      return "GB";
    // 미국, 캐나다
    case "+1":
    default:
      return ["US", "CA"];
  }
};

export const validatePhoneNumber = (phone, countryCode) => {
  try {
    if (!(countryCode && phone)) return false;

    if (countryCode instanceof Array) {
      return (countryCode || []).some((item) =>
        parsePhoneNumber(phone, item).isValid(),
      );
    }

    if (typeof countryCode === "string") {
      return parsePhoneNumber(phone, countryCode).isValid();
    }
  } catch (e) {
    return false;
  }

  return false;
};

export const validateEmail = (email = "") => {
  const regExp = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
  );

  return regExp.test(email);
};
