import React, {useContext, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import ModalClose from "../../share/ModalClose";
import {HugeText, LargeText, MediumText} from "../../share/StyledText";
import {ThemeContext} from "../../../context/ThemeProvider";
import StyledInput from "../../share/StyledInput";
import FlexBox from "../../share/FlexBox";
import {CreateScarProvider} from "../../../pages/Project/CreateScar/CreateScarContainer";
import {useQuery} from "@apollo/client";
import {SEARCH_RISK_CODE_V2} from "../../../graphql/Project/CreateScar/query";

const Wrapper = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 932px;
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 5;
  background-color: ${({theme}) => theme.whiteColor};
  transition: all 0.3s;
  overflow-y: scroll;

  ${({riskCodeModal}) => riskCodeModal && css`
    z-index: 10;
    top: 0;
    right: calc(100vw - 50%);
    transform: translateX(50%);
  `};
`;
const Header = styled.div`
  width: 100%;
  //height: 152px;
  padding: ${({ theme }) => theme.isApp ? '40px 20px 20px' : '20px'};
  background-color: ${({theme}) => theme.bgColor};
`;
const Line = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-top: 6px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const ScrollBox = styled.div`
  height: calc(100% - 242px);
  padding: 22px 20px 40px;
  overflow-y: scroll;
`;
const RiskCode = styled.div`
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid ${({theme}) => theme.lightBorderColor};
`;
const Select = styled(MediumText)`
  color: ${({theme}) => theme.activeColor};
  word-break: keep-all;
`;
const CodeContents = styled(MediumText)`
  font-family: "AppleSDGothicNeoRegular";
  line-height: 1.3;
`;

const SearchRiskCodeModal = ({riskCodeModal, setRiskCodeModal}) => {
    const {language} = useContext(ThemeContext);
    const {handleSelectRiskCode} = useContext(CreateScarProvider);
    const [input, setInput] = useState("");
    const [codeList, setCodeList] = useState([]);

    const {data: searchDataV2} = useQuery(SEARCH_RISK_CODE_V2, {
        variables: {
            riskCodeKeyword: input,
        },
    });

    useEffect(() => {
        if (searchDataV2?.searchRiskCode_v2?.result) {
            setCodeList(searchDataV2?.searchRiskCode_v2?.checkItemList);
        }
    }, [searchDataV2]);

    return (
        <Wrapper riskCodeModal={riskCodeModal}>
            <Header>
                <ModalClose setModalClose={setRiskCodeModal}/>
                <HugeText>
                    {language === "Kor" && "리스크코드 입력하기"}
                    {language === "Eng" && "Search Risk Code"}
                </HugeText>
            </Header>
            <Line>
                <StyledInput
                    NO_BORDER
                    label={
                        (language === "Kor" && "키워드로 검색하기") ||
                        (language === "Eng" && "Search with keywords")
                    }
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={
                        (language === "Kor" && "키워드를 입력해주세요.") ||
                        (language === "Eng" && "Please enter a keyword")
                    }
                />
            </Line>

            <ScrollBox>
                {codeList.map((code, index) => (
                    <RiskCode key={`${code.cli_riskCode}_${index}`}>
                        <FlexBox justifyContent="space-between" margin="0 0 12px">
                            <LargeText fontFamily="AppleSDGothicNeoBold">
                                {code.cli_riskCode} | {(language === 'Kor' && code.cli_safetyCategory_kr) || language === 'Eng' && code.cli_safetyCategory_eng}
                            </LargeText>
                            <Select onClick={() => handleSelectRiskCode(code.cli_riskCode)}>
                                {(language === "Kor" && "선택") ||
                                (language === "Eng" && "Select")}
                            </Select>
                        </FlexBox>
                        <CodeContents>
                            {language === 'Kor' && code.cli_checkItem_kr}
                            {language === 'Eng' && code.cli_checkItem_eng}
                        </CodeContents>
                    </RiskCode>
                ))}
            </ScrollBox>
        </Wrapper>
    );
};

export default React.memo(SearchRiskCodeModal);
