import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import {
    HugeText,
    LargeText,
    MediumText,
} from "../../../components/share/StyledText";

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.isApp ? '40px 20px' : '20px'};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const LanguageBox = styled.div`
  padding-bottom: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 18px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`;
const Box = styled.div`
  color: ${({theme}) => theme.subTextColor};
  display: flex;
  flex-direction: column;
`;
const Text = styled(MediumText)`
  font-weight: 400;
  color: ${({theme}) => theme.subTextColor};
  margin-top: 6px;
`;
const ActiveText = styled(LargeText)`
  color: ${({theme, active}) => active && theme.activeColor};
`;

const LanguagePresenter = ({
                               // themeMode,
                               language = "Kor",
                               handleSettingLanguage,
                           }) => {
    return (
        <Wrapper>
            <GoBack route='/project-list'/>
            <HugeText margin="0 0 26px">
                {language === "Kor" && "언어 설정하기"}
                {language === "Eng" && "Language Setting"}
            </HugeText>
            <LanguageBox>
                <Box>
                    <LargeText fontWeight={500}>한국어</LargeText>
                    <Text>Korean</Text>
                </Box>
                <ActiveText
                    fontWeight={500}
                    active={language === "Kor"}
                    onClick={() => handleSettingLanguage("Kor")}
                >
                    {language === "Kor" ? "설정됨" : "To select"}
                </ActiveText>
            </LanguageBox>

            <LanguageBox>
                <Box>
                    <LargeText fontWeight={500}>English</LargeText>
                    <Text>영어</Text>
                </Box>
                <ActiveText
                    fontWeight={500}
                    active={language === "Eng"}
                    onClick={() => handleSettingLanguage("Eng")}
                >
                    {language === "Eng" ? "Selected" : "설정하기"}
                </ActiveText>
            </LanguageBox>
        </Wrapper>
    );
};

export default React.memo(LanguagePresenter);
