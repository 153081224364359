import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { MediumText } from "../../../components/share/StyledText";
import ProjectInfo from "../../../components/feature/Project/ProjectInfo";
import folderIcon from "../../../assets/icons/project/folder.svg";
import SortingForm from "../../../components/feature/Project/SortingForm";
import ProjectHeader from "../../../components/layout/ProjectHeader";
import SideBar from "../../../components/layout/SideBar/SideBar";
import SideNotifications from "../../../components/layout/SideBar/SideNotifications";
import arrowTopIcon from "../../../assets/icons/project/arrowTopIcon.svg";

const Wrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  padding: ${({ theme }) => (theme.isApp ? "20px" : "0 20px")};
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;
const MyProjectList = styled.div`
  //min-height: 92px;
  height: ${({ height }) => height}px;
  min-height: ${({ height }) => (height !== 0 ? 92 : 0)}px;
  transition: 0.25s;

  ${({ fold, height }) =>
    height !== 0 &&
    fold &&
    css`
      height: 0;
      padding: 0;
      border: none;
      overflow: hidden;
    `};
`;
const ScrollBox = styled.div`
  height: calc(100vh - 162px);
  padding-bottom: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
`;
const Title = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 26px 0;
`;
const SubText = styled(MediumText)`
  font-weight: 500;
  font-family: "AppleSDGothicNeoRegular", "Apple SD Gothic Neo", sans-serif;
  color: ${({ theme }) => theme.subTextColor};
`;
const Image = styled.img`
  margin: ${({ margin }) => (margin ? margin : 0)};
  transition: 0.4s;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate});
    `};
`;
const CreateProjectButton = styled.button`
  width: 100%;
  height: 48px;
  font-size: 18px;
  font-family: "AppleSDGothicNeoBold", "Apple SD Gothic Neo", sans-serif;
  color: ${({ theme }) => theme.activeColor};
  color: ${({ theme }) => theme.activeColor};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.activeColor};

  &:active {
    color: ${({ theme }) => theme.whiteColor};
    opacity: 0.3;
    background-color: ${({ theme }) => theme.activeColor};
  }
`;

const ProjectListPresenter = ({
  language = "Kor",
  navigate,
  detailSort,
  setDetailSort,
  sortType,
  handleSortType,
  handleSortRefresh,
  signPrjList = [],
  myPrjList = [],
  myPrjView,
  setMyPrjView,
  myProfile,
}) => {
  useEffect(() => {
    sessionStorage.setItem("sort", "");
  }, []);

  return (
    <Wrapper>
      <ProjectHeader />

      <ScrollBox>
        {myProfile?.user_group === "head" && ( // 프로젝트 생성 권한 있을 경우
          <CreateProjectButton onClick={() => navigate("/create-project")}>
            <Image margin="4px 12px 0 0" src={folderIcon} />
            {language === "Kor" && "프로젝트 생성하기"}
            {language === "Eng" && "Create Project"}
          </CreateProjectButton>
        )}
        <Title>
          <MediumText fontFamily="AppleSDGothicNeoBold">
            {language === "Kor" && "내 결재 차례 프로젝트"}
            {language === "Eng" && "Action Required"}
          </MediumText>

          {signPrjList.length > 1 ? (
            <SubText onClick={() => setMyPrjView(!myPrjView)}>
              {myPrjView ? (
                <>
                  {language === "Kor" && "접기"}
                  {language === "Eng" && "Close"}
                </>
              ) : (
                <>
                  {language === "Kor" && "열기"}
                  {language === "Eng" && "Open"}
                </>
              )}
              <Image
                margin="0 0 2px 4px"
                rotate={myPrjView ? 0 : "180deg"}
                src={arrowTopIcon}
              />
            </SubText>
          ) : null}
        </Title>
        {/* 내 결재 차례 프로젝트 */}
        <MyProjectList
          fold={!myPrjView}
          height={signPrjList.length === 0 ? 0 : signPrjList.length * 106 - 20}
        >
          {signPrjList?.map((prj) => (
            <ProjectInfo key={prj.prj_id} APPROVAL prjData={prj} />
          ))}
        </MyProjectList>

        {/* 검색 / 정렬 UI */}
        <SortingForm
          navigate={navigate}
          detailSort={detailSort}
          setDetailSort={setDetailSort}
          sortType={sortType}
          handleSortType={handleSortType}
          handleSortRefresh={handleSortRefresh}
        />
        {/* // 프로젝트 목록 */}
        {myPrjList?.map((prj) => (
          <ProjectInfo key={prj.prj_id} prjData={prj} />
        ))}
      </ScrollBox>

      <SideBar />
      <SideNotifications />
    </Wrapper>
  );
};

export default React.memo(ProjectListPresenter);
