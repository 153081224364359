import React, {useCallback, useContext, useState} from "react";
import styled, {css} from "styled-components";
import FlexBox from "../../../share/FlexBox";
import {LargeText} from "../../../share/StyledText";
import {ThemeContext} from "../../../../context/ThemeProvider";
import {useMutation} from "@apollo/client";
import {CREATE_CHECK_ITEM_EVALUATE, EDIT_CHECK_LIST_ITEM_COMMENT} from "../../../../graphql/Project/CheckList/mutation";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px;
`;
const ListBox = styled.div`
  padding: 18px 0;
  display: flex;
`;
const Number = styled.div`
  font-size: 18px;
  padding-top: 2px;
  margin-right: 8px;
`;
const InfoBox = styled.div`
  width: 100%;
`;
const ListTitle = styled(LargeText)`
  font-weight: 400;
  line-height: 1.2;
`;
const WarningText = styled(LargeText)`
  height: 18px;
  font-family: "AppleSDGothicNeoMedium";
  font-weight: 400;
  text-align: right;
  color: ${({theme}) => theme.negativeColor};
  margin-top: 18px;
`;
const Button = styled.button`
  width: 64px;
  height: 50px;
  font-size: 18px;
  white-space: nowrap;
  color: ${({ theme }) => theme.subTextColor};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.ultraLightGray};
  
  ${({ active }) => active && css`
    color: ${({ theme }) => theme.whiteColor};
    background-color: ${({ theme }) => theme.activeColor};
  `};
`;
const Line = styled.div`
  height: 1px;
  margin-top: 18px;
  background-color: ${({theme}) => theme.borderColor};
`;
const InputBox = styled.div`
  display: flex;
  margin: 12px 0;
`;
const Input = styled.input`
  flex: 1;
  height: 50px;
  padding: 0 14px;
  margin-right: 12px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  
  &::placeholder {
    font-size: 16px;
    color: ${({ theme }) => theme.subTextColor};
  }
`;

const CheckItem = ({item, index, refetch}) => {
    const {language} = useContext(ThemeContext);
    const [score, setScore] = useState(item?.wci_score || null);
    const [comment, setComment] = useState(item?.wci_comment || '');

    const [createCheckItemEvaluate] = useMutation(CREATE_CHECK_ITEM_EVALUATE);
    const [editCheckListItemComment] = useMutation(EDIT_CHECK_LIST_ITEM_COMMENT);

    const handleCheckItemEvaluate = useCallback(async score => {
        try {
            const { data } = await createCheckItemEvaluate({
                variables: {
                    wciId: item.wci_id,
                    wciScore: score,
                    wciRepeatedViolation: (item?.lastWeekScore) < 3 && (score < 3)
                }
            });

            if (data?.createCheckItemEvaluate?.result) {
                await refetch();
                setScore(score);
            }
        } catch(e) {
            alert(e.message);
        }
    }, [item]);

    const handleCheckListItemComment = useCallback(async () => {
        try {
            const { data } = await editCheckListItemComment({
                variables: {
                    wciId: item.wci_id,
                    wciComment: comment,
                }
            });

            if (data?.editCheckListItemComment) {
                await refetch();
                alert(
                    (language === 'Kor' && '의견을 저장했습니다.') ||
                    (language === 'Eng' && 'Saved your comments')
                );
            }
        } catch(e) {
            alert(e.message);
        }
    }, [comment]);

    return (
        <Wrapper>
            <ListBox>
                <Number>{String.fromCharCode(index + 97)}.</Number>
                <InfoBox>
                    <ListTitle>
                        {language === 'Kor' && item?.wci_checkItem_kr}
                        {language === 'Eng' && item?.wci_checkItem_eng}
                    </ListTitle>
                </InfoBox>
            </ListBox>
            <FlexBox justifyContent='space-between' margin='18px 0 0'>
                <Button active={score === 0} onClick={() => handleCheckItemEvaluate(0)}>0</Button>
                <Button active={score === 1} onClick={() => handleCheckItemEvaluate(1)}>1</Button>
                <Button active={score === 2} onClick={() => handleCheckItemEvaluate(2)}>2</Button>
                <Button active={score === 3} onClick={() => handleCheckItemEvaluate(3)}>3</Button>
                <Button active={score === 999} onClick={() => handleCheckItemEvaluate(999)}>N/A</Button>
            </FlexBox>
            <InputBox>
                <Input
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    maxLength={100}
                    placeholder={
                        (language === 'Kor' && '의견을 입력해주세요.') ||
                        (language === 'Eng' && 'Please enter your comments.')
                    }
                />
                <Button
                    active
                    onClick={handleCheckListItemComment}
                >
                    {(language === 'Kor' && '저장') || (language === 'Eng' && 'Save')}
                </Button>
            </InputBox>
            <WarningText>
                {((item?.lastWeekScore) < 3 && (score < 3) && (score !== null)) && (
                    <>
                        {language === 'Kor' && '반복위반'}
                        {language === 'Eng' && 'Repeat'}
                    </>
                )}
            </WarningText>
            <Line />
        </Wrapper>
    )
}

export default React.memo(CheckItem);