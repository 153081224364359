export const lightTheme = {
  // General
  whiteColor: "#FFFFFF",
  blackColor: "#000000",

  defaultTextColor: "#000000",
  subTextColor: "#B5B5B5",
  grayTextColor: "#989898",
  activeColor: "#00C0F3",
  borderColor: "#E6E6E6",
  lightBorderColor: "#F2F2F2",
  buttonTextColor: "#FFFFFF",
  checkedColor: "#57EA78",
  negativeColor: "#EE3335",
  activeYellow: "#FFC400",
  darkYellow: "#FFA700",
  toggleSwitchColor: "#E5E5E5", // 알림 설정 스위치 버튼
  sortingBoxColor: "#F9F9F9", // 프로젝트 목록 검색, 정렬 박스
  activeBorderColor: "#2564B1",
  ultraLightGray: '#FAFAFA',

  bgColor: "#FFFFFF",
};

export const darkTheme = {
  // General
  whiteColor: "#FFFFFF",
  blackColor: "#000000",

  defaultTextColor: "#FFFFFF",
  darkGrayTextColor: "#3C3C3C",
  subTextColor: "#FFFFFF",
  activeColor: "#FFFFFF",
  borderColor: "#E6E6E6",
  lightBorderColor: "#F2F2F2",
  buttonTextColor: "#00C0F3",
  checkedColor: "#57ea78",
  negativeColor: "#EE3335",
  activeYellow: "#FFC400",
  darkYellow: "#FFA700",
  toggleSwitchColor: "#E5E5E5", // 알림 설정 스위치 버튼
  sortingBoxColor: "#F9F9F9", // 프로젝트 목록 검색, 정렬 박스
  activeBorderColor: "#2564B1",
  ultraLightGray: '#FAFAFA',

  bgColor: "#333333",
};